import { formatUrl } from 'url-lib';
import {
    sdkRequest,
    translateServerErrors,
    dangerouslyFetchAllPages,
} from '@eventbrite/http';

import { gettext } from '@eventbrite/i18n';

const ROUTE_API_URL = '/api/v3';
const APPS_API_URL = `${ROUTE_API_URL}/apps/`;
const RATINGS_API_URL = `${ROUTE_API_URL}/ratings/`;
const APP_RATING_REPLIES_API_URL = `${ROUTE_API_URL}/app_rating_replies/`;
const _buildCategoriesEndpoint = () => `${APPS_API_URL}categories/`;

const _buildAppPicksEndpoint = (filter) =>
    `${APPS_API_URL}?picks=${filter}&expand.app=details`;

const _buildAppByCategoryEndpoint = (categoryId) =>
    `${APPS_API_URL}?categories=${categoryId}&expand.app=details`;

const _buildAppRatingsEnpoint = (appId) => `${APPS_API_URL}${appId}/ratings/`;

const _buildAppRatingByIdEndpoint = (ratingId) =>
    `${RATINGS_API_URL}${ratingId}/`;

const _buildAppRatingsReplyEndpoint = (appId, ratingId) =>
    `${APPS_API_URL}${appId}/ratings/${ratingId}/replies/`;

const _buildAppRatingRepliesByIdEndpoint = (ratingReplyId) =>
    `${APP_RATING_REPLIES_API_URL}${ratingReplyId}/`;

const NOT_AUTHORIZED_ERROR = gettext(
    'You do not have permission to access the resource you requested.',
);
const STANDARD_SERVER_ERROR = gettext(
    'There was an issue with the server, try again',
);

const GENERAL_ERROR_SPEC = {
    NOT_AUTHORIZED: () => NOT_AUTHORIZED_ERROR,

    default: () => STANDARD_SERVER_ERROR,
};

export const getCategories = () =>
    sdkRequest(formatUrl(_buildCategoriesEndpoint()))
        .catch(translateServerErrors(GENERAL_ERROR_SPEC))
        .then(({ categories }) => categories);

export const getPaginatedApps = (params) =>
    sdkRequest(formatUrl(APPS_API_URL, params))
        .catch(translateServerErrors(GENERAL_ERROR_SPEC))
        .then(({ apps }) => apps);

export const getAllApps = (params = { 'expand.app': 'details' }) =>
    dangerouslyFetchAllPages(
        formatUrl(APPS_API_URL, params),
        'apps',
        'continuation',
    )
        .catch(translateServerErrors(GENERAL_ERROR_SPEC))
        .then(({ objects: apps }) => apps);

export const getAppsByCategory = (categoryId) =>
    dangerouslyFetchAllPages(
        _buildAppByCategoryEndpoint(categoryId),
        'apps',
        'continuation',
    )
        .catch(translateServerErrors(GENERAL_ERROR_SPEC))
        .then(({ objects: apps }) => apps);

export const getSearchApps = (searchedTerm) =>
    dangerouslyFetchAllPages(
        _buildAppPicksEndpoint(searchedTerm),
        'apps',
        'continuation',
    )
        .catch(translateServerErrors(GENERAL_ERROR_SPEC))
        .then(({ objects: apps }) => apps);

export const createReview = (appId, data) =>
    sdkRequest(formatUrl(_buildAppRatingsEnpoint(appId)), {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then((response) => response)
        .catch(translateServerErrors(GENERAL_ERROR_SPEC));

export const editReview = (reviewId, data) =>
    sdkRequest(formatUrl(_buildAppRatingByIdEndpoint(reviewId)), {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then((response) => response)
        .catch(translateServerErrors(GENERAL_ERROR_SPEC));

export const getRatings = (appId, params) =>
    sdkRequest(formatUrl(_buildAppRatingsEnpoint(appId), params))
        .catch(translateServerErrors(GENERAL_ERROR_SPEC))
        .then((response) => response);

export const createReviewReply = (appId, ratingId, replyBody) =>
    sdkRequest(formatUrl(_buildAppRatingsReplyEndpoint(appId, ratingId)), {
        method: 'POST',
        body: JSON.stringify({ text: replyBody }),
    })
        .catch(() => Promise.reject(translateServerErrors(GENERAL_ERROR_SPEC)))
        .then((response) => response);

export const editReviewReply = (replyId, replyBody) =>
    sdkRequest(formatUrl(_buildAppRatingRepliesByIdEndpoint(replyId)), {
        method: 'POST',
        body: JSON.stringify({ text: replyBody }),
    })
        .catch(() => Promise.reject(translateServerErrors(GENERAL_ERROR_SPEC)))
        .then((response) => response);

export const deleteReviewReply = (ratingReplyId) =>
    sdkRequest(formatUrl(_buildAppRatingRepliesByIdEndpoint(ratingReplyId)), {
        method: 'DELETE',
    })
        .catch(() => Promise.reject(translateServerErrors(GENERAL_ERROR_SPEC)))
        .then((response) => response);
