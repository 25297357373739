import { initializeErrorReporting } from '@eventbrite/error-reporting';
import React from 'react';
import { render } from 'react-dom';
import {
    initDimensionsFromState,
    trackInitialPageView,
} from '@eventbrite/site-analytics';
// import 'eventbrite_design_system/css/eds.css';
import './app.scss';
import App from './app';
import serverData from './__mocks__/serverData';
import {
    getLocaleOverride,
    setupAsync,
    getParentLocale,
} from '@eventbrite/i18n';

// retrieve data from server to hydrate the client
const props = window.__SERVER_DATA__ || serverData;
const parentLocale = getParentLocale(
    getLocaleOverride() || props.env.localeInfo.locale,
);

const main = async () => {
    initializeErrorReporting();
    try {
        if (parentLocale !== 'en_US') {
            await setupAsync({
                parentLocale,
                translations:
                    /* webpackChunkName: "app-marketplace-i18n-translation" */ import(
                        `./i18n/translations/${parentLocale}.json`
                    ),
            });
        }
    } finally {
        render(<App {...props} />, document.getElementById('root'));
    }
};
main();

// set up initial GA tracking
initDimensionsFromState(props);
trackInitialPageView(props.gaSettings);
