import {
    SET_SEARCH_TERM,
    LOAD_FILTERED_APPS,
    LOAD_APPS_PAGINATION_OBJ,
} from '../actions/Search';

export const searchedTerm = (
    state = { newSearch: '', oldSearch: '' },
    { type, payload },
) => {
    const newState = {};

    Object.assign(newState, state);

    if (type === SET_SEARCH_TERM) {
        newState.newSearch = payload;
        newState.oldSearch = state.newSearch || '';
    }

    return newState;
};

export const paginationObj = (state = {}, { type, payload }) => {
    let newState = state;

    if (type === LOAD_APPS_PAGINATION_OBJ) {
        newState = payload;
    }

    return newState;
};

export const filteredApps = (state = [], { type, payload }) => {
    let nextState = state;

    if (type === LOAD_FILTERED_APPS) {
        nextState = payload;
    }

    return nextState;
};
