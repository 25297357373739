import React from 'react';
import PropTypes from 'prop-types';
import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import { reduxForm, reducer as formReducer } from 'redux-form';
import omit from 'lodash/omit';
import some from 'lodash/some';
import isEqual from 'lodash/isEqual';

/**
 * Creates a component decorator that will create a one-off Redux store if one
 * does not already exist so that the decorated form can be connected to the store via `redux-form`.
 * @param {object} config - the same configuration object passed to `reduxForm` in `redux-form`
 * @returns {function} a form component decorator
 */
export const formValidate = (config) => (FormComponent) => {
    // `ReduxFormComponent` is the `FormComponent` connected to the store (we may have to create)
    // via actions & reducers provided by `reduxForm`. Since `reduxForm` assumes that the
    // `FormComponent` has a store in its context, we need to ensure that an ancestor component
    // has a store in its context, which is what we'll conditionally take care of below
    const ReduxFormComponent = reduxForm(config)(FormComponent);

    // `FormComponentAncestorWithStore` is a component that wraps the `FormComponent`
    // that is being passed in, conditionally adding a one-off store with the
    // appropriate `formReducer` so that redux-form can work as expected.
    return class FormComponentAncestorWithStore extends React.PureComponent {
        // In order to read the `store` property out of the context, `store` needs
        // to be registered. Ideally the type would be the same shape type that
        // `react-redux` uses, but it's not publicly available.
        // https://github.com/reactjs/react-redux/blob/4d302257e3b361731f44b1f546e547ed578c8eec/src/utils/PropTypes.js
        static contextTypes = {
            store: PropTypes.object,
        };

        render() {
            let content = (
                <ReduxFormComponent ref={config.reduxFormRef} {...this.props} />
            );
            const existingStore = this.props.store || this.context.store;

            // If a Redux store was added higher up the component hierarchy, we'll
            // have the store in the `context`. If it was added directly to the
            // FormComponent (far less likely), we'll have it in props.
            // However, if there is no store, it means that the component hasn't been
            // connected to a store yet, so we need to create a one-off store to pass
            // as props to the FormComponent
            if (!existingStore) {
                const store = createStore(
                    combineReducers({
                        form: formReducer,
                    }),
                );

                // Wrap the form component in <Provider> so that it can have the store
                // in its context. This is so that when `connect` from react-redux looks for
                // the store, it'll find it and everything should work.
                content = <Provider store={store}>{content}</Provider>;
            }

            return content;
        }
    };
};

/**
 * `DisconnectedFormValidate` is a wrapper of `formValidate` which can be connected to Redux store in order to initalize
 * redux-forms config with info taken from a Redux store.
 */
export class DisconnectedFormValidate extends React.Component {
    static contextTypes = {
        store: PropTypes.object,
    };

    static propTypes = {
        validateFactory: PropTypes.func,
        validateFactoryParams: PropTypes.object,
        asyncValidateFactory: PropTypes.func,
        asyncValidateFactoryParams: PropTypes.object,
        asyncBlurFields: PropTypes.arrayOf(PropTypes.string),
        formName: PropTypes.string.isRequired,
        formComponent: PropTypes.elementType.isRequired,
        reduxFormRef: PropTypes.func,
        destroyOnUnmount: PropTypes.bool,
    };

    static getWrapperComponent({
        validateFactory,
        validateFactoryParams,
        asyncValidateFactory,
        asyncValidateFactoryParams,
        asyncBlurFields = [],
        formName,
        reduxFormRef,
        destroyOnUnmount = false,
    }) {
        return formValidate({
            validate: validateFactory && validateFactory(validateFactoryParams),
            asyncValidate:
                asyncValidateFactory &&
                asyncValidateFactory(asyncValidateFactoryParams),
            asyncBlurFields,
            form: formName,
            destroyOnUnmount,
            reduxFormRef,
        });
    }

    constructor(props) {
        super(props);

        this.state = {
            WrapperComponent: DisconnectedFormValidate.getWrapperComponent(
                props,
            )(props.formComponent),
        };
    }

    componentDidUpdate(prevProps) {
        const propsAffectingFormValidate = Object.keys(
            DisconnectedFormValidate.propTypes,
        );
        const hasSomePropAffectingFormValidateChanged = some(
            propsAffectingFormValidate,
            (propName) => !isEqual(this.props[propName], prevProps[propName]),
        );

        if (hasSomePropAffectingFormValidateChanged) {
            this.setState({
                WrapperComponent: DisconnectedFormValidate.getWrapperComponent(
                    this.props,
                )(this.props.formComponent),
            });
        }
    }

    render() {
        const propsAffectingFormValidate = Object.keys(
            DisconnectedFormValidate.propTypes,
        );
        const propsForWrapperComponent = omit(
            this.props,
            propsAffectingFormValidate,
        );

        return (
            <this.state.WrapperComponent
                {...propsForWrapperComponent}
            ></this.state.WrapperComponent>
        );
    }
}

export const RESET_FORM_FIELDS_ACTION_TYPE = 'redux-form/RESET_FIELDS';

/**
 * Reset the given fields on the given form. Should be used in conjunction with
 * the resetFieldsPlugin below.
 */
export const resetFieldsAction = ({ form, fields }) => ({
    type: RESET_FORM_FIELDS_ACTION_TYPE,
    payload: '',
    meta: {
        fields,
        form,
    },
});

const resetStateFields = (state, fields) => ({
    ...state,
    fields: omit(state.fields, ...fields),
    values: {
        ...(state.values || {}),
        ...fields.reduce(
            (accumulator, key) => ({
                ...accumulator,
                [key]: (state.initial && state.initial[key]) || undefined,
            }),
            {},
        ),
    },
});

/**
 * resetFieldsPlugin is designed to be used as a plugin for the redux-form
 * reducer to allow one to reset the values for specific form fields in the
 * form by dispatching the resetFieldsAction above.
 *
 * See http://redux-form.com/6.0.0-rc.5/docs/api/ReducerPlugin.md/
 *
 * @param form str name of the form for which to reset the fields
 */
export const resetFieldsPlugin = (form) => ({
    [form]: (state, action) => {
        if (
            action.type === RESET_FORM_FIELDS_ACTION_TYPE &&
            action.meta.form === form
        ) {
            return resetStateFields(state, action.meta.fields);
        }
        return state;
    },
});
