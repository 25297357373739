import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';
import classNames from 'classnames';

import './Header.scss';

import Search from '../../containers/ConnectedSearch';

import { Illustration } from '@eventbrite/eds-illustration';
import {
    AppMarketplaceHeroDesktop,
    AppMarketplaceHeroMobile,
    AppMarketplaceHeroTablet,
} from '../../svgs/index';

import { addWindowListener, removeWindowListener } from '@eventbrite/eds-utils';

import {
    SMALL_WIDE,
    LARGE_WIDE,
    LARGE,
    MEDIUM,
    SMALL,
} from '../../constants/constants';
export default class Header extends PureComponent {
    static propTypes = {
        headline: translationPropType.isRequired,
        secondary: translationPropType,
        align: PropTypes.string,
        margin: PropTypes.bool,
        hero: PropTypes.elementType,
        heroVerticalAlignCenter: PropTypes.bool,
        defaultHero: PropTypes.bool,
    };

    state = {
        hasWindow: false,
        windowSize: null,
    };

    componentDidMount() {
        addWindowListener('resize', this.updateHeaderImageByMediaQueryStatus);

        this.setState({
            hasWindow: true,
            windowSize: this.getWindowSize(),
        });
    }

    updateHeaderImageByMediaQueryStatus = () => {
        this.setState({
            windowSize: this.getWindowSize(),
        });
    };

    getImageForHeader = () => {
        switch (this.state.windowSize) {
            case LARGE:
                return <AppMarketplaceHeroDesktop />;
            case MEDIUM:
                return <AppMarketplaceHeroTablet />;
            case SMALL:
                return <AppMarketplaceHeroMobile />;
            default:
                return <AppMarketplaceHeroDesktop />;
        }
    };

    getWindowSize = () => {
        if (window.innerWidth >= LARGE_WIDE) {
            return LARGE;
        } else if (window.innerWidth >= SMALL_WIDE) {
            return MEDIUM;
        } else {
            return SMALL;
        }
    };

    componentWillUnmount() {
        if (this.state.hasWindow) {
            removeWindowListener(
                'resize',
                this.updateHeaderImageByMediaQueryStatus,
            );
        }
    }

    getClassBasedOnAlignProp(align) {
        switch (align) {
            case 'left':
            default:
                return {
                    headline: 'header--headline',
                    search: 'header--search',
                    image: classNames('header--hero', {
                        'vertical-align-center':
                            this.props.heroVerticalAlignCenter,
                    }),
                };
            case 'center':
                return {
                    headline: 'header--headline center',
                    search: 'header--search center',
                };
        }
    }

    render() {
        const classesBasesOnAlignment = this.getClassBasedOnAlignProp(
            this.props.align,
        );

        const headlineContainerClass = classesBasesOnAlignment.headline;
        const searchContainerClass = classesBasesOnAlignment.search;

        const imageContainerClass = classNames(classesBasesOnAlignment.image, {
            'header--hero__margin': this.props.margin,
            'header-container--illustration': this.props.defaultHero,
        });

        const headerContainerClass = classNames('header-container', {
            'default-hero-image': this.props.defaultHero,
        });

        const headerSecondaryHeadline = classNames(
            'eds-text-hs eds-l-mar-top-4 header-headline__secondary',
            {
                'always-show': this.props.alwaysShowSecondary,
            },
        );

        const HeroIllustration = this.props.hero;

        return (
            <div className={headerContainerClass}>
                <div className="header-container--headline_search">
                    <div className={headlineContainerClass}>
                        <h1 className="eds-text-hl eds-text-color--primary-brand--hover">
                            {this.props.headline}
                        </h1>
                        <h4 className={headerSecondaryHeadline}>
                            {this.props.secondary ? this.props.secondary : null}
                        </h4>
                    </div>

                    <div className={`${searchContainerClass}`}>
                        <Search />
                    </div>
                </div>
                <div className={imageContainerClass}>
                    {this.props.hero ? (
                        <Illustration
                            type={<HeroIllustration />}
                            alt={this.props.headline}
                            width={
                                this.state.windowSize == SMALL ? '60%' : '250px'
                            }
                            height="auto"
                        />
                    ) : (
                        <Illustration
                            type={this.getImageForHeader()}
                            alt="eventbrite-app-marketplace"
                            height="100%"
                            width="100%"
                        />
                    )}
                </div>
            </div>
        );
    }
}
