import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CategoryPage from '../pages/CategoryPage';
import { fetchAppsByCategory } from '../actions/Apps';

const _mapStateToProps = ({ appsByCategory, categories, isFetching }) => ({
    appsByCategory,
    categories,
    isFetching,
});
const _mapPropsToDispatch = {
    fetchAppsByCategory,
};

export default withRouter(
    connect(_mapStateToProps, _mapPropsToDispatch)(CategoryPage),
);
