import * as React from 'react';

const StarHalfFilledSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={16} height={16} {...props}>
        <defs>
            <path
                d="M6.667 1.93l-.001 7.737 3.251 2.154-.978-3.737 2.658-2.417H8.07L6.667 1.93zm0-1.93l1.865 5h4.801L9.667 8.333l1.333 5-4.334-2.87-4.333 2.87 1.334-5L0 5h4.799l1.868-5z"
                id="star-half-filled_svg__a"
            />
        </defs>
        <use
            fill="#f05537"
            xlinkHref="#star-half-filled_svg__a"
            transform="translate(1.333 1.333)"
            fillRule="evenodd"
        />
    </svg>
);

StarHalfFilledSvg.displayName = 'StarHalfFilledSvg';
export default StarHalfFilledSvg;
