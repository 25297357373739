import React, { PureComponent } from 'react';
import { GlobalHeader } from '@eventbrite/eds-global-header';
import { getLogoInfo, getUserMenuInfo } from '@eventbrite/site-header';
import {
    ENV_SHAPE_PROP_TYPE,
    REQUEST_SHAPE_PROP_TYPE,
    USER_SHAPE_PROP_TYPE,
} from '@eventbrite/site-structure';
import { gettext } from '@eventbrite/i18n';
import { EventbriteAppMarketplaceLogo } from '../../svgs/index';
import './CustomGlobalHeader.scss';

const logoOptions = {
    iconType: <EventbriteAppMarketplaceLogo />,
    iconWidth: '260px',
    iconHeight: '23px',
    iconColor: 'background-rgb',
};
export default class CustomGlobalHeader extends PureComponent {
    static propTypes = {
        env: ENV_SHAPE_PROP_TYPE.isRequired,
        request: REQUEST_SHAPE_PROP_TYPE.isRequired,
        user: USER_SHAPE_PROP_TYPE.isRequired,
    };

    _getQuickLinks(env, user) {
        const quickLinks = [];

        if (user.isAuthenticated) {
            quickLinks.push({
                url: `${env.serverUrl}/organizations/extensions/`,
                content: (
                    <span className="eds-text-weight--heavy">
                        {gettext('Manage apps')}
                    </span>
                ),
            });
        }

        return quickLinks;
    }

    _getNonUserQuickLinks(env, user) {
        const links = [];

        if (user.isAuthenticated) {
            return null;
        }

        links.push({
            url: `${env.serverUrl}/organizer/overview/`,
            content: (
                <span className="eds-text-weight--heavy">
                    {gettext('Create Events')}
                </span>
            ),
        });

        links.push({
            url: env.loginUrl,
            content: (
                <span className="eds-text-weight--heavy">
                    {gettext('Log In')}
                </span>
            ),
        });

        links.push({
            url: env.signupUrl,
            content: (
                <span className="site-header__link-menu site-header__type-pill">
                    {gettext('Sign Up')}
                </span>
            ),
        });

        return links;
    }

    render() {
        const { env, request, user } = this.props;

        const userMenuInfo = {
            serverUrl: env.serverUrl,
            adminServerUrl: env.adminServerUrl,
            logoutUrl: env.logoutUrl,
            requestPath: request.path,
            user,
            isNewAccountSettingsActive: user.isNewAccountSettingsActive,
            showBrowseEvents: false,
        };

        const logoInfo = getLogoInfo(env.serverUrl, request.path, {
            ...logoOptions,
            url: `${env.serverUrl}/apps/`,
        });

        return (
            <GlobalHeader
                logoInfo={logoInfo}
                userMenuInfo={getUserMenuInfo(userMenuInfo)}
                quickLinks={this._getQuickLinks(env, user)}
                nonUserQuickLinks={this._getNonUserQuickLinks(env, user)}
            />
        );
    }
}
