import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import isEmpty from 'lodash/isEmpty';
import MetaTags from 'react-meta-tags';

import { AppDiscoverySection } from '@eventbrite/app-discovery';
import { Layout } from '@eventbrite/eds-layout';

import CategoriesFilter from '../containers/ConnectedCategoriesFilter';
import {
    getCategoryIllustration,
    getCategoryInfo,
} from '../utils/categoryMapHelper';
import CategoriesExploreSection from '../containers/ConnectedCategoriesExploreSection';
import AppPagination from '../components/AppPagination/AppPagination';
import Header from '../components/Header/Header';
import {
    RECOMMENDED,
    POPULAR,
    MAX_APPS_TO_DISPLAY,
} from '../constants/constants';

export default class PicksPage extends PureComponent {
    static propTypes = {
        categories: PropTypes.arrayOf(PropTypes.shape).isRequired,
        appsListMap: PropTypes.objectOf(PropTypes.shape).isRequired,
        fetchPopularApps: PropTypes.func.isRequired,
        fetchFeaturedApps: PropTypes.func.isRequired,
        fetchRecommendedApps: PropTypes.func.isRequired,
    };

    constructor() {
        super();

        this.state = {
            pageName: RECOMMENDED,
            pagination: {
                pageNumber: 1,
            },
        };
    }

    componentDidMount() {
        const pageName = this.getPageName();
        const { fetchCategories, categoriesFetching } = this.props;

        this.fetchApps(pageName);

        if (!categoriesFetching) {
            fetchCategories();
        }
    }

    componentDidUpdate() {
        const pageName = this.getPageName();

        this.fetchApps(pageName);
    }

    fetchApps(pageName) {
        const {
            appsListMap,
            fetchPopularApps,
            fetchFeaturedApps,
            fetchRecommendedApps,
            isFetching,
            hasFailed,
            isUserSignedIn,
        } = this.props;

        if (
            !appsListMap.popularApps &&
            pageName === POPULAR &&
            !isFetching.popularApps &&
            !hasFailed.popularApps
        ) {
            fetchPopularApps();
        }

        if (!appsListMap.featuredApps && pageName === RECOMMENDED) {
            if (isUserSignedIn) {
                if (!isFetching.recommendedApps && !hasFailed.recommendedApps) {
                    fetchRecommendedApps();
                }
            } else if (!isFetching.featuredApps && !hasFailed.featuredApps) {
                fetchFeaturedApps();
            }
        }
    }

    getPageName() {
        const router = this.props.router;
        const pathName = router.getCurrentLocation().pathname;
        let pageName = RECOMMENDED;

        if (pathName === '/apps/popular' || pathName === '/apps/popular/') {
            pageName = POPULAR;
        }

        return pageName;
    }

    _handlePageSelection = (page) => {
        if (page !== this.state.pagination.pageNumber) {
            this.setState({ pagination: { pageNumber: page } });
        }
    };

    _getAppsDisplay = (pageName) => {
        const appsListMap = this.props.appsListMap;
        const { pageNumber } = this.state.pagination;

        let apps = appsListMap && appsListMap.featuredApps;

        if (pageName === POPULAR) {
            apps = appsListMap && appsListMap.popularApps;
        }

        if (isEmpty(apps)) {
            return null;
        }

        const appsByPage = chunk(apps, MAX_APPS_TO_DISPLAY);
        const currentlyViewableApps = appsByPage[pageNumber - 1];

        return (
            <div>
                <AppDiscoverySection
                    apps={currentlyViewableApps}
                    maxApps={MAX_APPS_TO_DISPLAY}
                />
                <AppPagination
                    appsByPage={appsByPage}
                    onPageSelection={this._handlePageSelection}
                    totalApps={apps.length}
                    pageNumber={pageNumber}
                />
            </div>
        );
    };

    render() {
        const pageName = this.getPageName();
        const categoryInfo = getCategoryInfo(pageName);
        const categoryName = categoryInfo && categoryInfo.name;
        const headline = categoryInfo && categoryInfo.description;

        return (
            <Layout maxWidth="large" hasHorizontalGutters={true}>
                <MetaTags>
                    <title>{`${categoryName} | Eventbrite App Marketplace`}</title>
                    <meta name="description" content={headline} />
                </MetaTags>
                <div className="eds-l-mar-bot-8">
                    <div>
                        <Header
                            headline={headline}
                            align="left"
                            hero={getCategoryIllustration(pageName)}
                            margin={true}
                            heroVerticalAlignCenter={true}
                        />
                        <div className="eds-l-mar-top-4">
                            <CategoriesFilter />
                        </div>
                    </div>
                    <div className="eds-l-mar-top-10">
                        <h2 className="eds-text-hs">{categoryName}</h2>
                    </div>
                    <div className="eds-l-mar-bot-10 eds-l-mar-top-4">
                        {this._getAppsDisplay(pageName)}
                    </div>
                </div>
                <div className="eds-l-mar-bot-10">
                    <CategoriesExploreSection />
                </div>
            </Layout>
        );
    }
}
