import PropTypes from 'prop-types';

export const DEFAULT_RESULTS_PER_PAGE = 20;
export const DEFAULT_RESULTS_PER_PAGE_OPTIONS = [5, 10, 20, 30, 40, 50];
export const PAGE_SIZE_CONTINUOUS = 'continuous';
export const PAGE_SIZE_LG = 'large';
export const PAGE_SIZE_MD = 'medium';
export const PAGE_SIZE_MIN = 'minimal';
// 'small' is in common use, so to provide backwards compatibility, functionality-wise,
// it will now map to medium, which was reduced in size as per spec. The spec version
// of small is named minimal
export const PAGE_SIZE_SM = 'small';
export const DEFAULT_PAGE_SIZE = PAGE_SIZE_MD;

export const PAGE_SIZES = {
    [PAGE_SIZE_LG]: 5,
    [PAGE_SIZE_MD]: 3,
    [PAGE_SIZE_SM]: 3,
    [PAGE_SIZE_MIN]: 1,
    [PAGE_SIZE_CONTINUOUS]: 1,
};
export const AVAILABLE_PAGE_SIZES = Object.keys(PAGE_SIZES);

export const STYLE_ORGANIZER = 'organizer';
export const STYLE_CONSUMER = 'consumer';

export const STYLES = [STYLE_ORGANIZER, STYLE_CONSUMER];

export const NUM_RESULTS_PROP_TYPE = (props) => {
    if (!props.totalNumResults && !props.pageCount) {
        return new Error(
            'One among totalNumResults or pageCount prop must be provided',
        );
    }

    return PropTypes.checkPropTypes(
        {
            totalNumResults: PropTypes.number,
        },
        {
            totalNumResults: props.totalNumResults,
        },
        'prop',
        'Pagination',
    );
};
