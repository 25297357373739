//
// This component could have the AutoComplete logic
// We will need to filter apps in the AutoComplete as the user types
// Research: How does AutoComplete currently work?
// What do we link to from each item in the autocomplete
//
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { InputField } from '@eventbrite/eds-input-field';
import { IconButton } from '@eventbrite/eds-icon-button';
import { MagnifyingGlassChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';

import './Search.scss';

const ENTER_KEY_CODE = 13;

const DEBOUNCE_SEARCH_DELAY = 500;

export default class Search extends PureComponent {
    static propTypes = {
        searchedTerm: PropTypes.objectOf(PropTypes.shape).isRequired,
        setSearchView: PropTypes.func.isRequired,
        searchApps: PropTypes.func,
    };

    constructor(props) {
        super();

        this.state = {
            searchTerm: props.searchedTerm.newSearch || '',
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const searchTerm = this.state.searchTerm;
        const { apps, router, searchApps } = this.props;
        const { searchTerm: prevSearchTerm } = prevState;
        const hasNewSearchTerm = searchTerm !== prevSearchTerm;
        const hasLoadedApps = !isEmpty(apps);
        const currentLocation = router.getCurrentLocation();
        const isSearchView = currentLocation.pathname.endsWith('/search/');
        const searchQuery = currentLocation.query.q;

        if (hasNewSearchTerm && hasLoadedApps && isSearchView) {
            this._searchApps(searchTerm, apps, currentLocation.pathname);
        } else if (
            isEmpty(prevProps.apps) &&
            !isEmpty(apps) &&
            !isEmpty(searchQuery)
        ) {
            this._handleSearchChange(searchQuery);
            searchApps(searchTerm, apps);
        }
    }

    _searchApps = debounce((searchTerm, apps, currentLocationPath) => {
        const { searchApps, router } = this.props;

        if (!searchTerm) {
            router.replace(`${currentLocationPath}`);
        } else {
            router.replace(`${currentLocationPath}?q=${searchTerm}`);
        }
        searchApps(searchTerm, apps);
    }, DEBOUNCE_SEARCH_DELAY);

    _handleSearch = () => {
        const { router } = this.props;
        const currentLocationPath = router.getCurrentLocation().pathname;
        const searchTerm = this.state.searchTerm;

        if (searchTerm) {
            this.props.setSearchView(searchTerm, currentLocationPath);
            this.props.searchApps(searchTerm, this.props.apps);
        }
    };

    _handleSearchChange = (value) => {
        this.props.setSearchedTerm(value);

        this.setState({ searchTerm: value });
    };

    _handleKeyDown = ({ event }) => {
        if (event && event.keyCode === ENTER_KEY_CODE) {
            event.preventDefault();

            this._handleSearch();
        }
    };

    render() {
        return (
            <form className="search-container eds-l-mar-bot-10">
                <InputField
                    id="search-app"
                    type="search"
                    label={gettext('Search apps')}
                    placeholder={gettext('Search apps')}
                    data-spec="search-app"
                    borderType="none"
                    bottomSpacing={0}
                    hasError={false}
                    name="search_app"
                    style="basic"
                    onChange={this._handleSearchChange}
                    value={this.state.searchTerm}
                    onKeyDown={this._handleKeyDown}
                    maxLength={45}
                />
                <div className="search-button">
                    <IconButton
                        iconType={<MagnifyingGlassChunky />}
                        title="Search"
                        onClick={this._handleSearch}
                    />
                </div>
            </form>
        );
    }
}
