// For local development, GA may not be loaded through core so you may need to add
// init('UA-141520-21');
// To App.js to initialize for testing

const loadGA = () => {
    const gaAddress = 'https://www.google-analytics.com/analytics.js';

    /* eslint-disable */
    (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        (i[r] =
            i[r] ||
            function () {
                (i[r].q = i[r].q || []).push(arguments);
            }),
            (i[r].l = 1 * new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    })(window, document, 'script', gaAddress, 'ga');
    /* eslint-enable */
};

const currentPath = () => window.location.pathname + window.location.search;

export const init = (trackingID) => {
    if (!window.ga) {
        loadGA();
    }
    window.ga('create', trackingID, 'auto');
    return;
};

export const pageview = (path = currentPath()) => {
    if (window.ga) {
        if (path) {
            window.ga('set', 'page', path);
        }
        window.ga('send', 'pageview');
    }
    return;
};

export default {
    init,
    pageview,
};
