import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { gettext } from '@eventbrite/i18n';

import InternalLink from '../InternalLink/InternalLink';
import { isMediumDown, isSmallWideDown } from '@eventbrite/eds-utils';

import { Avatar } from '@eventbrite/eds-avatar';
import ArrowLink from '../ArrowLink/ArrowLink';

import { BASE_APPS_URL } from '../../constants/constants';

export default class SpotlightSectionItem extends PureComponent {
    static propTypes = {
        app: PropTypes.objectOf(PropTypes.shape).isRequired,
    };

    render() {
        const { app } = this.props;

        const isSmallScreen = isSmallWideDown();

        const spotlightSpecs = {
            avatarSize: isSmallScreen ? 'small' : 'large',
        };

        if (isMediumDown() && !isSmallScreen) {
            spotlightSpecs.avatarSize = 'medium';
        }

        const appUrl = `${BASE_APPS_URL}${app.slug}/`;

        return (
            <div className="spotlight-section--item eds-l-mar-top-4">
                <div className="spotlight-section--item__logo eds-l-mar-right-6">
                    <Avatar
                        text={app.name}
                        imageUrl={app.logo_url}
                        size={spotlightSpecs.avatarSize}
                    />
                </div>
                <div className="spotlight-section--item__text">
                    <div className="eds-l-mar-bot-4">
                        <InternalLink to={appUrl} style="neutral">
                            <h2 className="eds-text-weight--heavy">
                                {app.name}
                            </h2>
                            <p className="eds-l-mar-top-2">
                                {app.localization.short_description}
                            </p>
                        </InternalLink>
                    </div>
                    <ArrowLink url={appUrl} text={gettext('Get app')} />
                </div>
            </div>
        );
    }
}
