import React, { PureComponent } from 'react';
import { Modal } from '@eventbrite/eds-modal';
import { Button } from '@eventbrite/eds-button';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

export default class MessageModal extends PureComponent {
    static propTypes = {
        shown: PropTypes.bool,
        onCloseAction: PropTypes.func,
        children: PropTypes.node,
    };

    _handleOnClose = () => {
        const { onCloseAction } = this.props;

        if (onCloseAction && isFunction(onCloseAction)) {
            onCloseAction();
        }
    };

    render() {
        const { shown, children } = this.props;

        return (
            <Modal
                isShown={shown}
                isClosable={true}
                onClose={this._handleOnClose}
                noMinHeight={true}
                noPadding={true}
            >
                <div className="eds-l-mar-all-4 eds-l-pad-all-12">
                    {children}

                    <div className="eds-align--center eds-l-pad-all-8">
                        <Button
                            type="button"
                            style="neutral"
                            onClick={this._handleOnClose}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}
