import { Location, LocationDescriptor } from 'history';
import React, { FunctionComponent } from 'react';
import { AppCardLinkProp, LINK_COMPONENT_TYPES_MAP } from '../constants';

const Link: FunctionComponent<{
    to: LocationDescriptor | ((location: Location) => LocationDescriptor);
    type: AppCardLinkProp;
    onClick?: () => void;
}> = ({ children, to, type, onClick }) => {
    const Component = LINK_COMPONENT_TYPES_MAP[type];

    return (
        <Component to={to} onClick={onClick}>
            {children}
        </Component>
    );
};

export default Link;
