/**
 * Whether to display an error, given the properties of a form element.
 * An error is displayed when:
 *   - a field is invalid
 *   - the user is not editing the field (i.e. not in focus)
 *   - the field has been edited or the form has been submitted,
 *     or the field has a value
 *
 * The input properties come from redux-form:
 * http://redux-form.com/6.8.0/docs/api/Field.md/#props
 *
 * @param error
 * @param value
 * @param submitFailed
 * @param dirty
 * @param active
 * @param touched
 * @returns {boolean|*}
 */
export const shouldDisplayError = ({
    error,
    value,
    submitFailed,
    dirty,
    touched,
}) => !!error && (!!value || submitFailed || dirty || touched);
