import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CategoriesFilter from '../components/CategoriesFilter/CategoriesFilter';

import { selectCategory } from '../actions';
import { fetchCategories } from '../actions/Categories';

const _mapStateToProps = ({
    categories,
    selectedCategory,
    categoriesFetching,
}) => ({
    categories,
    selectedCategory,
    categoriesFetching,
});

const _mapPropsToDispatch = {
    fetchCategories,
    selectCategory,
};

export default withRouter(
    connect(_mapStateToProps, _mapPropsToDispatch)(CategoriesFilter),
);
