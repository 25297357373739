import range from 'lodash/range';
import * as constants from './constants';

const isFirstPage = (pageNumber) => pageNumber === 1;

const isLastPage = (pageNumber, pageCount) => pageNumber === pageCount;

const showPrevEllipsis = (pageSize, pages = []) => {
    if (
        pageSize === constants.PAGE_SIZE_MIN ||
        pageSize === constants.PAGE_SIZE_CONTINUOUS
    ) {
        return false;
    }
    //This allows the ellipsis to show even when only the #1 is off the screen.
    return !!pages.length && pages[0].number > 1;
};

const showNextEllipsis = (pageSize, pages = [], pageCount = 0) => {
    if (
        pageSize === constants.PAGE_SIZE_MIN ||
        pageSize === constants.PAGE_SIZE_CONTINUOUS
    ) {
        return false;
    }

    return (
        !!pages.length &&
        //This has a count of all the hidden pages/numbers past what you can see.
        //This checks to make sure that you only stop showing ellipses when there
        //are no numbers past what you can see. (e.g. total 7 pages and you're at 4 on size medium.
        //One number (7) is still not on screen, so show the ellipsis.)
        Math.abs(pages[pages.length - 1].number - pageCount) > 0
    );
};

/**
 * returns the calculated offset for lower bound range. (from)
 * @example
 * // returns 2
 * scenario:
 * Viewport Large
 * @returns {integer} lower bound range
 */

const getFromOffset = (
    pageNumber,
    pageCount,
    middleRangeOffset,
    maxPagesShown,
    minBound,
) => {
    // If total count is less than pages shown for size return minBound
    // < 1 2 3 4 >
    if (pageCount <= maxPagesShown) {
        return minBound;
    }

    // If maxPagesShown is within 1 of pageCount, show all since ellipsis
    // doesn't really make sense on a spread of 5..6
    // < 1 2 3 4 5 6 >
    if (pageCount <= maxPagesShown + 1) {
        return minBound;
    }

    // If still within the first range of pages, return up to that
    // < 1 2 3 4 5 .. 10 >
    if (pageNumber <= maxPagesShown) {
        return minBound;
    }

    // If within the maxPagesShown from end of results
    // < 1 .. 5 6 7 8 9 10 >
    if (pageNumber > pageCount - maxPagesShown) {
        return pageCount - maxPagesShown + 1;
    }

    // default -> page is in the middle, and we have enough pages in both sides.
    // < 1 .. 3 4 5 6 7 .. 10 >
    return pageNumber - middleRangeOffset;
};

/**
 * returns the calculated offset for upper bound range. (to)
 * @example
 * // returns 2
 * scenario:
 * Viewport Large
 * skipping first and applying corrections
 * @returns {number} upper bound range
 */

const getToOffset = (
    pageNumber,
    pageCount,
    middleRangeOffset,
    maxPagesShown,
    maxBound,
) => {
    // If total count is less than pages shown for size return maxBound
    // < 1 2 3 4 >
    if (pageCount <= maxPagesShown) {
        return maxBound;
    }

    // If maxPagesShown is within 1 of pageCount, show all since ellipsis
    // doesn't really make sense on a spread of 5..6
    // < 1 2 3 4 5 6 >
    if (pageCount <= maxPagesShown + 1) {
        return maxBound;
    }

    // If still within the first range of pages, return up to that
    // < 1 2 3 4 5 .. 10 >
    if (pageNumber <= maxPagesShown) {
        return maxPagesShown + 1;
    }

    // If within the maxPagesShown from end of results
    // Also handles potential to be at or past maxBound
    // < 1 .. 5 6 7 8 9 10 >
    if (pageNumber > pageCount - maxPagesShown) {
        return maxBound;
    }

    // default -> page is in the middle, and we have enough pages in both sides (plus one to account for ellipses)
    // < 1 .. 3 4 5 6 7 .. 10 >
    return pageNumber + middleRangeOffset + 1;
};

// num of pages to display by ViewPort / 2
// because we need to find the offset for each side of
// the selected page.
const getMiddleRangeOffset = (maxPagesShown) => Math.floor(maxPagesShown / 2);

/**
 * returns the calculated range of pages
 * @example
 * // returns [1,2,3,4,5,6,7,8,9]
 * scenario:
 * Viewport Large
 * @param {number} pageNumber current page
 * @param {number} pageCount total pages
 * @param {string} pageSize  size of the page we are trying to display pagination for.
 * @returns {Array} range of pages to display
 */
const getPagesRange = (pageNumber, pageCount, pageSize) => {
    if (pageSize === constants.PAGE_SIZE_MIN) {
        return [pageNumber];
    }

    //we always want the last page to show up
    const maxBound = pageCount + 1;
    //we always want the first page to show up
    const minBound = 1;
    const maxPagesShown = constants.PAGE_SIZES[pageSize];
    const middleRangeOffset = getMiddleRangeOffset(maxPagesShown);
    const from = getFromOffset(
        pageNumber,
        pageCount,
        middleRangeOffset,
        maxPagesShown,
        minBound,
    );
    const to = getToOffset(
        pageNumber,
        pageCount,
        middleRangeOffset,
        maxPagesShown,
        maxBound,
    );
    // what happens when the currentPage is already given by the template?
    const isCurrentPageWithinLimits =
        pageNumber > minBound && pageNumber < maxBound;

    // we only have 1 page to show in the range. and is not already provided by the template itself.
    if (from === to && isCurrentPageWithinLimits) {
        return [pageNumber];
    }

    return range(from, to, 1);
};

/**
 * returns an object for page with
 * page number, url and if that page is selected
 * @example
 * // returns [{ number: 2, url: '', isSelected: true}]
 * scenario:
 * Viewport Large
 * page_number: 2, page_count: 3
 * @returns {Array} range of pages to display
 */

const getPages = (pageNumber, pageCount, pageSize) =>
    getPagesRange(pageNumber, pageCount, pageSize).map((number) => ({
        number,
        isSelected: pageNumber === number,
    }));

const getPaginationInfo = ({
    pageNumber = 1,
    pageCount = 0,
    totalNumResults = 0,
    currentResultsPerPage = constants.DEFAULT_RESULTS_PER_PAGE,
    pageSize = constants.DEFAULT_PAGE_SIZE,
}) => {
    let calculatedPageCount = pageCount;

    // If totalNumResults is defined, let's use that instead of pageCount
    if (totalNumResults) {
        calculatedPageCount = Math.ceil(
            totalNumResults / currentResultsPerPage,
        );
    }

    const pages = getPages(pageNumber, calculatedPageCount, pageSize);
    const resultMax = pageNumber * currentResultsPerPage;
    const currentResultMax =
        resultMax > totalNumResults ? totalNumResults : resultMax;
    const currentResultMin = resultMax - (currentResultsPerPage - 1);

    return {
        isPrevDisabled: isFirstPage(pageNumber),
        isNextDisabled: isLastPage(pageNumber, calculatedPageCount),
        showPrevEllipsis: showPrevEllipsis(pageSize, pages),
        showNextEllipsis: showNextEllipsis(
            pageSize,
            pages,
            calculatedPageCount,
        ),
        isFirstPage: isFirstPage(pageNumber),
        isLastPage: isLastPage(pageNumber, calculatedPageCount),
        pages,
        pageCount: calculatedPageCount,
        currentResultMax,
        currentResultMin,
    };
};

export default getPaginationInfo;
