import React, { PureComponent } from 'react';
import { Layout } from '@eventbrite/eds-layout';
import find from 'lodash/find';
import { gettext } from '@eventbrite/i18n';
import { AppDiscoverySection } from '@eventbrite/app-discovery';
import MetaTags from 'react-meta-tags';

import Header from '../components/Header/Header';
import CategoriesFilter from '../containers/ConnectedCategoriesFilter';
import SpotlightSection from '../containers/ConnectedSpotlightSection';
import CategoriesExploreSection from '../containers/ConnectedCategoriesExploreSection';

export default class HomePage extends PureComponent {
    constructor() {
        super();

        this.state = {
            marketingCategory: null,
        };
    }

    UNSAFE_componentWillMount() {
        const {
            fetchPopularApps,
            fetchFeaturedApps,
            fetchRecommendedApps,
            appsListMap,
            isUserSignedIn,
        } = this.props;

        if (!appsListMap.popularApps) {
            fetchPopularApps();
        }

        if (isUserSignedIn && !appsListMap.featuredApps) {
            fetchRecommendedApps();
        } else if (!appsListMap.featuredApps) {
            fetchFeaturedApps();
        }
    }

    componentDidMount() {
        const { categoriesFetching, fetchCategories } = this.props;

        if (!categoriesFetching) {
            fetchCategories();
        }
    }

    componentDidUpdate() {
        const { categories, fetchMarketingApps, isFetching } = this.props;

        let marketingCategory;

        if (
            categories.length > 0 &&
            !this.state.marketingCategory &&
            !isFetching.marketingApps
        ) {
            marketingCategory =
                find(categories, { slug: 'marketing-and-promotion' }) ||
                find(categories, { slug: 'promotion-and-advertising' });

            if (marketingCategory) {
                this.setState({ marketingCategory });

                fetchMarketingApps(marketingCategory.id);
            }
        }
    }

    _refreshMarketingApps = () => {
        const { marketingCategory } = this.state;

        const { fetchMarketingApps } = this.props;

        if (marketingCategory) {
            fetchMarketingApps(marketingCategory.id);
        }
    };

    _refreshFeaturedOrRecommendedApps = () => {
        const { fetchFeaturedApps, fetchRecommendedApps, isUserSignedIn } =
            this.props;

        if (isUserSignedIn) {
            fetchRecommendedApps();
        } else {
            fetchFeaturedApps();
        }
    };

    _refreshPopularApps = () => {
        const { fetchPopularApps } = this.props;

        fetchPopularApps();
    };

    render() {
        const { appsListMap, isFetching, hasFailed } = this.props;

        const marketingCategory = this.state.marketingCategory;

        // Translators: 'Eventbrite App Marketplace' and 'Spectrum' are the marketplace names and shouldn't be translated
        const pageTitle = gettext(
            'Eventbrite App Marketplace | Apps for Your Events (Formerly Spectrum)',
        );

        return (
            <Layout maxWidth="large" hasHorizontalGutters={true}>
                <MetaTags>
                    <title>{pageTitle}</title>
                    <meta
                        name="description"
                        content={gettext(
                            'Discover apps that work seamlessly with Eventbrite to help you save time, sell more tickets in more places, and grow your event. Facebook, Mailchimp, HubSpot, Constant Contact and SurveyMonkey are just a few apps our event organizers love.',
                        )}
                    />
                </MetaTags>
                <div className="eds-l-mar-bot-4">
                    <Header
                        headline={gettext(
                            'Find the right app to create unforgettable events',
                        )}
                        secondary={gettext('Easy to use. Made just for you.')}
                        align="left"
                        defaultHero={true}
                    />
                </div>
                <div className="eds-g-grid eds-l-mar-bot-8">
                    <div className="eds-g-cell eds-g-cell-lg-12-12 eds-g-cell-12-12">
                        <CategoriesFilter />
                    </div>
                </div>
                <div className="eds-l-mar-bot-10">
                    <AppDiscoverySection
                        sectionTitle={gettext('Popular')}
                        apps={(appsListMap && appsListMap.popularApps) || []}
                        linkUrl="/apps/popular/"
                        isLoading={isFetching.popularApps}
                        hasFailed={hasFailed.popularApps}
                        refreshContentHandler={this._refreshPopularApps}
                    />
                </div>
                <div className="eds-l-mar-bot-10">
                    <AppDiscoverySection
                        sectionTitle={gettext('Recommended')}
                        apps={(appsListMap && appsListMap.featuredApps) || []}
                        linkUrl="/apps/recommended/"
                        isLoading={
                            isFetching.featuredApps ||
                            isFetching.recommendedApps
                        }
                        hasFailed={
                            hasFailed.featuredApps || hasFailed.recommendedApps
                        }
                        refreshContentHandler={
                            this._refreshFeaturedOrRecommendedApps
                        }
                        shouldTrackAppRecLoaded={true}
                    />
                </div>
                <div className="eds-l-mar-bot-12">
                    <SpotlightSection />
                </div>
                {marketingCategory ? (
                    <div className="eds-l-mar-bot-10">
                        <AppDiscoverySection
                            sectionTitle={marketingCategory.name_localized}
                            apps={
                                (appsListMap && appsListMap.marketingApps) || []
                            }
                            linkUrl={`/apps/categories/${marketingCategory.slug}/`}
                            isLoading={isFetching.marketingApps}
                            refreshContentHandler={this._refreshMarketingApps}
                            hasFailed={hasFailed.marketingApps}
                        />
                    </div>
                ) : null}
                <div className="eds-l-mar-bot-12">
                    <CategoriesExploreSection />
                </div>
            </Layout>
        );
    }
}
