import { connect } from 'react-redux';
import ReviewModal from '../components/modals/ReviewModal';
import {
    createNewReview,
    editReview,
    setRatingForSelectedApp,
} from '../actions/Ratings';

const _mapStateToProps = ({
    currentAppRating,
    currentApp,
    reviewFromUser,
    userLocale,
}) => ({
    currentAppRating:
        currentAppRating ||
        (reviewFromUser && reviewFromUser.rating) ||
        undefined,
    currentAppReviewId: (reviewFromUser && reviewFromUser.id) || undefined,
    currentApp,
    userLocale,
});

const _mapPropsToDispatch = {
    createNewReview,
    editReview,
    setRatingForSelectedApp,
};

export default connect(_mapStateToProps, _mapPropsToDispatch)(ReviewModal);
