import { connect } from 'react-redux';

import NotFound from '../components/NotFound';

import { fetchCategories } from '../actions/Categories';

const _mapStateToProps = ({ categoriesFetching }) => ({
    categoriesFetching,
});

const _mapPropsToDispatch = {
    fetchCategories,
};

export default connect(_mapStateToProps, _mapPropsToDispatch)(NotFound);
