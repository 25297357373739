import { deepKeysToCamel } from '@eventbrite/transformation-utils';
import { setLoading, setIsFetching, setHasFailed } from '.';
import { flattenCategoriesForApps } from '../utils/transforms';
import { getPaginatedApps, getAppsByCategory, getAllApps } from '../api';
import { RECOMMENDED_STATIC_APPS } from '../constants/constants';

export const LOAD_APPS = 'LOAD_APPS';
export const LOAD_POPULAR_APPS = 'LOAD_POPULAR_APPS';
export const LOAD_FEATURED_APPS = 'LOAD_FEATURED_APPS';
export const LOAD_APPS_BY_CATEGORY = 'LOAD_APPS_BY_CATEGORY';
export const LOAD_CURRENT_APP = 'LOAD_CURRENT_APP';
export const LOAD_MARKETING_APPS = 'LOAD_MARKETING_APPS';
const FEATURED_APPS = 'featuredApps';

const loadApps = (apps) => ({ type: LOAD_APPS, payload: apps });
const loadPopularApps = (apps) => ({ type: LOAD_POPULAR_APPS, payload: apps });
const loadFeaturedApps = (apps) => ({
    type: LOAD_FEATURED_APPS,
    payload: apps,
});
const loadAppsByCategory = (appsAndSlug) => ({
    type: LOAD_APPS_BY_CATEGORY,
    payload: appsAndSlug,
});
const loadCurrentApp = (app) => ({ type: LOAD_CURRENT_APP, payload: app });
const loadMarketingApps = (apps) => ({
    type: LOAD_MARKETING_APPS,
    payload: apps,
});

export const fetchPopularApps = () => (dispatch) => {
    const POPULAR_APPS = 'popularApps';
    const params = {
        picks: 'popular',
        'expand.app': 'details',
    };

    dispatch(setIsFetching({ type: POPULAR_APPS, value: true }));
    dispatch(setHasFailed({ type: POPULAR_APPS, value: false }));

    return getAllApps(params).then(
        (apps) => {
            dispatch(setIsFetching({ type: POPULAR_APPS, value: false }));

            return dispatch(loadPopularApps(apps));
        },
        (error) => {
            dispatch(setIsFetching({ type: POPULAR_APPS, value: false }));
            dispatch(setHasFailed({ type: POPULAR_APPS, value: true }));
            throw error;
        },
    );
};

export const fetchFeaturedApps = () => (dispatch) => {
    const params = {
        picks: 'featured',
        'expand.app': 'details',
    };

    dispatch(setIsFetching({ type: FEATURED_APPS, value: true }));
    dispatch(setHasFailed({ type: FEATURED_APPS, value: false }));

    return getAllApps(params).then(
        (apps) => {
            dispatch(setIsFetching({ type: FEATURED_APPS, value: false }));

            return dispatch(loadFeaturedApps(apps));
        },
        (error) => {
            dispatch(setIsFetching({ type: FEATURED_APPS, value: false }));
            dispatch(setHasFailed({ type: FEATURED_APPS, value: true }));
            throw error;
        },
    );
};

export const fetchRecommendedApps = () => (dispatch) => {
    dispatch(loadFeaturedApps(RECOMMENDED_STATIC_APPS));
};

export const fetchAllApps = (params) => (dispatch) => {
    dispatch(setHasFailed({ type: 'apps', value: false }));
    return getAllApps(params).then(
        (apps) => dispatch(loadApps(flattenCategoriesForApps(apps))),
        (error) => {
            dispatch(setHasFailed({ type: 'apps', value: true }));
            throw error;
        },
    );
};

export const fetchAppsByCategory = (categoryId, slug) => (dispatch) => {
    dispatch(setIsFetching({ type: 'appsByCategory', value: true }));

    return getAppsByCategory(categoryId).then(
        (apps) => {
            dispatch(setIsFetching({ type: 'appsByCategory', value: false }));
            return dispatch(loadAppsByCategory({ apps, slug }));
        },
        (error) => {
            dispatch(setIsFetching({ type: 'appsByCategory', value: false }));
            throw error;
        },
    );
};

export const fetchApp = (params) => (dispatch) => {
    dispatch(setLoading(true));

    return getPaginatedApps(params).then(
        (apps) => {
            dispatch(setLoading(false));

            const currentApp = apps.length ? apps[0] : {};

            return dispatch(loadCurrentApp(deepKeysToCamel(currentApp)));
        },
        (error) => {
            dispatch(setLoading(false));
            throw error;
        },
    );
};

export const fetchMarketingApps = (categoryId) => (dispatch) => {
    dispatch(setIsFetching({ type: 'marketingApps', value: true }));

    return getAppsByCategory(categoryId).then(
        (apps) => {
            dispatch(setIsFetching({ type: 'marketingApps', value: false }));

            return dispatch(loadMarketingApps(apps));
        },
        (error) => {
            dispatch(setIsFetching({ type: 'marketingApps', value: false }));
            throw error;
        },
    );
};
