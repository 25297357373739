import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { HotKeys } from 'react-hotkeys';
import { Divider } from '@eventbrite/eds-divider';
import {
    MOVE_TO_NEXT,
    MOVE_TO_PREV,
    MOVE_TO_FIRST,
    MOVE_TO_LAST,
} from '@eventbrite/eds-hot-keys';
import { ACTION_KEY_MAP } from '../hotKeys';
import '../tabs.scss';
import classNames from 'classnames';
import {
    DIRECTION_VERTICAL,
    FORMAT_PILL,
    TAB_DIRECTION_PROP_TYPE,
    TAB_FORMAT_PROP_TYPE,
} from '../constants';

class HotTabs extends PureComponent {
    static propTypes = {
        /**
         * List of TabItems with a currently focused tab derived from a focus position
         */
        tabItems: PropTypes.arrayOf(PropTypes.node).isRequired,
        /**
         * Callback that handles the changing of focus on a tab
         */
        onChangeTab: PropTypes.func.isRequired,

        /**
         * Format of tabs: "tab" or "pill"
         */
        tabFormat: TAB_FORMAT_PROP_TYPE.isRequired,

        /**
         * Direction of tabs: "horizontal" or "vertical"
         */
        tabDirection: TAB_DIRECTION_PROP_TYPE.isRequired,
        /**
         * Class names for HotTabs component wrapper
         */
        __containerClassName: PropTypes.string,
    };

    hotKeyHandlers = {
        [MOVE_TO_NEXT]: (e) => this.props.onChangeTab(e, MOVE_TO_NEXT),
        [MOVE_TO_PREV]: (e) => this.props.onChangeTab(e, MOVE_TO_PREV),
        [MOVE_TO_FIRST]: (e) => this.props.onChangeTab(e, MOVE_TO_FIRST),
        [MOVE_TO_LAST]: (e) => this.props.onChangeTab(e, MOVE_TO_LAST),
    };

    render() {
        const { tabItems, tabFormat, tabDirection, __containerClassName } =
            this.props;
        const tabClasses = classNames(
            'eds-tabs__navigation',
            'eds-l-pad-vert-3',
            'eds-l-mar-bot-3',
            {
                'eds-tabs__vertical-nav eds-tabs__flex-column':
                    tabDirection === DIRECTION_VERTICAL,
                'eds-tabs__pills': tabFormat === FORMAT_PILL,
            },
            __containerClassName,
        );
        return (
            <HotKeys keyMap={ACTION_KEY_MAP} handlers={this.hotKeyHandlers}>
                <ul className={tabClasses} role="tablist">
                    {tabItems}
                    <Divider />
                </ul>
            </HotKeys>
        );
    }
}

export default HotTabs;
