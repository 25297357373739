import { connect } from 'react-redux';

import HomePage from '../pages/HomePage';
import {
    fetchPopularApps,
    fetchFeaturedApps,
    fetchRecommendedApps,
    fetchMarketingApps,
} from '../actions/Apps';
import { fetchCategories } from '../actions/Categories';

const _mapStateToProps = ({
    appsListMap,
    categories,
    isFetching,
    hasFailed,
    categoriesFetching,
    isUserSignedIn,
}) => ({
    appsListMap,
    categories,
    isFetching,
    hasFailed,
    categoriesFetching,
    isUserSignedIn,
});

const _mapPropsToDispatch = {
    fetchFeaturedApps,
    fetchRecommendedApps,
    fetchPopularApps,
    fetchMarketingApps,
    fetchCategories,
};

export default connect(_mapStateToProps, _mapPropsToDispatch)(HomePage);
