import { connect } from 'react-redux';

import ReviewForm from '../components/reviewForm/ReviewForm';
import { initializeReviewForm } from '../actions/Ratings';

const _mapStateToProps = ({ currentAppRating, reviewFromUser }) => ({
    currentAppRating:
        currentAppRating ||
        (reviewFromUser && reviewFromUser.rating) ||
        undefined,
    currentAppReviewText:
        (reviewFromUser && reviewFromUser.reviewText) || undefined,
    currentAppReviewTitle:
        (reviewFromUser && reviewFromUser.reviewTitle) || undefined,
});

const _mapPropsToDispatch = {
    initializeReviewForm,
};

export default connect(_mapStateToProps, _mapPropsToDispatch)(ReviewForm);
