import * as React from 'react';

const PopularSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 276 221" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M159.032 195.05C143.247 204.54 124.761 210 105 210 47.01 210 0 162.99 0 105S47.01 0 105 0c53.253 0 97.246 39.643 104.079 91.028A66.214 66.214 0 01211 91c35.899 0 65 29.101 65 65s-29.101 65-65 65c-21.244 0-40.107-10.191-51.968-25.95z"
                fill="#82D2DC"
                fillRule="nonzero"
                opacity={0.3}
            />
            <g transform="translate(65)">
                <path
                    d="M131.584 94.584a137.143 137.143 0 01-3.12 3.71c-8.17 9.38-14.945 14.644-20.464 17.587v33.085H75.724v-31.189c-6.154-2.32-14.268-7.596-24.621-19.483a136.97 136.97 0 01-3.202-3.811C14.241 86.823-1.679 59.947.14 13.854h28.412V0h122.463v13.854h28.782c1.827 46.299-14.244 73.209-48.213 80.73zm9.336-13.307c17.243-6.08 26.296-25.396 27.159-57.947h-17.064v29.158c-1.015 9.539-4.38 19.135-10.095 28.79zM28.552 23.33H11.858c.855 32.254 9.751 51.513 26.689 57.777-5.654-9.596-8.986-19.136-9.995-28.62V23.33z"
                    fill="#F05537"
                />
                <path
                    d="M61.828 146.483h60.069a1 1 0 011 1v12.896a1 1 0 01-1 1h-60.07a1 1 0 01-1-1v-12.896a1 1 0 011-1z"
                    fill="#E11C0B"
                />
                <rect
                    fill="#F05537"
                    x={48.414}
                    y={156.414}
                    width={85.655}
                    height={64.552}
                    rx={1}
                />
            </g>
            <path fill="#FFF" d="M119.62 176.828h74.484v24.828H119.62z" />
            <path
                d="M129.552 186.138h55.345M129.552 194.828h55.345"
                stroke="#1A0E2F"
                strokeLinecap="square"
            />
        </g>
    </svg>
);

PopularSvg.displayName = 'PopularSvg';
export default PopularSvg;
