import { createMemoryHistory as createMemoryHistoryRouterV4 } from 'history';
import { browserHistory, createMemoryHistory } from 'react-router';

/**
 * Provides an environment appropriate history management object for react-router
 */
export const getHistory = (routerConfig: { isRouterV4?: boolean } = {}) => {
    const hasBrowserHistory =
        typeof browserHistory !== 'undefined' &&
        process.env.NODE_ENV !== 'test';

    if (hasBrowserHistory) {
        return browserHistory;
    }

    if (routerConfig.isRouterV4) {
        return createMemoryHistoryRouterV4();
    }

    return createMemoryHistory();
};
