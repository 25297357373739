import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@eventbrite/eds-link';

import ChartBar from './ChartBar';
import { gettext } from '@eventbrite/i18n';
import { starNumberAsWordToDigit } from '../../utils/transforms';
import { RATINGS_SUMMARY_STARS_SHAPE } from '../../constants/constants';

const RatingsChart = ({
    appId,
    clearRatings,
    fetchRatings,
    setPageNumber,
    setRatingFilter,
    stars,
    totalReviews,
}) => {
    const percentageRating = (ratingPerStar) =>
        (ratingPerStar / totalReviews) * 100;

    const _handleRatingCountClick = (ratingPerStar) => {
        const updateRatingList = () => {
            if (appId) {
                setRatingFilter(String(ratingPerStar));
                clearRatings();
                setPageNumber(1);
                fetchRatings(appId);
            }
        };

        return updateRatingList;
    };

    const buildChartBar = (starNumberAsWord, ratingPerStar) => {
        const starNumberAsDigit = starNumberAsWordToDigit(starNumberAsWord);
        // Translators: This is for a star rating, such as "5 star" or "3 star"
        const title = gettext('%(starNumberAsDigit)s Star', {
            starNumberAsDigit,
        });

        const renderRatingCount = () => {
            let component = (
                <div className="eds-text-color--ui-500">({ratingPerStar})</div>
            );

            if (ratingPerStar > 0) {
                component = (
                    <Link
                        to="#ratings"
                        onClick={_handleRatingCountClick(starNumberAsDigit)}
                    >
                        ({ratingPerStar})
                    </Link>
                );
            }

            return component;
        };

        return (
            <tr key={starNumberAsDigit} className="chart-row eds-l-mar-bot-4">
                <td className="chart-bar__title eds-text-weight--heavy">
                    {title}
                </td>
                <td>
                    <ChartBar value={percentageRating(ratingPerStar)} />
                </td>
                <td className="chart-bar__count">{renderRatingCount()}</td>
            </tr>
        );
    };

    const renderChartBars = () => {
        const chartBars = [];

        Object.keys(stars).map((starNumberAsWord) =>
            chartBars.push(
                buildChartBar(starNumberAsWord, stars[starNumberAsWord]),
            ),
        );
        return chartBars.sort((a, b) => b.key - a.key);
    };

    return (
        <table className="ratings-chart">
            <tbody>{stars && renderChartBars()}</tbody>
        </table>
    );
};

RatingsChart.propTypes = {
    appId: PropTypes.string,
    clearRatings: PropTypes.func,
    fetchRatings: PropTypes.func,
    setPageNumber: PropTypes.func,
    setRatingFilter: PropTypes.func,
    stars: RATINGS_SUMMARY_STARS_SHAPE,
    totalReviews: PropTypes.number.isRequired,
};

export default RatingsChart;
