import React from 'react';
import { Icon } from '@eventbrite/eds-icon';
import { RefreshChunky } from '@eventbrite/eds-iconography';
import { Button } from '@eventbrite/eds-button';

import './RefreshContent.scss';

const RefreshContent = (props) => {
    const { onClickHandler } = props;

    return (
        <div className="refresh-content">
            <div className="refresh-content--message eds-text-weight--heavy">
                Whoa, something weird happened here. Try refreshing this
                section.
            </div>
            <div className="refresh-content--button">
                <Button style="none" onClick={onClickHandler}>
                    <Icon type={<RefreshChunky />} size="small" />
                </Button>
            </div>
        </div>
    );
};

export default RefreshContent;
