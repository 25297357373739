import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router';

import './InternalLink.scss';

export default class InternalLink extends PureComponent {
    static propTypes = {
        to: PropTypes.string.isRequired,
        style: PropTypes.string,
        onClick: PropTypes.func,
    };

    style = Object.freeze({
        neutral: 'neutral',
        inverse: 'inverse',
        tab: 'tab',
        tabSelected: 'tabSelected',
    });

    styleToClassMap = {
        neutral: 'internal-link--neutral',
        inverse: 'internal-link--inverse',
        tab: 'internal-link--tab',
        tabSelected: 'internal-link--tab__selected',
    };

    render() {
        const { onClick, to, style } = this.props;

        return (
            <Link
                onClick={onClick ? () => onClick() : null}
                className={
                    style ? this.styleToClassMap[style] : this.style.neutral
                }
                to={to}
            >
                {this.props.children}
            </Link>
        );
    }
}
