import { getCategories } from '../api';

export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const CATEGORIES_FETCHING = 'CATEGORIES_FETCHING';

const loadCategories = (categories) => ({
    type: LOAD_CATEGORIES,
    payload: categories,
});
const categoriesFetching = (fetching) => ({
    type: CATEGORIES_FETCHING,
    payload: fetching,
});

// eslint-disable-next-line arrow-body-style
export const fetchCategories = () => (dispatch) => {
    dispatch(categoriesFetching(true));

    // eslint-disable-next-line arrow-body-style
    return getCategories().then((categories) => {
        return dispatch(loadCategories(categories));
    });
};
