export const getInitialState = () => ({
    isLoading: false,
    appsListMap: {
        apps: null,
        popularApps: null,
        featuredApps: null,
        marketingApps: null,
    },
    appsByCategory: {},
    isFetching: {},
    hasFailed: {},
    currentApp: {},
    filteredApps: [],
    searchedTerm: {},
    categories: [],
    paginationObj: {},
    selectedCategory: -1,
    userLocale: '',
    isUserSignedIn: false,
});
