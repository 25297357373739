import { CheckChunky } from '@eventbrite/eds-iconography';
import React, { FunctionComponent } from 'react';
import './InstalledBadge.scss';

const InstalledBadge: FunctionComponent = () => (
    <span className="installed-badge">
        <CheckChunky />
    </span>
);

export default InstalledBadge;
