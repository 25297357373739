import includes from 'lodash/includes';
import isFunction from 'lodash/isFunction';

const REDUX_FORM_EVENT_HANDLERS = [
    'onBlur',
    'onChange',
    'onDragStart',
    'onDrop',
    'onFocus',
];

const propNeedsMerge = (propName, componentProps) =>
    includes(REDUX_FORM_EVENT_HANDLERS, propName) &&
    isFunction(componentProps[propName]);

/**
 * Creates and returns a merge between the component original handlers and
 * the ones in the reduxFormFieldProps giving priority to these.
 *
 * @param {React.Component} component
 * @param {object} reduxFormFieldProps
 * @returns {object}
 */
export const mergeEventHandlers = (component, reduxFormFieldProps) => {
    const mergedHandlers = Object.keys(reduxFormFieldProps)
        .filter((propName) => propNeedsMerge(propName, component.props))
        .reduce(
            (handlers, propName) => ({
                ...handlers,
                [propName]: (eventOrValue) => {
                    component.props[propName](eventOrValue);
                    reduxFormFieldProps[propName](eventOrValue);
                },
            }),
            {},
        );

    return {
        ...reduxFormFieldProps,
        ...mergedHandlers,
    };
};
