import { connect } from 'react-redux';

import AppProductPage from '../pages/AppProductPage';
import {
    clearRatings,
    clearReviewFromUser,
    fetchAppRatingsByUser,
    fetchRatings,
} from '../actions/Ratings';

const _mapStateToProps = ({ currentApp }) => ({
    ...currentApp,
});

const _mapPropsToDispatch = {
    clearRatings,
    clearReviewFromUser,
    fetchAppRatingsByUser,
    fetchRatings,
};

export default connect(_mapStateToProps, _mapPropsToDispatch)(AppProductPage);
