import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';
import RatingStars from '../../containers/ConnectedRatingStars';
import ReviewForm from '../../containers/ConnectedReviewForm';
import { LoadingOverlay } from '@eventbrite/eds-loading-overlay';
import { gettext } from '@eventbrite/i18n';

export default class EditReviewModalContent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func.isRequired,
        submitButtonText: translationPropType.isRequired,
    };

    render() {
        const { isLoading, onSubmit, submitButtonText } = this.props;

        return (
            <div className="eds-l-pad-all-4">
                <h2 className="eds-text-hs eds-align--left eds-l-mar-top-8">
                    {gettext('Rating submitted')}
                </h2>
                <p className="eds-text-weight--heavy eds-l-mar-top-2">
                    {gettext('Thanks for your feedback!')}
                </p>
                <div className="eds-l-mar-top-4 eds-l-mar-bot-10">
                    <RatingStars isReadOnly={false} size="medium" />
                </div>
                <div className="eds-l-mar-top-6">
                    <ReviewForm
                        onSubmit={onSubmit}
                        submitButtonText={submitButtonText}
                    />
                </div>
                <LoadingOverlay isShown={isLoading} />
            </div>
        );
    }
}
