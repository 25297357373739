import { connect } from 'react-redux';

import RatingsOverview from '../components/ratingsOverview/RatingsOverview';
import {
    clearRatings,
    fetchRatings,
    setPageNumber,
    setRatingFilter,
} from '../actions/Ratings';

const _mapPropsToDispatch = {
    clearRatings,
    fetchRatings,
    setPageNumber,
    setRatingFilter,
};

export default connect(null, _mapPropsToDispatch)(RatingsOverview);
