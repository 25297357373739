import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import random from 'lodash/random';
import { gettext } from '@eventbrite/i18n';

import CategoriesExploreItem from './CategoriesExploreItem';
import './CategoriesExploreSection.scss';

import { getCategoryIllustration } from '../../utils/categoryMapHelper';

export default class CategoriesExploreSection extends PureComponent {
    static propTypes = {
        categories: PropTypes.arrayOf(PropTypes.shape).isRequired,
        fetchCategories: PropTypes.func,
    };

    randomizeCategories() {
        const { categories } = this.props;

        const categoriesToExplore = [];

        if (categories.length > 0) {
            let randomNumber = 0;

            while (categoriesToExplore.length < 3) {
                randomNumber = random(0, categories.length - 1);

                if (!includes(categoriesToExplore, categories[randomNumber])) {
                    categoriesToExplore.push(categories[randomNumber]);
                }
            }
        }

        return categoriesToExplore;
    }

    render() {
        const randomizedCategories = this.randomizeCategories();

        return (
            <div className="categories-explore-section eds-l-pad-top-6 eds-l-pad-bot-8">
                <div className="categories-explore-section--title">
                    <h1 className="eds-text-hs eds-text--center">
                        {gettext('Categories to explore')}
                    </h1>
                </div>
                <div className="categories-explore-section--list eds-l-mar-top-6">
                    {randomizedCategories.map((category) => (
                        <CategoriesExploreItem
                            key={category.name}
                            category={category}
                            IllustrationType={getCategoryIllustration(
                                category.slug,
                            )}
                        />
                    ))}
                </div>
            </div>
        );
    }
}
