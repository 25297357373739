import { connect } from 'react-redux';
import Base from '../pages/Base/Base';
import { fetchAllApps } from '../actions/Apps';
import { fetchCategories } from '../actions/Categories';

import { setLoading } from '../actions';

const _mapStateToProps = ({ isLoading, apps, categoriesFetching }) => ({
    isLoading,
    apps,
    categoriesFetching,
});

const _mapPropsToDispatch = {
    setLoading,
    fetchAllApps,
    fetchCategories,
};

export default connect(_mapStateToProps, _mapPropsToDispatch)(Base);
