import isEmpty from 'lodash/isEmpty';
import {
    deepKeysToCamel,
    deepKeysToSnake,
} from '@eventbrite/transformation-utils';
import { initialize } from 'redux-form';
import {
    createReview,
    createReviewReply,
    deleteReviewReply as deleteReviewReplyApi,
    editReview as editReviewApi,
    editReviewReply as editReviewReplyApi,
    getRatings,
} from '../api';
import { WRITE_REVIEW_FORM_NAME } from '../constants/constants';

export const LOAD_RATINGS = 'LOAD_RATINGS';
export const CLEAR_RATINGS = 'CLEAR_RATINGS';
export const CLEAR_REVIEW_FROM_USER = 'CLEAR_REVIEW_FROM_USER';
export const SET_SELECTED_APP_RATING = 'SET_SELECTED_APP_RATING';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_RATING_FILTER = 'SET_RATING_FILTER';
export const SET_RATING_SORT_ORDER = 'SET_RATING_SORT_ORDER';
export const SET_REVIEW_FROM_USER = 'SET_REVIEW_FROM_USER';
export const REMOVE_REVIEW_REPLY = 'REMOVE_REVIEW_REPLY';
export const UPDATE_REVIEW_REPLY = 'UPDATE_REVIEW_REPLY';

const loadRatings = (ratings) => ({ type: LOAD_RATINGS, payload: ratings });

export const clearRatings = () => ({ type: CLEAR_RATINGS });

export const clearReviewFromUser = () => ({ type: CLEAR_REVIEW_FROM_USER });

export const removeReviewReply = ({ ratingId, ratingReplyId }) => ({
    type: REMOVE_REVIEW_REPLY,
    payload: { ratingId, ratingReplyId },
});

export const updateReviewReply = (reviewReply) => ({
    type: UPDATE_REVIEW_REPLY,
    payload: reviewReply,
});

export const setRatingForSelectedApp = (rating) => ({
    type: SET_SELECTED_APP_RATING,
    payload: rating,
});

const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    payload: isLoading,
});

export const setPageNumber = (pageNumber) => ({
    type: SET_PAGE_NUMBER,
    payload: pageNumber,
});

export const setRatingFilter = (ratingFilter) => ({
    type: SET_RATING_FILTER,
    payload: ratingFilter,
});

export const setRatingSortOrder = (sortOrder) => ({
    type: SET_RATING_SORT_ORDER,
    payload: sortOrder,
});

export const setReviewFromUser = (review) => ({
    type: SET_REVIEW_FROM_USER,
    payload: review,
});

export const createNewReview = (appId, data) => (dispatch) =>
    createReview(appId, data)
        .then((review) => dispatch(setReviewFromUser(deepKeysToCamel(review))))
        .catch((error) => Promise.reject(error));

export const editReview = (reviewId, data) => (dispatch, getState) => {
    const state = getState();
    const { reviewFromUser } = state;
    const appRatingFields = {
        rating: reviewFromUser.rating,
        reviewText: reviewFromUser.reviewText,
        reviewTitle: reviewFromUser.reviewTitle,
        ...data,
    };

    // The API will not handle null values for app_ratings, so we must remove them here
    const appRating = Object.keys(appRatingFields).reduce(
        (validFields, appRatingFormField) => {
            if (appRatingFields[appRatingFormField] !== null) {
                validFields[appRatingFormField] =
                    appRatingFields[appRatingFormField];
            }
            return validFields;
        },
        {},
    );

    const reviewData = {
        appRating,
    };

    return editReviewApi(reviewId, deepKeysToSnake(reviewData))
        .then((review) => {
            dispatch(setReviewFromUser(deepKeysToCamel(review)));
            return Promise.resolve(review);
        })
        .catch((error) => Promise.reject(error));
};

export const createNewReviewReply =
    (appId, ratingId, replyBody) => (dispatch) =>
        createReviewReply(appId, ratingId, replyBody)
            .catch((error) => Promise.reject(error))
            .then((reviewReply) => {
                reviewReply.ratingId = ratingId;
                dispatch(updateReviewReply(deepKeysToCamel(reviewReply)));
                return reviewReply;
            });

export const editReviewReply = (ratingId, replyId, replyBody) => (dispatch) =>
    editReviewReplyApi(replyId, replyBody)
        .catch((error) => Promise.reject(error))
        .then((reviewReply) => {
            reviewReply.ratingId = ratingId;
            dispatch(updateReviewReply(deepKeysToCamel(reviewReply)));
            return reviewReply;
        });

export const deleteReviewReply = (ratingId, ratingReplyId) => (dispatch) =>
    deleteReviewReplyApi(ratingReplyId)
        .catch((error) => Promise.reject(error))
        .then(() => dispatch(removeReviewReply({ ratingId, ratingReplyId })));

export const fetchAppRatingsByUser = (appId, userId) => (dispatch) =>
    getRatings(appId, deepKeysToSnake({ userId }))
        .then((response) => {
            if (response.ratings && response.ratings[0]) {
                dispatch(setRatingForSelectedApp(response.ratings[0].rating));
                dispatch(
                    setReviewFromUser(deepKeysToCamel(response.ratings[0])),
                );
            }
            return Promise.resolve(response);
        })
        .catch((e) => Promise.reject(e));

export const fetchRatings = (appId) => (dispatch, getState) => {
    const state = getState();
    const { pagination, ratingFilter, sortOrder } = state.appRatings;
    const params = {
        'expand.app_rating': 'replies',
        order_by: sortOrder,
        rating: !ratingFilter || ratingFilter === 'all' ? null : ratingFilter,
    };

    if (!isEmpty(pagination)) {
        if (!pagination.hasMoreItems) {
            return Promise.resolve();
        } else if (pagination.continuation) {
            params.continuation = pagination.continuation;
        }
    }

    dispatch(setIsLoading(true));
    // eslint-disable-next-line arrow-body-style
    return getRatings(appId, params).then(
        (response) => {
            dispatch(setIsLoading(false));
            return dispatch(loadRatings(deepKeysToCamel(response)));
        },
        (error) => {
            dispatch(setIsLoading(false));
            throw error;
        },
    );
};

export const initializeReviewForm = (reviewTitle, reviewText) => (dispatch) =>
    dispatch(
        initialize(
            WRITE_REVIEW_FORM_NAME,
            deepKeysToSnake({ reviewTitle, reviewText }),
        ),
    );
