import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { moment } from '@eventbrite/date';
import { Button } from '@eventbrite/eds-button';
import RatingStars from '../ratingStars/RatingStars';
import ReviewReplyCard from '../../containers/ConnectedReviewReplyCard';
import ReviewReplyForm from '../../containers/ConnectedReviewReplyForm';
import { REVIEW_REPLY_SHAPE } from '../../constants/constants';
import { gettext } from '@eventbrite/i18n';
import './ratingCard.scss';

export default class RatingCard extends PureComponent {
    static propTypes = {
        appName: PropTypes.string.isRequired,
        created: PropTypes.string,
        id: PropTypes.string.isRequired,
        rating: PropTypes.number,
        replies: PropTypes.arrayOf(REVIEW_REPLY_SHAPE),
        reviewText: PropTypes.string,
        reviewTitle: PropTypes.string,
        shouldShowReplyCTA: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            showReplyForm: false,
        };
    }

    _handleReplyClick(e) {
        e.preventDefault();
        this.setState({
            showReplyForm: true,
        });
    }

    onCancelReply() {
        this.setState({ showReplyForm: false });
    }

    onEditReply() {
        this.setState({ showReplyForm: true });
    }

    onSubmitReply() {
        this.setState({ showReplyForm: false });
    }

    render() {
        const {
            created,
            id,
            rating,
            replies,
            reviewText,
            reviewTitle,
            shouldShowReplyCTA,
        } = this.props;
        const { showReplyForm } = this.state;
        const createdDateAsMoment = moment(created);
        const formattedCreatedDate = createdDateAsMoment.format(
            'MMMM Do YYYY, h:mm:ssa',
        );
        const timeSinceCreated = createdDateAsMoment.fromNow();
        const firstReply = replies && replies[0];

        let replyCTA;

        if (shouldShowReplyCTA && !showReplyForm && !firstReply) {
            replyCTA = (
                <div className="eds-l-mar-top-2" data-spec="reply-review-cta">
                    <Button
                        style="anchor"
                        onClick={this._handleReplyClick.bind(this)}
                    >
                        <strong>{gettext('Reply to review')}</strong>
                    </Button>
                </div>
            );
        }

        let replyForm;

        if (showReplyForm) {
            replyForm = (
                <ReviewReplyForm
                    ratingId={id}
                    replyId={firstReply && firstReply.id}
                    replyText={firstReply && firstReply.text}
                    handleCancelReply={this.onCancelReply.bind(this)}
                    handleSubmitReply={this.onSubmitReply.bind(this)}
                />
            );
        }

        let replyCard;

        if (firstReply && !showReplyForm) {
            replyCard = (
                <div className="eds-l-mar-top-4">
                    <ReviewReplyCard
                        handleEditReply={this.onEditReply.bind(this)}
                        ratingId={id}
                        replyId={replies[0].id}
                        replyText={replies[0].text}
                        replyCreatedDate={replies[0].created}
                        replyEditedDate={replies[0].changed}
                    />
                </div>
            );
        }

        return (
            <div className="rating-card">
                <div className="eds-align--left">
                    {rating && <RatingStars rating={rating} size="xsmall" />}
                    <span
                        className="eds-l-mar-left-4 eds-text-color--ui-600"
                        title={formattedCreatedDate}
                    >
                        {timeSinceCreated}
                    </span>
                </div>
                <h3 className="eds-l-mar-vert-2 eds-text-bl eds-text-weight--heavy eds-text-color--ui-900">
                    {reviewTitle}
                </h3>
                <p className="eds-text-bm">{reviewText}</p>
                {replyCTA}
                {replyCard}
                {replyForm}
            </div>
        );
    }
}
