import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    REVIEW_SUBMIT_ERROR_PROP_TYPE,
    REVIEW_SUBMIT_ERRORS,
} from '../../constants/constants';
import { HeartIconFilled, WriteIllustration } from '../../svgs/index';
import { gettext } from '@eventbrite/i18n';
import { Illustration } from '@eventbrite/eds-illustration';

export default class AfterSubmitMessageModalContent extends PureComponent {
    static propTypes = {
        error: PropTypes.oneOf(REVIEW_SUBMIT_ERROR_PROP_TYPE),
    };

    _createErrorMessages = (error) => {
        let headline = '';
        let body = '';

        switch (error) {
            case REVIEW_SUBMIT_ERRORS.APP_ALREADY_RATED:
                headline = gettext('You already reviewed this app');
                body = gettext(
                    'Your review keeps others informed and helps our partners improve their app.',
                );

                break;
            case REVIEW_SUBMIT_ERRORS.APP_NOT_INSTALLED:
                // eslint-disable-next-line quotes
                headline = gettext("You can't leave a review just yet");
                body = gettext(
                    'To write a review, you must have this app installed.',
                );
                break;
            default:
                headline = gettext('Something weird happened');
                body = (
                    <span>
                        {gettext(
                            'We ran into an error when trying to save your review.',
                        )}{' '}
                        <br /> {gettext('Try again at a later time.')}
                    </span>
                );
        }

        return {
            headline,
            body,
        };
    };

    render() {
        const { error } = this.props;

        let headline = gettext('Thank you for your review');
        let body = '';
        let SubmitIllustration = HeartIconFilled;

        if (error) {
            const messages = this._createErrorMessages(error);

            headline = messages.headline;
            body = messages.body;
            SubmitIllustration = WriteIllustration;
        }

        return (
            <section>
                <div className="eds-align--center">
                    <Illustration
                        type={<SubmitIllustration />}
                        alt="review-illustration"
                        width="auto"
                        height="200px"
                    />
                </div>
                <h3 className="eds-text-hs eds-align--center eds-l-mar-top-8">
                    {headline}
                </h3>
                <p className="eds-text-bl eds-text-weight--regular eds-align--center eds-l-mar-top-4 eds-text--center">
                    {body}
                </p>
            </section>
        );
    }
}
