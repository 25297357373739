import { NotificationBar } from '@eventbrite/eds-notification';
import React from 'react';

export default ({ slug, location = {} }) => {
    const { query } = location;

    const shouldDisplayNameBadgesBanner =
        query?.redirect_from === 'attendees-badges' &&
        slug === 'conference-badges';

    return (
        <>
            {shouldDisplayNameBadgesBanner && (
                <NotificationBar
                    type="warning"
                    data-testid="name-badges-banner"
                >
                    Eventbrite no longer offers a feature to create name badges.
                    Consider using this third-party tool instead.
                </NotificationBar>
            )}
        </>
    );
};
