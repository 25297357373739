import React, { PureComponent } from 'react';
import { Modal } from '@eventbrite/eds-modal';
import PropTypes from 'prop-types';
import EditReviewModalContent from '../../containers/ConnectedEditReviewModalContent';
import ReviewModalContent from '../../containers/ConnectedReviewModalContent';
import isFunction from 'lodash/isFunction';

export default class ReviewModal extends PureComponent {
    static propTypes = {
        shown: PropTypes.bool,
        onCloseAction: PropTypes.func,
        createNewReview: PropTypes.func,
        currentAppRating: PropTypes.number,
        currentAppReviewId: PropTypes.string,
        editReview: PropTypes.func,
        onSaveSuccessAction: PropTypes.func,
        onSaveFailureAction: PropTypes.func,
    };

    constructor() {
        super();

        this.state = {
            isLoading: false,
        };
    }

    _handleOnClose = () => {
        const { onCloseAction, setRatingForSelectedApp } = this.props;

        if (onCloseAction && isFunction(onCloseAction)) {
            onCloseAction();
        }
        setRatingForSelectedApp(0);
    };

    _onSaveReviewFailure = (err) => {
        const { onSaveFailureAction } = this.props;
        let parsedError = null;

        if (err && err.extraData && err.extraData.parsedError) {
            parsedError = err.extraData.parsedError;
        }

        if (isFunction(onSaveFailureAction)) {
            onSaveFailureAction(parsedError);
        }
    };

    _handlePrimaryAction = (values) => {
        const {
            currentAppRating,
            currentAppReviewId,
            createNewReview,
            currentApp,
            editReview,
            userLocale,
            onSaveSuccessAction,
            setRatingForSelectedApp,
        } = this.props;

        if (!currentAppRating) {
            return false;
        }

        const data = {
            ...values,
            rating: currentAppRating,
            locale: userLocale,
        };

        this.setState({ isLoading: true });

        if (currentAppReviewId) {
            return editReview(currentAppReviewId, data)
                .then(() => {
                    if (isFunction(onSaveSuccessAction)) {
                        onSaveSuccessAction();
                    }
                })
                .catch(this._onSaveReviewFailure)
                .finally(() => {
                    this.setState({ isLoading: false });
                });
        }

        return createNewReview(currentApp.id, data)
            .then(() => {
                if (isFunction(onSaveSuccessAction)) {
                    onSaveSuccessAction();
                }
            })
            .catch(this._onSaveReviewFailure)
            .finally(() => {
                this.setState({ isLoading: false });
                setRatingForSelectedApp(0);
            });
    };

    render() {
        const { currentAppReviewId, shown } = this.props;
        const { isLoading } = this.state;
        let modalContent = (
            <ReviewModalContent
                onSubmit={this._handlePrimaryAction}
                isLoading={isLoading}
            />
        );

        if (currentAppReviewId) {
            modalContent = (
                <EditReviewModalContent
                    onSubmit={this._handlePrimaryAction}
                    isLoading={isLoading}
                />
            );
        }

        return (
            <Modal
                isShown={shown}
                isClosable={true}
                noMinHeight={true}
                onClose={this._handleOnClose}
                noPadding={true}
            >
                {modalContent}
            </Modal>
        );
    }
}
