import {
    LOAD_APPS,
    LOAD_FEATURED_APPS,
    LOAD_POPULAR_APPS,
    LOAD_APPS_BY_CATEGORY,
    LOAD_CURRENT_APP,
    LOAD_MARKETING_APPS,
} from '../actions/Apps';

export const appsListMap = (state = {}, { type, payload }) => {
    const nextState = {};
    let keyword = '';

    Object.assign(nextState, state);

    switch (type) {
        case LOAD_APPS:
            keyword = 'apps';
            break;
        case LOAD_POPULAR_APPS:
            keyword = 'popularApps';
            break;
        case LOAD_FEATURED_APPS:
            keyword = 'featuredApps';
            break;
        case LOAD_MARKETING_APPS:
            keyword = 'marketingApps';
            break;
        default:
            return state;
    }

    nextState[keyword] = payload;

    return nextState;
};

export const appsByCategory = (state = {}, { type, payload }) => {
    const nextState = {};

    Object.assign(nextState, state);

    if (type === LOAD_APPS_BY_CATEGORY && payload.slug && payload.apps) {
        nextState[payload.slug] = payload.apps;
    }

    return nextState;
};

export const currentApp = (state = {}, { type, payload }) => {
    let newState = state;

    if (type === LOAD_CURRENT_APP) {
        newState = payload;
    }

    return newState;
};
