import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';
import { InputField } from '@eventbrite/eds-input-field';
import { ValidationFormField } from '@eventbrite/eds-validation-form-field';
import { Button } from '@eventbrite/eds-button';
import { formValidate } from '@eventbrite/eds-utils';
import { makeValidator } from '@eventbrite/validators';
import { WRITE_REVIEW_FORM_NAME } from '../../constants/constants';
import { gettext } from '@eventbrite/i18n';
import './reviewForm.scss';

class ReviewForm extends PureComponent {
    static propTypes = {
        currentAppRating: PropTypes.number,
        currentAppReviewText: PropTypes.string,
        currentAppReviewTitle: PropTypes.string,
        handleSubmit: PropTypes.func,
        initializeReviewForm: PropTypes.func.isRequired,
        submitButtonText: translationPropType.isRequired,
    };

    componentDidMount() {
        const {
            currentAppReviewText,
            currentAppReviewTitle,
            initializeReviewForm,
        } = this.props;

        initializeReviewForm(currentAppReviewTitle, currentAppReviewText);
    }

    render() {
        const { currentAppRating, handleSubmit, invalid, submitButtonText } =
            this.props;

        return (
            <form onSubmit={handleSubmit}>
                <ValidationFormField name="review_title" label="">
                    <InputField
                        id="review_title"
                        type="text"
                        name="review_title"
                        label={gettext('Review Title')}
                        placeholder={gettext(
                            "Review title. What's most important to know?",
                        )}
                        data-spec="review_title"
                        style="basic"
                    />
                </ValidationFormField>
                <ValidationFormField name="review_text" label="">
                    <InputField
                        id="review_text"
                        name="review_text"
                        label={gettext('Review')}
                        placeholder={gettext(
                            'What did you like or dislike about this app?',
                        )}
                        data-spec="review_text"
                        style="basic"
                        isMultiline={true}
                        rows={8}
                    />
                </ValidationFormField>

                <div className="eds-align--space-between">
                    <p
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: gettext(
                                'By reviewing this app, you agree to the <a href="https://www.eventbrite.com/l/eventbrite-app-marketplace-guidelines/" target=_"blank" rel="noopener noreferrer">Eventbrite App Marketplace Review Guidelines</a>.',
                            ),
                        }}
                        className="guidelines-message"
                    />
                    <Button
                        type="submit"
                        style="fill"
                        disabled={!currentAppRating || invalid}
                    >
                        {submitButtonText}
                    </Button>
                </div>
            </form>
        );
    }
}

export default formValidate({
    form: WRITE_REVIEW_FORM_NAME,
    validate: makeValidator({
        review_title: [
            {
                validator: (value) => (value && value.length <= 100) || !value,
                errorMessage: gettext('Max 100 character limit!'),
            },
            {
                validator: (value) => value,
                errorMessage: gettext('Required field'),
            },
        ],
        review_text: {
            validator: (value) => (value && value.length <= 1024) || !value,
            errorMessage: gettext('Max 1024 character limit!'),
        },
    }),
    touchOnChange: true,
})(ReviewForm);
