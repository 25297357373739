import * as React from 'react';

const HeartIconSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={264} height={214} {...props}>
        <defs>
            <path
                d="M151.525 0C117.562 0 104.5 26.143 104.5 26.143S86.213 0 57.475 0C26.125 0 0 21.238 0 52.312c0 21.108 5.335 31.455 20.9 47.03L104.62 183l83.48-83.657C203.665 83.767 209 73.2 209 52.286 209 21.238 182.875 0 151.525 0M198 51.893c0 17.354-3.666 25.524-17.834 39.73L104.11 168 27.829 91.618C13.499 77.25 10 69.457 10 51.92 10 28.025 30.205 10 57 10c31.171 0 47 36.657 47 36.657S119.829 10 151 10c26.111 0 47 18.009 47 41.893"
                id="heart-icon_svg__a"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                d="M59.168 72.612C72.22 25.81 118.034-5.266 167.225 1.647c53.597 7.533 90.909 57.31 83.338 111.181-7.571 53.87-57.158 91.435-110.755 83.902a98.056 98.056 0 01-17.736-4.195c-15.23 15.325-37.24 23.534-60.282 20.296-38.83-5.457-65.885-41.36-60.428-80.19 4.383-31.185 28.403-54.776 57.806-60.029z"
                fill="#E1F4F7"
            />
            <g transform="translate(23 18)">
                <path
                    d="M198 51.893c0 17.354-3.666 25.524-17.834 39.73L104.11 168 27.829 91.618C13.499 77.25 10 69.457 10 51.92 10 28.025 30.205 10 57 10c31.171 0 47 36.657 47 36.657S119.829 10 151 10c26.111 0 47 18.009 47 41.893"
                    fill="#FFF"
                />
                <use fill="#F05537" xlinkHref="#heart-icon_svg__a" />
            </g>
            <g stroke="#F05537" strokeLinecap="square" strokeWidth={2}>
                <path d="M247.045 44.995l14.938-1.128M245.39 38.501l13.955-5.446M241.134 33.322l8.949-12.014" />
            </g>
        </g>
    </svg>
);

HeartIconSvg.displayName = 'HeartIconSvg';
export default HeartIconSvg;
