import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { moment } from '@eventbrite/date';
import { AlertChunky } from '@eventbrite/eds-iconography';
import { MoreActions } from '@eventbrite/eds-more-actions';
import { NotificationBar, TYPE_ERROR } from '@eventbrite/eds-notification';
import { ProgressIndicator } from '@eventbrite/eds-progress-indicator';
import { gettext } from '@eventbrite/i18n';
import './reviewReplyCard.scss';

export default class ReviewReplyCard extends PureComponent {
    static propTypes = {
        deleteReviewReply: PropTypes.func.isRequired,
        handleEditReply: PropTypes.func.isRequired,
        logoUrl: PropTypes.string,
        name: PropTypes.string.isRequired,
        ratingId: PropTypes.string.isRequired,
        replyId: PropTypes.string.isRequired,
        replyText: PropTypes.string.isRequired,
        replyCreatedDate: PropTypes.string.isRequired,
        replyEditedDate: PropTypes.string,
        shouldShowMoreActionsMenu: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            hasFailed: false,
        };
    }

    _handleSelectAction(value) {
        const { deleteReviewReply, handleEditReply, ratingId, replyId } =
            this.props;

        if (value === 'delete' && deleteReviewReply) {
            this.setState({ isLoading: true });
            deleteReviewReply(ratingId, replyId).catch(() => {
                this.setState({
                    isLoading: false,
                    hasFailed: true,
                });
            });
        } else if (value === 'edit' && handleEditReply) {
            handleEditReply();
        }
    }

    render() {
        const {
            logoUrl,
            name,
            replyText,
            replyCreatedDate,
            replyEditedDate,
            shouldShowMoreActionsMenu,
        } = this.props;
        const { isLoading, hasFailed } = this.state;
        const createdDateAsMoment = moment(replyCreatedDate);
        const formattedCreatedDate = createdDateAsMoment.format(
            'MMMM Do YYYY, h:mm:ssa',
        );
        const timeSinceCreated = createdDateAsMoment.fromNow();
        const menuItems = [
            {
                content: gettext('Edit'),
                value: 'edit',
            },
            {
                content: gettext('Remove'),
                value: 'delete',
            },
        ];

        if (isLoading) {
            return (
                <div className="eds-align--center eds-l-mar-vert-16">
                    <ProgressIndicator style="gradient" />
                </div>
            );
        }

        let errorNotification;

        if (hasFailed) {
            errorNotification = (
                <div className="eds-align--center eds-l-mar-vert-4">
                    <NotificationBar
                        data-spec="create-review-reply-error-notification"
                        type={TYPE_ERROR}
                        iconType={<AlertChunky />}
                    >
                        {gettext('Failed to delete reply. Please try again.')}
                    </NotificationBar>
                </div>
            );
        }

        let moreActionsMenu;

        if (shouldShowMoreActionsMenu) {
            moreActionsMenu = (
                <div className="more-actions-wrapper">
                    <MoreActions
                        items={menuItems}
                        dropdownAlign="right"
                        onAction={this._handleSelectAction.bind(this)}
                    />
                </div>
            );
        }

        let editedDateDisplay;

        if (replyEditedDate && replyEditedDate !== replyCreatedDate) {
            const editedDateAsMoment = moment(replyEditedDate);
            const formattedEditedDate = editedDateAsMoment.format(
                'MMMM Do YYYY, h:mm:ssa',
            );
            const timeSinceEdited = moment(replyEditedDate).fromNow();

            editedDateDisplay = (
                <span title={formattedEditedDate}>
                    {' '}
                    | {gettext('Edited')} {timeSinceEdited}
                </span>
            );
        }

        return (
            <div className="review-reply-card eds-bg-color--ui-100 eds-l-pad-all-4">
                {errorNotification}
                <div className="title-group">
                    <div className="app-logo">
                        <img alt={`${name} logo`} src={logoUrl} />
                    </div>
                    <h4 className="eds-l-mar-right-2">{name}</h4>
                    <div className="eds-text-bs eds-text-color--ui-600">
                        <span title={formattedCreatedDate}>
                            {timeSinceCreated}
                        </span>
                        {editedDateDisplay}
                    </div>
                    {moreActionsMenu}
                </div>
                <p className="reply-text eds-text-bm">{replyText}</p>
            </div>
        );
    }
}
