import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    SiteStructure,
    ENV_SHAPE_PROP_TYPE,
    REQUEST_SHAPE_PROP_TYPE,
    USER_SHAPE_PROP_TYPE,
    FOOTER_LINKS_SHAPE_PROP_TYPE,
} from '@eventbrite/site-structure';
import CustomGlobalHeader from '../../components/CustomGlobalHeader/CustomGlobalHeader';

import './Base.scss';

export default class Page extends PureComponent {
    static propTypes = {
        env: ENV_SHAPE_PROP_TYPE.isRequired,
        request: REQUEST_SHAPE_PROP_TYPE.isRequired,
        user: USER_SHAPE_PROP_TYPE.isRequired,
        footerLinks: FOOTER_LINKS_SHAPE_PROP_TYPE.isRequired,
        children: PropTypes.node,

        isLoading: PropTypes.bool,
    };

    componentDidMount() {
        const { fetchAllApps } = this.props;

        fetchAllApps();
    }

    render() {
        const {
            env,
            request,
            user,
            footerLinks,
            children,

            isLoading,
        } = this.props;

        const header = (
            <CustomGlobalHeader env={env} request={request} user={user} />
        );

        return (
            <SiteStructure
                env={env}
                globalHeader={header}
                request={request}
                user={user}
                footerLinks={footerLinks}
                isLoading={isLoading}
                fullScreenMain={true}
            >
                {React.cloneElement(children, {
                    user,
                })}
            </SiteStructure>
        );
    }
}
