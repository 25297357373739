import { ProgressIndicator } from '@eventbrite/eds-progress-indicator';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import React, { PureComponent } from 'react';
import {
    AppCardAppNameTextSizeProp,
    AppCardLinkProp,
    AppListColumnNumbersProp,
    AppProp,
    APP_CARD_APP_NAME_TEXT_SIZE_MD,
} from '../../constants';
import AppCard from '../AppCard/AppCard';
import AppList from '../AppList/AppList';
import ArrowLink from '../ArrowLink/ArrowLink';
import RefreshContent from '../RefreshContent/RefreshContent';
import './AppDiscoverySection.scss';

export default class AppDiscoverySection extends PureComponent<{
    appNameTextSize: AppCardAppNameTextSizeProp;
    apps: Array<AppProp>;
    columnNumber: AppListColumnNumbersProp;
    hasFailed?: boolean;
    isLoading?: boolean;
    linkComponentType?: AppCardLinkProp;
    linkLabel?: string | GenericLazyString;
    linkUrl?: string;
    maxApps?: number;
    refreshContentHandler: () => void;
    sectionTitle?: string | GenericLazyString;
    shouldTrackAppRecLoaded?: boolean;
}> {
    static defaultProps = {
        linkLabel: gettext('See all'),
        maxApps: 6,
    };

    _handleRefreshContent = () => {
        const { refreshContentHandler } = this.props;

        if (refreshContentHandler) {
            refreshContentHandler();
        }
    };

    _getHeader = (sectionTitle?: string | GenericLazyString) => {
        const { appNameTextSize, linkComponentType, linkLabel, linkUrl } =
            this.props;

        if (!linkUrl && !sectionTitle) {
            return null;
        }

        const sectionTitleClass = classNames({
            'eds-text-bm eds-text-weight--heavy':
                appNameTextSize === APP_CARD_APP_NAME_TEXT_SIZE_MD,
            'eds-text-hs': appNameTextSize !== APP_CARD_APP_NAME_TEXT_SIZE_MD,
        });

        return (
            <div className="app-discovery-section--title">
                <h1 className={sectionTitleClass}>{sectionTitle}</h1>
                <div className="app-discovery-section--see-all-section">
                    <ArrowLink
                        url={linkUrl}
                        text={linkLabel}
                        linkComponentType={linkComponentType}
                    />
                </div>
            </div>
        );
    };

    render() {
        const {
            appNameTextSize,
            apps,
            columnNumber,
            hasFailed,
            isLoading,
            linkComponentType,
            maxApps,
            sectionTitle,
            shouldTrackAppRecLoaded,
        } = this.props;

        if (!apps.length && !isLoading && !hasFailed) {
            return null;
        }

        const appsToDisplay = apps.slice(0, maxApps);

        let appListContent = (
            <AppList columnNumber={columnNumber}>
                {appsToDisplay.map((app, index) => (
                    <div className="app-list--item" key={app.id}>
                        <AppCard
                            app={app}
                            appNameTextSize={appNameTextSize}
                            linkComponentType={linkComponentType}
                            position={index + 1} // position should not be zero-indexed
                            sectionTitle={sectionTitle}
                            shouldTrackAppRecLoaded={shouldTrackAppRecLoaded}
                        />
                    </div>
                ))}
            </AppList>
        );

        if (hasFailed && !isLoading) {
            appListContent = (
                <RefreshContent onClickHandler={this._handleRefreshContent} />
            );
        } else if (isLoading) {
            appListContent = (
                <div className="eds-align--center">
                    <ProgressIndicator
                        style="gradient"
                        size="large-chunky"
                        shape="circular"
                    />
                </div>
            );
        }

        return (
            <div>
                {this._getHeader(sectionTitle)}
                <div className="app-discovery-section--list eds-l-pad-bot-2 eds-l-mar-top-4">
                    {appListContent}
                </div>
            </div>
        );
    }
}
