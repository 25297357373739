export const flattenCategoriesForApps = (apps) =>
    apps.map((app) => ({
        ...app,
        flatCategories: (() =>
            app.categories.map((category) => category.name))(),
    }));

const arrayOfNumbersAsWord = ['one', 'two', 'three', 'four', 'five'];

export const starNumberAsWordToDigit = (starNumberAsWord) =>
    arrayOfNumbersAsWord.indexOf(starNumberAsWord) + 1;
export const starNumberAsDigitToWord = (starNumberAsDigit) =>
    arrayOfNumbersAsWord[starNumberAsDigit - 1];
