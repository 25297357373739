import { Avatar, AvatarSize } from '@eventbrite/eds-avatar';
import { GenericLazyString } from '@eventbrite/i18n';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import classNames from 'classnames';
import React, { PureComponent } from 'react';
import {
    AppCardAppNameTextSizeProp,
    AppCardLinkProp,
    AppProp,
    APP_CARD_APP_NAME_TEXT_SIZE_LG,
    APP_CARD_APP_NAME_TEXT_SIZE_MD,
    BASE_APPS_URL,
    ROUTER_LINK,
} from '../../constants';
import {
    APP_CLICKED,
    APP_MARKETPLACE_EVENT_CATEGORY,
    APP_RECOMMENDATIONS_LOADED,
} from '../../constants/analytics';
import InstalledBadge from '../InstalledBadge/InstalledBadge';
import Link from '../Link';
import './AppCard.scss';

export default class AppCard extends PureComponent<{
    app: AppProp;
    appNameTextSize: AppCardAppNameTextSizeProp;
    linkComponentType: AppCardLinkProp;
    position?: number;
    sectionTitle?: string | GenericLazyString;
    shouldTrackAppRecLoaded?: boolean;
}> {
    static defaultProps = {
        appNameTextSize: APP_CARD_APP_NAME_TEXT_SIZE_LG,
        linkComponentType: ROUTER_LINK,
        shouldTrackAppRecLoaded: false,
    };

    /**
     * Cut off the short description at 34 characters
     * @param shortDesc short description of the app
     */
    _transformShortDesc(shortDesc: string) {
        const shortDescLength = shortDesc.length;
        const cutOffSize = 40;

        if (shortDescLength > cutOffSize) {
            return `${shortDesc.substr(0, cutOffSize)}...`;
        }

        return shortDesc;
    }

    _handleAppClick = () => {
        const { app, position, sectionTitle } = this.props;

        trackAnalyticsEvent({
            action: APP_CLICKED,
            category: APP_MARKETPLACE_EVENT_CATEGORY,
            label: app.name,
            dimensions: {
                apiKeyId: app.id,
                locationOnPage: sectionTitle,
            },
            metrics: {
                searchRank: position,
            },
        });
    };

    _trackAppRecLoaded = () => {
        const { app, position } = this.props;

        trackAnalyticsEvent({
            action: APP_RECOMMENDATIONS_LOADED,
            category: APP_MARKETPLACE_EVENT_CATEGORY,
            label: app.name,
            dimensions: {
                apiKeyId: app.id,
            },
            metrics: {
                searchRank: position,
            },
        });
    };

    componentDidMount() {
        const { shouldTrackAppRecLoaded } = this.props;

        if (shouldTrackAppRecLoaded === true) {
            this._trackAppRecLoaded();
        }
    }

    render() {
        const { app, linkComponentType, appNameTextSize } = this.props;
        const showAppDetails = app.details && app.details.is_installed;
        let shortDesc;

        // Some apps do not have short descriptions yet
        // Remove this check once data is updated
        if (app.localization.short_description) {
            shortDesc = this._transformShortDesc(
                app.localization.short_description,
            );
        }

        const headerTextSize = classNames({
            'eds-text-bm': appNameTextSize === APP_CARD_APP_NAME_TEXT_SIZE_MD,
            'eds-text-bl': appNameTextSize === APP_CARD_APP_NAME_TEXT_SIZE_LG,
        });

        return (
            <div className="app-card">
                <Link
                    to={`${BASE_APPS_URL}${app.slug}/`}
                    type={linkComponentType}
                    onClick={this._handleAppClick}
                >
                    <div className="app-card__link">
                        <div className="app-card__img">
                            {showAppDetails ? (
                                <div className="installed-badge--container">
                                    <InstalledBadge />
                                </div>
                            ) : null}
                            <Avatar
                                text={app.name}
                                imageUrl={app.logo_url}
                                size={AvatarSize.small}
                                textColor="grey-700"
                            />
                        </div>
                        <div className="app-card__text">
                            <h4 className={headerTextSize}>{app.name}</h4>
                            <p className="eds-text-bm eds-text--truncate">
                                {shortDesc}
                            </p>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}
