/* eslint-disable */

const serverData = {
    env: {
        signupUrl:
            'https://www.evbqa.com/signup/?referrer=%2F&user_type=prebuyer&user_type_sig=ALxiGcBmMyKx92QOUXwKRdmx63vyX8aA_Q',
        isEmbeddedView: false,
        serverUrl: 'https://www.evbqa.com',
        showCookieHeader: false,
        organizeUrls: {
            suggested: [
                {
                    url: '/organizer/overview/',
                    gaLabel: 'Organize',
                    label: 'Overview',
                },
                {
                    url: '/organizer/pricing/',
                    gaLabel: 'Organize',
                    label: 'Pricing',
                },
                {
                    url: 'https://www.eventbrite.com/blog/',
                    gaLabel: 'Organize',
                    label: 'Blog',
                },
                {
                    url: '/blog/academy/',
                    gaLabel: 'Organize',
                    label: 'Resources',
                },
            ],
            home: {
                url: '/how-it-works/',
                gaLabel: 'HowItWorks',
                label: 'How it works',
            },
        },
        ebDomain: 'evbqa.com',
        wasRenderedServerSide: true,
        helpUrls: {
            impressum:
                'https://www.evbqa.com/support/articleredirect?anum=10983',
            home: 'https://www.evbqa.com/support',
            suggested: [
                {
                    url: '/how-it-works/',
                    gaLabel: 'HowItWorks',
                    label: 'How it works',
                },
                {
                    url: '/fees/',
                    gaLabel: 'WhatItCosts',
                    label: 'What does it cost to create an event?',
                },
                {
                    url: 'https://www.evbqa.com/support/articleredirect?anum=3776',
                    gaLabel: 'WhereToFind',
                    label: 'Where are my tickets?',
                },
                {
                    url: 'https://www.evbqa.com/support/articleredirect?anum=3275',
                    gaLabel: 'HowToContact',
                    label: 'How to contact the event organizer',
                },
            ],
        },
        locales: [
            {
                locale: 'es_AR',
                localized_language_name: 'Español (AR)',
                locale_name: 'Argentina',
            },
            {
                locale: 'en_AU',
                localized_language_name: 'English (AU)',
                locale_name: 'Australia',
            },
            {
                locale: 'nl_BE',
                localized_language_name: 'Belge',
                locale_name: 'België',
            },
            {
                locale: 'fr_BE',
                localized_language_name: 'Français (BE)',
                locale_name: 'Belgique',
            },
            {
                locale: 'pt_BR',
                localized_language_name: 'Português (BR)',
                locale_name: 'Brasil',
            },
            {
                locale: 'en_CA',
                localized_language_name: 'English (CA)',
                locale_name: 'Canada (EN)',
            },
            {
                locale: 'fr_CA',
                localized_language_name: 'Français (CA)',
                locale_name: 'Canada (FR)',
            },
            {
                locale: 'es_CL',
                localized_language_name: 'Español',
                locale_name: 'Chile',
            },
            {
                locale: 'es_CO',
                localized_language_name: 'Español (ES)',
                locale_name: 'Colombia',
            },
            {
                locale: 'de_DE',
                localized_language_name: 'Deutsch',
                locale_name: 'Deutschland',
            },
            {
                locale: 'es_ES',
                localized_language_name: 'Español (ES)',
                locale_name: 'España',
            },
            {
                locale: 'en_FI',
                localized_language_name: 'English (FI)',
                locale_name: 'Finland',
            },
            {
                locale: 'fr_FR',
                localized_language_name: 'Français',
                locale_name: 'France',
            },
            {
                locale: 'en_HK',
                localized_language_name: 'English (HK)',
                locale_name: 'Hong Kong',
            },
            {
                locale: 'en_IE',
                localized_language_name: 'English (UK)',
                locale_name: 'Ireland',
            },
            {
                locale: 'it_IT',
                localized_language_name: 'Italiano',
                locale_name: 'Italia',
            },
            {
                locale: 'es_MX',
                localized_language_name: 'Español (MX)',
                locale_name: 'México',
            },
            {
                locale: 'nl_NL',
                localized_language_name: 'Nederlands',
                locale_name: 'Nederland',
            },
            {
                locale: 'en_NZ',
                localized_language_name: 'English (Australia/New Zealand)',
                locale_name: 'New Zealand',
            },
            {
                locale: 'de_AT',
                localized_language_name: 'Deutsch',
                locale_name: 'Österreich',
            },
            {
                locale: 'es_PE',
                localized_language_name: 'Español (ES)',
                locale_name: 'Perú',
            },
            {
                locale: 'pt_PT',
                localized_language_name: 'Português',
                locale_name: 'Portugal',
            },
            {
                locale: 'en_SG',
                localized_language_name: 'English (SG)',
                locale_name: 'Singapore',
            },
            {
                locale: 'en_SE',
                localized_language_name: 'English (SE)',
                locale_name: 'Sweden',
            },
            {
                locale: 'de_CH',
                localized_language_name: 'Deutsch (CH)',
                locale_name: 'Switzerland (DE)',
            },
            {
                locale: 'fr_CH',
                localized_language_name: 'Français (CH)',
                locale_name: 'Switzerland (FR)',
            },
            {
                locale: 'en_GB',
                localized_language_name: 'English (UK)',
                locale_name: 'United Kingdom',
            },
            {
                locale: 'en_US',
                localized_language_name: 'English (US)',
                locale_name: 'United States',
            },
        ],
        searchQuery: null,
        searchLocation: "{'address': u'Ashburn, VA'}",
        adminServerUrl: 'https://admin.evbqa.com',
        currencyFormat: '¤#,##0.00',
        logoutUrl: 'https://www.evbqa.com/logout/',
        isNewAccountSettingsActive: true,
        enableBrandUpdates2018: true,
        loginUrl: 'https://www.evbqa.com/login/?referrer=%2F',
        localeInfo: {
            flag_logo_url:
                'https://cdn.evbstatic.com/s3-s3/marketing/migration/US-flag.png',
            gold_support_phone_number: '1-877-297-6805',
            greetings: ['Hello', 'Hi', 'Greetings', 'Dear'],
            country_code: 'US',
            language_code: 'en',
            silver_support_phone_number: '1-877-297-6805',
            has_phone_support: true,
            show_link_to_music_microsite: true,
            flag_logo_medium_url:
                'https://cdn.evbstatic.com/s3-s3/marketing/migration/US-flag-32x32.png',
            paypal_locale: 'en_US',
            group: 'AMERICA',
            sales_email: 'sales@eventbrite.com',
            prefixes: [
                'Mr.',
                'Mrs.',
                'Ms.',
                'Miss',
                'Mx.',
                'Dr.',
                'Prof.',
                'Rev.',
            ],
            npo_service_fee_percent: '2.0%',
            uses_metric_system: false,
            has_localized_support_team: false,
            is_eventioz_locale: false,
            latitude: 37.775262,
            app_store_download_icon_url:
                'https://cdn.evbstatic.com/s3-s3/marketing/app_cta/en_us/Download_on_the_App_Store_Badge_US-UK_135x40.svg',
            android_entrymanager_url:
                'https://play.google.com/store/apps/details?id=com.eventbrite.organizer&hl=en_US',
            press_email: 'press@eventbrite.com',
            hiw_sales_phone_number: '1-855-292-7905',
            base_ios_app_url:
                'http://itunes.apple.com/%s/app/eventbrite/id487922291',
            is_locale_enabled: true,
            from_mail_charity: 'charity@eventbrite.com',
            get_default_location_dict: {
                city: 'New York',
                region_code: 'NY',
                postal_code: '11101',
                country_code: 'US',
                latitude: 40.73061,
                country: 'United States',
                region: 'New York',
                longitude: -73.935242,
            },
            get_default_location_name: 'New York, NY',
            terms_of_service_url: 'https://www.evbqa.com/l/LegalTerms',
            server: 'http://www.evbqa.com',
            address_4: 'United States',
            address_1: 'Eventbrite, Inc.',
            address_2: '155 5th St, 7th Floor',
            address_3: 'San Francisco, CA 94103',
            attendee_support_phone_number: '1-888-810-2063',
            default_location_for_tld: {
                city: 'New York',
                region_code: 'NY',
                postal_code: '11101',
                country_code: 'US',
                latitude: 40.73061,
                country: 'United States',
                region: 'New York',
                longitude: -73.935242,
            },
            twitter_handle: 'Eventbrite',
            play_store_badge_url:
                'https://cdn.evbstatic.com/s3-s3/marketing/app_cta/en_us/en_generic_rgb_wo_45.png',
            locale: 'en_US',
            base_android_app_url:
                'https://play.google.com/store/apps/details?id=com.eventbrite.attendee&hl=%s',
            google_maps_language: 'en',
            base_ios_app_badge_url:
                '%(server)s/eventbriteapp/?appstore&%(affiliate_params)s',
            paypal_support_number: '888-221-1161',
            localized_language_name: 'English (US)',
            facebook_page: 'www.facebook.com/Eventbrite',
            on_demand_payout_allowed: false,
            nonprofit_501c3_fax_number: '1-888-855-2521',
            app_store_available_icon_url:
                'https://cdn.evbstatic.com/s3-s3/marketing/app_cta/en_us/Available_on_the_App_Store_Badge_US-UK_135x40.svg',
            base_medium_logo_url:
                'https://cdn.evbstatic.com/s3-s3/marketing/migration/%s-flag-32x32.png',
            support_number_winter_hours: null,
            is_deferred_payment_options_allowed: false,
            google_plus_page: 'plus.google.com/u/0/+eventbrite/',
            music_microsite_url: 'https://www.evbqa.com/l/music/',
            first_week_day: 6,
            support_team_landing_page: '',
            sales_hours: 'Mon-Fri 6am-6pm PT',
            from_mail_order: 'orders@eventbrite.com',
            support_phone: '1-800-350-8850',
            sales_contact_landing_page: '/l/contact-sales/',
            support_number_summer_hours: null,
            adobe_reader_url: 'http://get.adobe.com/reader/',
            from_mail_installments: 'installments@eventbrite.com',
            available_for_event_listing_override: null,
            contact_sales_phone_number: '1-888-201-2414',
            tld: '.com',
            contact_us_path: 'support/contact-us',
            show_cookie_policy_bar: false,
            base_app_store_available_icon_url:
                'https://cdn.evbstatic.com/s3-s3/marketing/app_cta/%s/Available_on_the_App_Store_Badge_%s_135x40.svg',
            default_adobe_download_url: 'http://get.adobe.com/reader/',
            recaptcha_language: 'en',
            base_ios_entrymanager_url:
                'http://itunes.apple.com/%s/app/entry-manager/id368260521',
            default_language: 'en-us',
            domain: 'evbqa.com',
            base_android_entrymanager_url:
                'https://play.google.com/store/apps/details?id=com.eventbrite.organizer&hl=%s',
            outside_us_organizer_phone_number: '1-804-201-9585',
            default_currency: 'USD',
            base_play_store_badge_url:
                'https://cdn.evbstatic.com/s3-s3/marketing/app_cta/%s/%s_generic_rgb_wo_45.png',
            old_event_locale: null,
            press_blog_url: '/blog/press/',
            sales_phone_and_hours: '1-888-541-9753 Mon-Fri 6am-6pm PT',
            from_mail_ebhelp: 'ebhelp@eventbrite.com',
            base_adobe_download_url:
                'http://get.adobe.com/%(language_code)s/reader/',
            organizations_support_phone_number: '1-855-872-1525',
            support_number_hours: null,
            contact_us_sales_phone_number: '1-800-993-7308',
            default_timezone: 'America/Los_Angeles',
            show_support_special_hours_on_contact_us: false,
            live_ops_support_phone_number: null,
            contact_us_url: 'https://www.evbqa.com/support/contact-us',
            instagram_page: 'www.instagram.com/eventbrite/',
            default_language_name: 'English (US)',
            longitude: -122.400197,
            twitter_page: 'www.twitter.com/Eventbrite',
            localized_country_name: 'United States',
            feature_flag: null,
            android_attendee_app_url:
                'https://play.google.com/store/apps/details?id=com.eventbrite.attendee&hl=en_US',
            is_latam: false,
            ios_entrymanager_url:
                'http://itunes.apple.com/us/app/entry-manager/id368260521',
            base_app_store_download_icon_url:
                'https://cdn.evbstatic.com/s3-s3/marketing/app_cta/%s/Download_on_the_App_Store_Badge_%s_135x40.svg',
            npo_service_fee_charge: '$0.99 per ticket',
            facebook_locale: 'en_US',
            paypal_locale_lc: 'us',
            contact_us_show_chat_button: false,
            support_phone_number: '1-800-350-8850',
            npo_email: 'nonprofit@eventbrite.com',
            first_week_day_from_sunday: 0,
            hreflang: 'en-us',
            tos_path: '/l/LegalTerms',
            default_paper_size: 'letter',
            base_android_app_badge_url:
                '%(server)s/eventbriteapp/?playstore&%(affiliate_params)s',
            locale_name: 'United States',
            default_professional_package: true,
            sales_phone_number: '1-888-541-9753',
            google_analytics_url: 'http://www.google.com/analytics',
            base_flag_logo_url:
                'https://cdn.evbstatic.com/s3-s3/marketing/migration/%s-flag.png',
            contact_sales_form_phone_number: '1-888-203-4469',
            alt_phone_number: '1-888-248-4220',
            sales_contact_link_text: 'Contact Sales',
            ssl_server: 'https://www.evbqa.com',
            support_team_link_text: '',
            ios_attendee_app_url:
                'http://itunes.apple.com/us/app/eventbrite/id487922291',
            music_url: '/l/music/',
            salesforce_chat_config: {
                locale: 'USA',
                language: 'English',
                live_agent: '57370000000PCN2',
                script_source:
                    'https://c.la2c1cs.salesforceliveagent.com/content/g/js/34.0/deployment.js',
                init_args: [
                    'https://d.la2c1cs.salesforceliveagent.com/chat',
                    '572700000008Pmf',
                    '00Df0000003cLmh',
                ],
            },
            blog_url: 'www.eventbrite.com/blog/',
            outside_us_phone_number: '1-415-694-7900',
            linked_in_page: 'www.linkedin.com/company/eventbrite',
            live_ops_outside_us_phone_number: '1-415-813-3236',
        },
        isMobile: false,
    },
    footerLinks: {
        thirdFooterColumnName: 'Find Events',
        countries: [
            {
                locale: 'es_AR',
                locale_name: 'Argentina',
                href: 'http://www.evbqa.com.ar',
                tld: '.com.ar',
            },
            {
                locale: 'en_AU',
                locale_name: 'Australia',
                href: 'http://www.evbqa.com.au',
                tld: '.com.au',
            },
            {
                locale: 'nl_BE',
                locale_name: 'België',
                href: 'http://www.evbqa.be',
                tld: '.be',
            },
            {
                locale: 'fr_BE',
                locale_name: 'Belgique',
                href: 'http://www.evbqa.be/fr_BE/',
                tld: '.be',
            },
            {
                locale: 'pt_BR',
                locale_name: 'Brasil',
                href: 'http://www.evbqa.com.br',
                tld: '.com.br',
            },
            {
                locale: 'en_CA',
                locale_name: 'Canada (EN)',
                href: 'http://www.evbqa.ca',
                tld: '.ca',
            },
            {
                locale: 'fr_CA',
                locale_name: 'Canada (FR)',
                href: 'http://www.evbqa.ca/fr_CA/',
                tld: '.ca',
            },
            {
                locale: 'es_CL',
                locale_name: 'Chile',
                href: 'http://www.evbqa.cl',
                tld: '.cl',
            },
            {
                locale: 'es_CO',
                locale_name: 'Colombia',
                href: 'http://www.evbqa.co',
                tld: '.co',
            },
            {
                locale: 'de_DE',
                locale_name: 'Deutschland',
                href: 'http://www.evbqa.de',
                tld: '.de',
            },
            {
                locale: 'es_ES',
                locale_name: 'España',
                href: 'http://www.evbqa.es',
                tld: '.es',
            },
            {
                locale: 'en_FI',
                locale_name: 'Finland',
                href: 'http://www.evbqa.fi',
                tld: '.fi',
            },
            {
                locale: 'fr_FR',
                locale_name: 'France',
                href: 'http://www.evbqa.fr',
                tld: '.fr',
            },
            {
                locale: 'en_HK',
                locale_name: 'Hong Kong',
                href: 'http://www.evbqa.hk',
                tld: '.hk',
            },
            {
                locale: 'en_IE',
                locale_name: 'Ireland',
                href: 'http://www.evbqa.ie',
                tld: '.ie',
            },
            {
                locale: 'it_IT',
                locale_name: 'Italia',
                href: 'http://www.evbqa.it',
                tld: '.it',
            },
            {
                locale: 'es_MX',
                locale_name: 'México',
                href: 'http://www.evbqa.com.mx',
                tld: '.com.mx',
            },
            {
                locale: 'nl_NL',
                locale_name: 'Nederland',
                href: 'http://www.evbqa.nl',
                tld: '.nl',
            },
            {
                locale: 'en_NZ',
                locale_name: 'New Zealand',
                href: 'http://www.evbqa.co.nz',
                tld: '.co.nz',
            },
            {
                locale: 'de_AT',
                locale_name: 'Österreich',
                href: 'http://www.evbqa.at',
                tld: '.at',
            },
            {
                locale: 'es_PE',
                locale_name: 'Perú',
                href: 'http://www.evbqa.com.pe',
                tld: '.com.pe',
            },
            {
                locale: 'pt_PT',
                locale_name: 'Portugal',
                href: 'http://www.evbqa.pt',
                tld: '.pt',
            },
            {
                locale: 'en_SG',
                locale_name: 'Singapore',
                href: 'http://www.evbqa.sg',
                tld: '.sg',
            },
            {
                locale: 'en_SE',
                locale_name: 'Sweden',
                href: 'http://www.evbqa.se',
                tld: '.se',
            },
            {
                locale: 'de_CH',
                locale_name: 'Switzerland (DE)',
                href: 'http://www.evbqa.ch',
                tld: '.ch',
            },
            {
                locale: 'fr_CH',
                locale_name: 'Switzerland (FR)',
                href: 'http://www.evbqa.ch/fr_CH/',
                tld: '.ch',
            },
            {
                locale: 'en_GB',
                locale_name: 'United Kingdom',
                href: 'http://www.evbqa.co.uk',
                tld: '.co.uk',
            },
            {
                locale: 'en_US',
                locale_name: 'United States',
                href: 'http://www.evbqa.com',
                tld: '.com',
            },
        ],
        thirdFooterColumn: [
            {
                locale: 'en_US',
                pk: 1,
                title: 'Boston Events',
                link: 'https://www.eventbrite.com/d/ma--boston/events/',
                position: 1,
            },
            {
                locale: 'en_US',
                pk: 54,
                title: 'Chicago Events',
                link: 'https://www.eventbrite.com/d/il--chicago/events/',
                position: 2,
            },
            {
                locale: 'en_US',
                pk: 637,
                title: 'Denver Events',
                link: 'https://www.eventbrite.com/d/co--denver/events/',
                position: 3,
            },
            {
                locale: 'en_US',
                pk: 638,
                title: 'Houston Events',
                link: 'https://www.eventbrite.com/d/tx--houston/events/',
                position: 4,
            },
            {
                locale: 'en_US',
                pk: 640,
                title: 'Los Angeles Events',
                link: 'https://www.eventbrite.com/d/ca--los-angeles/events/',
                position: 5,
            },
            {
                locale: 'en_US',
                pk: 639,
                title: 'Nashville Events',
                link: 'https://www.eventbrite.com/d/tn--nashville/events/',
                position: 6,
            },
            {
                locale: 'en_US',
                pk: 641,
                title: 'New York Events',
                link: 'https://www.eventbrite.com/d/ny--new-york/events/',
                position: 7,
            },
            {
                locale: 'en_US',
                pk: 642,
                title: 'San Diego Events',
                link: 'https://www.eventbrite.com/d/ca--san-diego/events/',
                position: 8,
            },
            {
                locale: 'en_US',
                pk: 643,
                title: 'San Francisco Events',
                link: 'https://www.eventbrite.com/d/ca--san-francisco/events/',
                position: 9,
            },
            {
                locale: 'en_US',
                pk: 1117,
                title: 'All Cities',
                link: 'https://www.eventbrite.com/directory/sitemap/',
                position: 10,
            },
        ],
        popularSearches: [
            {
                locale: 'en_US',
                pk: 564,
                title: 'How It Works',
                link: 'https://www.eventbrite.com/organizer/overview/',
                position: 1,
            },
            {
                locale: 'en_US',
                pk: 811,
                title: 'For Large & Complex Events',
                link: 'https://www.eventbrite.com/organizer/overview/?ref=ebfooter',
                position: 2,
            },
            {
                locale: 'en_US',
                pk: 565,
                title: 'Pricing',
                link: 'https://www.eventbrite.com/organizer/pricing/',
                position: 3,
            },
            {
                locale: 'en_US',
                pk: 678,
                title: 'Content Standards',
                link: 'https://www.eventbrite.com/l/contentstandards/',
                position: 4,
            },
            {
                locale: 'en_US',
                pk: 539,
                title: 'Eventbrite Mobile App',
                link: 'https://www.eventbrite.com/eventbriteapp/',
                position: 5,
            },
            {
                locale: 'en_US',
                pk: 677,
                title: 'Eventbrite Check-In App',
                link: 'https://www.eventbrite.com/l/Organizer-check-in-app/',
                position: 6,
            },
            {
                locale: 'en_US',
                pk: 680,
                title: 'Eventbrite Spectrum',
                link: 'https://www.eventbrite.com/spectrum/',
                position: 7,
            },
            {
                locale: 'en_US',
                pk: 682,
                title: 'Rally - Fun Things To Do',
                link: 'https://www.eventbrite.com/rally/',
                position: 8,
            },
            {
                locale: 'en_US',
                pk: 818,
                title: 'FAQs',
                link: 'https://www.eventbrite.com/l/frequently-asked-questions/',
                position: 9,
            },
            {
                locale: 'en_US',
                pk: 683,
                title: 'Sitemap',
                link: 'https://www.eventbrite.com/sitemap/',
                position: 10,
            },
        ],
        eventsToPlan: [
            {
                locale: 'en_US',
                pk: 571,
                title: 'Conference Management Software',
                link: 'https://www.eventbrite.com/l/conferences/',
                position: 1,
            },
            {
                locale: 'en_US',
                pk: 572,
                title: 'Eventbrite + Facebook',
                link: 'https://www.eventbrite.com/l/facebook/',
                position: 2,
            },
            {
                locale: 'en_US',
                pk: 573,
                title: 'Nonprofits & Fundraisers',
                link: 'https://www.eventbrite.com/l/npo/',
                position: 3,
            },
            {
                locale: 'en_US',
                pk: 575,
                title: 'Sell Tickets',
                link: 'https://www.eventbrite.com/l/sell-tickets/',
                position: 4,
            },
            {
                locale: 'en_US',
                pk: 578,
                title: 'Event Management & Planning',
                link: 'https://www.eventbrite.com/l/event-management-software/',
                position: 5,
            },
            {
                locale: 'en_US',
                pk: 817,
                title: 'Online Event Registration',
                link: 'https://www.eventbrite.com/l/registration-online/',
                position: 6,
            },
            {
                locale: 'en_US',
                pk: 693,
                title: 'Online RSVP',
                link: 'https://www.eventbrite.com/l/online-rsvp/',
                position: 7,
            },
            {
                locale: 'en_US',
                pk: 576,
                title: 'Music Venues & Promoters',
                link: 'https://www.eventbrite.com/l/music-venues/',
                position: 8,
            },
            {
                locale: 'en_US',
                pk: 973,
                title: 'Event Equipment & Staffing',
                link: 'https://www.eventbrite.com/l/onsite/',
                position: 9,
            },
            {
                locale: 'en_US',
                pk: 580,
                title: 'Eventbrite Sales Number',
                link: 'https://www.eventbrite.com/l/eventbrite-sales-contact/',
                position: 10,
            },
        ],
        showMinimalLinks: false,
    },
    request: {
        path: '/',
        correlation_id: 'fbcc27264f3711e9909906898d06f880',
        params: {},
        session_id: 'gsJ4Df-1aZWvAUPB9G0PLQ',
    },
    user: {
        hasTikibar: false,
        publicId: null,
        hasEvents: false,
        hasOrganizers: false,
        usesNewPermissions: false,
        isStaff: false,
        canInvite: false,
        numFollowing: 0,
        fullName: null,
        thumbnailUrl: null,
        numOrders: 0,
        numSavedEvents: 0,
        firstName: 'Test',
        isAttendee: false,
        lastName: 'Testing',
        canUseStudio: false,
        canUseAttendeeNewsletter: false,
        canCreateEvents: false,
        email: 'example@example.com',
        isAuthenticated: true,
    },
    gaSettings: {},
};

export default serverData;
