import * as React from 'react';

const CrmAppMarketplaceSvg = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            viewBox="0 0 340 275"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <title>{'illustrations_crm'}</title>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M204.565 237.369c-16.737 9.093-35.918 14.258-56.305 14.258-65.235 0-118.118-52.883-118.118-118.117 0-65.235 52.883-118.118 118.118-118.118 64.385 0 116.739 51.516 118.09 115.578.411-.007.824-.01 1.237-.01 39.685 0 71.857 32.171 71.857 71.857s-32.172 71.858-71.857 71.858c-27.163 0-50.805-15.07-63.022-37.306z"
                    fill="#82D2DC"
                    fillRule="nonzero"
                    opacity={0.3}
                />
                <path
                    d="M224.138 179.54l43.449 36.125M201.94 66.817l22.59-46.652M103.83 165.221l-73.688 48.417M243.754 111.965l85.294-22.23M103.34 99.935L41.062 69.204"
                    stroke="#F05537"
                    strokeWidth={2}
                />
                <circle
                    fill="#F05537"
                    fillRule="nonzero"
                    cx={267.587}
                    cy={215.665}
                    r={29.063}
                />
                <circle
                    fill="#F05537"
                    fillRule="nonzero"
                    cx={39.492}
                    cy={68.452}
                    r={38.61}
                />
                <circle
                    fill="#F05537"
                    fillRule="nonzero"
                    cx={224.531}
                    cy={20.165}
                    r={19.91}
                />
                <circle
                    fill="#F05537"
                    fillRule="nonzero"
                    cx={46.717}
                    cy={202.817}
                    r={25.5}
                />
                <circle
                    fill="#F05537"
                    fillRule="nonzero"
                    cx={309.89}
                    cy={94.704}
                    r={25.5}
                />
                <circle
                    fill="#F05537"
                    fillRule="nonzero"
                    cx={169.412}
                    cy={134.294}
                    r={82.548}
                />
                <circle
                    fill="#FFF"
                    fillRule="nonzero"
                    cx={169.412}
                    cy={134.294}
                    r={72.806}
                />
                <g fillRule="nonzero">
                    <path
                        d="M197.462 107.912V120.4c-3.27 21.806-12.456 28.05-12.456 28.05a40.31 40.31 0 01-31.189 0s-9.35-6.244-12.488-28.05v-12.488l12.488-6.245 31.189 12.489 12.456-6.244z"
                        fill="#FFF"
                    />
                    <path
                        d="M188.83 165.221c21.84 12.489 49.04 14.417 49.04 45.606v6.211H100.561v-6.211c0-31.189 27.363-33.117 49.038-45.606a21.446 21.446 0 0019.42 10.037 23.898 23.898 0 0019.81-10.037z"
                        fill="#1A0E2F"
                    />
                    <path
                        d="M197.462 120.4v-12.488l-12.456 6.244-31.189-12.489-12.488 6.245V120.4c3.27 21.806 14.613 28.998 15.725 29.423a18.733 18.733 0 01-7.062 15.464s5.002 10.003 19.616 10.003a23.506 23.506 0 0019.223-10.003 17.196 17.196 0 01-7.094-15.464c1.144-.425 12.619-7.617 15.725-29.423z"
                        fill="#F05537"
                        opacity={0.5}
                        style={{
                            mixBlendMode: 'multiply',
                        }}
                    />
                    <path
                        d="M153.817 101.765l-12.488 6.212c0-18.929 9.121-28.05 28.083-28.05 18.961 0 28.05 9.121 28.05 28.05l-12.456 6.244-31.189-12.456z"
                        fill="#F05537"
                    />
                </g>
            </g>
        </svg>
    );
};

CrmAppMarketplaceSvg.displayName = 'CrmAppMarketplaceSvg';
export default CrmAppMarketplaceSvg;
