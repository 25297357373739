import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer as routing } from 'react-router-redux';

import {
    SET_LOADING,
    SELECT_CATEGORY,
    IS_FETCHING,
    HAS_FAILED,
    SET_USER_LOCALE,
    SET_IS_USER_SIGNED_IN,
} from '../actions';
import { categories, categoriesFetching } from './Categories';
import { appsListMap, appsByCategory, currentApp } from './Apps';
import { searchedTerm, filteredApps, paginationObj } from './Search';
import { currentAppRating, appRatings, reviewFromUser } from './Ratings';

const isLoading = (state = false, { type, payload }) => {
    let newState = state;

    if (type === SET_LOADING) {
        newState = payload;
    }

    return newState;
};

const selectedCategory = (state = -1, { type, payload }) => {
    let newState = state;

    if (type === SELECT_CATEGORY) {
        newState = payload;
    }

    return newState;
};

const isFetching = (state = {}, { type, payload }) => {
    const newState = {};

    Object.assign(newState, state);

    if (type === IS_FETCHING) {
        newState[payload.type] = payload.value;
    }

    return newState;
};

const hasFailed = (state = {}, { type, payload }) => {
    const newState = {};

    Object.assign(newState, state);

    if (type === HAS_FAILED) {
        newState[payload.type] = payload.value;
    }

    return newState;
};

const userLocale = (state = '', { type, payload }) => {
    let newState = state;

    if (type === SET_USER_LOCALE) {
        newState = payload;
    }

    return newState;
};

const isUserSignedIn = (state = false, { type, payload }) => {
    let newState = state;

    if (type === SET_IS_USER_SIGNED_IN) {
        newState = payload;
    }

    return newState;
};

export default combineReducers({
    appsByCategory,
    appsListMap,
    categories,
    categoriesFetching,
    currentApp,
    filteredApps,
    isLoading,
    paginationObj,
    searchedTerm,
    selectedCategory,
    routing,
    isFetching,
    hasFailed,
    userLocale,
    currentAppRating,
    reviewFromUser,
    appRatings,
    form: formReducer,
    isUserSignedIn,
});
