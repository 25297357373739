import { LOAD_CATEGORIES, CATEGORIES_FETCHING } from '../actions/Categories';

export const categories = (state = [], { type, payload }) => {
    let nextState = state;

    if (type === LOAD_CATEGORIES) {
        nextState = payload;
    }

    return nextState;
};

export const categoriesFetching = (state = false, { type, payload }) => {
    let nextState = state;

    if (type === CATEGORIES_FETCHING) {
        nextState = payload;
    }

    return nextState;
};
