import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ProgressIndicator } from '@eventbrite/eds-progress-indicator';
import RatingStars from '../../containers/ConnectedRatingStars';
import { gettext } from '@eventbrite/i18n';
import './OneClickRatingCTA.scss';

export default class OneClickRatingCTA extends PureComponent {
    static propTypes = {
        appId: PropTypes.string.isRequired,
        appName: PropTypes.string.isRequired,
        createNewReview: PropTypes.func.isRequired,
        editReview: PropTypes.func.isRequired,
        hasReview: PropTypes.bool.isRequired,

        currentReviewId: PropTypes.string,
        currentRating: PropTypes.number,
        onAddReviewBtnClick: PropTypes.func,
        onLoad: PropTypes.func,
    };

    constructor() {
        super();

        this.state = {
            isLoading: false,
        };
    }

    componentDidMount() {
        const { onLoad } = this.props;

        if (onLoad) {
            this.setState({ isLoading: true });
            onLoad().finally(() => this.setState({ isLoading: false }));
        }
    }

    _handleStarClick(starRating) {
        const {
            appId,
            appName,
            createNewReview,
            currentReviewId,
            editReview,
            hasReview,
            onAddReviewBtnClick,
        } = this.props;

        this.setState({ isLoading: true });
        if (currentReviewId) {
            editReview(currentReviewId, { rating: starRating })
                .then(() => {
                    if (onAddReviewBtnClick) {
                        onAddReviewBtnClick();
                    }
                })
                .finally(() => this.setState({ isLoading: false }));
        } else {
            createNewReview(appId, { rating: starRating })
                .then(() => {
                    if (onAddReviewBtnClick) {
                        onAddReviewBtnClick();
                    }
                })
                .finally(() => this.setState({ isLoading: false }));
        }
    }

    render() {
        const { appName, currentRating, currentReviewId } = this.props;
        const { isLoading } = this.state;
        let titleText = gettext('Rate your experience with %(appName)s', {
            appName,
        });

        if (isLoading) {
            return (
                <div className="eds-align--center eds-l-mar-vert-16">
                    <ProgressIndicator style="gradient" />
                </div>
            );
        }

        if (currentReviewId) {
            titleText = gettext('Your rating for %(appName)s', { appName });
        }

        return (
            <div className="one-click-rating-wrapper eds-align--center">
                <h2 className="eds-l-mar-bot-4">{titleText}</h2>
                <RatingStars
                    isDisabled={false}
                    isReadOnly={false}
                    onStarClick={this._handleStarClick.bind(this)}
                    rating={currentRating}
                    size="medium"
                />
            </div>
        );
    }
}
