import { Icon } from '@eventbrite/eds-icon';
import { ArrowRightChunky } from '@eventbrite/eds-iconography';
import { GenericLazyString } from '@eventbrite/i18n';
import React, { FunctionComponent } from 'react';
import { AppCardLinkProp, ROUTER_LINK } from '../../constants';
import Link from '../Link';
import './ArrowLink.scss';

const ArrowLink: FunctionComponent<{
    text?: string | GenericLazyString;
    url?: string;
    onClick?: () => void;
    linkComponentType?: AppCardLinkProp;
}> = ({ url, text, onClick, linkComponentType }) =>
    url ? (
        <div className="arrow-link">
            <Link
                to={url}
                onClick={onClick}
                type={linkComponentType ?? ROUTER_LINK}
            >
                <span>{text}</span>
                <Icon type={<ArrowRightChunky />} size="xsmall" />
            </Link>
        </div>
    ) : null;

export default ArrowLink;
