import React, { PureComponent } from 'react';
import InternalLink from '../InternalLink/InternalLink';
import { Link } from 'react-router';
import { Illustration } from '@eventbrite/eds-illustration';

import { BASE_APPS_URL } from '../../constants/constants';

export default class CategoriesExploreItem extends PureComponent {
    render() {
        const { category, IllustrationType } = this.props;
        const categoryLink = `${BASE_APPS_URL}categories/${category.slug}/`;

        return (
            <div className="categories-explore-section--item">
                <div className="categories-explore-section--item__image-container">
                    <Link
                        className="categories-explore-section--item__image"
                        to={categoryLink}
                    >
                        <Illustration
                            type={<IllustrationType />}
                            alt={category.name}
                            height="150px"
                            width="auto"
                        />
                    </Link>
                </div>
                <h3 className="categories-explore-section--item__description eds-text-bl eds-text-weight--heavy">
                    <InternalLink to={categoryLink} style="neutral">
                        {category.name_localized || category.name}
                    </InternalLink>
                </h3>
            </div>
        );
    }
}
