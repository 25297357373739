import * as React from 'react';

const MagnifyingGlassSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 14.9l-.7.7-1.2-1.2c1.2-1.3 1.9-3 1.9-4.9C17 5.4 13.6 2 9.5 2S2 5.4 2 9.5 5.4 17 9.5 17c1.9 0 3.6-.7 4.9-1.9l1.2 1.2-.7.7 5 5 2.1-2.1-5-5zM3 9.5C3 5.9 5.9 3 9.5 3S16 5.9 16 9.5 13.1 16 9.5 16 3 13.1 3 9.5zM16.3 17l.7-.7 3.6 3.6-.7.7-3.6-3.6z"
        />
    </svg>
);

MagnifyingGlassSvg.displayName = 'MagnifyingGlassSvg';
export default MagnifyingGlassSvg;
