import * as React from 'react';

const ReportingSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 336 239" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M61.959 122.54C28.129 122.54.703 95.113.703 61.283.703 27.453 28.128.028 61.96.028c33.83 0 61.256 27.425 61.256 61.256 0 33.83-27.425 61.255-61.256 61.255zm172.954 112.478c-55.61 0-100.69-45.08-100.69-100.69s45.08-100.69 100.69-100.69 100.69 45.08 100.69 100.69-45.08 100.69-100.69 100.69z"
                fill="#82D2DC"
                fillRule="nonzero"
                opacity={0.3}
            />
            <circle stroke="#1A0E2F" cx={163.123} cy={228.274} r={9.475} />
            <path d="M163.123 201.966v16.832" stroke="#1A0E2F" />
            <path
                fill="#F05537"
                fillRule="nonzero"
                d="M290.066 28.12h-81.573l-7.44-9.002h-75.887l-7.441 9.002H36.18v11.76h6.27v166.377h241.344V39.88h6.27z"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M50.616 39.88h224.93v157.961H50.616z"
            />
            <path
                fill="#1A0E2F"
                fillRule="nonzero"
                d="M70.793 136.056h18.811v46.234H70.793z"
            />
            <path
                fill="#F05537"
                fillRule="nonzero"
                opacity={0.5}
                style={{
                    mixBlendMode: 'multiply',
                }}
                d="M96.795 124.602h18.811v57.689H96.795z"
            />
            <path
                fill="#1A0E2F"
                fillRule="nonzero"
                d="M122.797 102.139h18.811v80.151h-18.811z"
            />
            <path
                fill="#F05537"
                fillRule="nonzero"
                opacity={0.5}
                style={{
                    mixBlendMode: 'multiply',
                }}
                d="M148.798 124.602h18.811v57.689h-18.811z"
            />
            <path
                stroke="#F05537"
                d="M70.821 182.29l66.272-54.344 22.073 23.438 83.913-68.558"
            />
            <path stroke="#F05537" d="M226.72 87.648l16.359-4.822-8.082 15.3" />
            <path
                d="M224.908 150.269l19.258 25.583a32.021 32.021 0 118.778-41.078l-28.036 15.495z"
                fill="#F05537"
                fillRule="nonzero"
            />
            <path
                d="M224.908 149.628l23.577-21.125c11.197 12.526 10.671 31.613-1.198 43.504l-22.379-22.38z"
                fill="#F05537"
                fillRule="nonzero"
                opacity={0.5}
                style={{
                    mixBlendMode: 'multiply',
                }}
            />
            <path
                d="M70.793 67.972h96.789M70.793 79.9h70.787M70.793 91.856h39.073"
                stroke="#1A0E2F"
            />
            <path
                fill="#F05537"
                fillRule="nonzero"
                opacity={0.5}
                style={{
                    mixBlendMode: 'multiply',
                }}
                d="M42.423 39.88h241.372v4.125H42.423z"
            />
        </g>
    </svg>
);

ReportingSvg.displayName = 'ReportingSvg';
export default ReportingSvg;
