import { Button } from '@eventbrite/eds-button';
import { Icon } from '@eventbrite/eds-icon';
import { RefreshChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import React, { FunctionComponent } from 'react';
import './RefreshContent.scss';

const RefreshContent: FunctionComponent<{ onClickHandler: () => void }> = ({
    onClickHandler,
}) => (
    <div className="refresh-content">
        <div className="refresh-content--message eds-text-weight--heavy">
            {gettext(
                'Whoa, something weird happened here. Try refreshing this section.',
            )}
        </div>
        <div className="refresh-content--button">
            <Button style="none" onClick={onClickHandler}>
                <Icon type={<RefreshChunky />} size="small" />
            </Button>
        </div>
    </div>
);

export default RefreshContent;
