import { connect } from 'react-redux';

import ReviewReplyForm from '../components/reviewReplyForm/ReviewReplyForm';
import { createNewReviewReply, editReviewReply } from '../actions/Ratings';

const _mapStateToProps = ({ currentApp }) => ({
    appId: currentApp && currentApp.id,
    appName: currentApp && currentApp.name,
});

const _mapPropsToDispatch = {
    createNewReviewReply,
    editReviewReply,
};

export default connect(_mapStateToProps, _mapPropsToDispatch)(ReviewReplyForm);
