import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import chunk from 'lodash/chunk';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import MetaTags from 'react-meta-tags';
import { AppDiscoverySection } from '@eventbrite/app-discovery';

import { Layout } from '@eventbrite/eds-layout';
import CategoriesFilter from '../containers/ConnectedCategoriesFilter';
import {
    getCategoryIllustration,
    getCategoryInfo,
} from '../utils/categoryMapHelper';
import CategoriesExploreSection from '../containers/ConnectedCategoriesExploreSection';

import RefreshContent from '../components/RefreshContent/RefreshContent';
import { ProgressIndicator } from '@eventbrite/eds-progress-indicator';
import Header from '../components/Header/Header';
import AppPagination from '../components/AppPagination/AppPagination';
import NotFound from '../containers/ConnectedNotFound';

import {
    CURRENT_CATEGORIES_TO_NEW_MAP,
    MAX_APPS_TO_DISPLAY,
} from '../constants/constants';

export default class CategoryPage extends PureComponent {
    static propTypes = {
        categories: PropTypes.arrayOf(PropTypes.shape).isRequired,
        appsByCategory: PropTypes.objectOf(PropTypes.shape).isRequired,
        fetchAppsByCategory: PropTypes.func.isRequired,
    };

    constructor() {
        super();

        this.state = {
            slug: '',
            category: null,
            pagination: {
                pageNumber: 1,
            },
        };
    }

    componentDidMount() {
        this.updateCategoryAndSlug();
    }

    componentDidUpdate(prevProps, prevState) {
        this.updateCategoryAndSlug();

        const { appsByCategory, fetchAppsByCategory } = this.props;

        if (!isEqual(prevState.category, this.state.category)) {
            if (!appsByCategory[this.state.slug]) {
                fetchAppsByCategory(this.state.category.id, this.state.slug);
            }

            this.setState({ pagination: { pageNumber: 1 } });
        }
    }

    updateCategoryAndSlug() {
        const { categories, router } = this.props;

        let slug = router.params && router.params.slug;

        // checking if the slug is from an old category
        const newSlug = CURRENT_CATEGORIES_TO_NEW_MAP[slug];

        if (newSlug) {
            slug = newSlug;
        }

        const category = categories && filter(categories, { slug });

        if (category[0]) {
            this.setState({ category: category[0] });
        }

        this.setState({ slug });
    }

    _handlePageSelection = (page) => {
        if (page !== this.state.pagination.pageNumber) {
            this.setState({ pagination: { pageNumber: page } });
        }
    };

    _handleRefreshAppsByCategory = () => {
        const { appsByCategory, fetchAppsByCategory } = this.props;

        if (!appsByCategory[this.state.slug]) {
            fetchAppsByCategory(this.state.category.id, this.state.slug);
        }
    };

    _getAppDisplay = () => {
        const { appsByCategory, isFetching } = this.props;

        const { slug, pagination } = this.state;
        const { pageNumber } = pagination;

        if (!isEmpty(appsByCategory[slug])) {
            const appsByPage = chunk(appsByCategory[slug], MAX_APPS_TO_DISPLAY);
            const currentlyViewableApps = appsByPage[pageNumber - 1];

            return (
                <div>
                    <AppDiscoverySection
                        apps={currentlyViewableApps}
                        maxApps={MAX_APPS_TO_DISPLAY}
                    />
                    <AppPagination
                        appsByPage={appsByPage}
                        onPageSelection={this._handlePageSelection}
                        totalApps={appsByCategory[slug].length}
                        pageNumber={pageNumber}
                    />
                </div>
            );
        } else if (isFetching.appsByCategory) {
            return (
                <div className="eds-align--center">
                    <ProgressIndicator
                        style="gradient"
                        size="large-chunky"
                        shape="circular"
                    />
                </div>
            );
        } else if (
            isFetching.appsByCategory === false &&
            !appsByCategory[slug]
        ) {
            return (
                <RefreshContent
                    onClickHandler={this._handleRefreshAppsByCategory}
                />
            );
        }

        return null;
    };

    _getCategoryPageContent = (categoryInfo) => {
        const { slug, category } = this.state;

        const pageTitle =
            category && category.name_localized
                ? `${
                      category && category.name_localized
                  } | Eventbrite App Marketplace`
                : 'Eventbrite App Marketplace';
        const headline = (categoryInfo && categoryInfo.description) || '';

        return (
            <Layout maxWidth="large" hasHorizontalGutters={true}>
                <MetaTags>
                    <title>{pageTitle}</title>
                    <meta name="description" content={headline} />
                </MetaTags>
                <div className="eds-l-mar-bot-8">
                    <div>
                        <Header
                            headline={headline}
                            align="left"
                            hero={getCategoryIllustration(slug)}
                            margin={true}
                            heroVerticalAlignCenter={true}
                        />
                        <div className="eds-l-mar-top-4">
                            <CategoriesFilter />
                        </div>
                    </div>
                    <div className="eds-l-mar-bot-4 eds-l-mar-top-10">
                        <h4 className="eds-text-hs">
                            {category && category.name_localized}
                        </h4>
                    </div>
                    <div className="eds-l-mar-bot-10">
                        {this._getAppDisplay()}
                    </div>
                </div>
                <div className="eds-l-mar-bot-10">
                    <CategoriesExploreSection />
                </div>
            </Layout>
        );
    };

    render() {
        const { slug } = this.state;
        const categoryInfo = getCategoryInfo(slug);

        let content = <NotFound />;

        if (categoryInfo) {
            content = this._getCategoryPageContent(categoryInfo);
        }

        return content;
    }
}
