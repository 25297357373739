import uniq from 'lodash/uniq';

import {
    CATEGORIES_INFO_MAP,
    CURRENT_CATEGORIES_TO_NEW_MAP,
} from '../constants/constants';

export const getCategoryIllustration = (slug) => {
    let illustrationComponent;

    const categorySlug = CURRENT_CATEGORIES_TO_NEW_MAP[slug] || slug;

    if (
        CATEGORIES_INFO_MAP[categorySlug] &&
        CATEGORIES_INFO_MAP[categorySlug].illustration
    ) {
        illustrationComponent = CATEGORIES_INFO_MAP[categorySlug].illustration;
    }

    return illustrationComponent;
};

export const getCategoryInfo = (slug) => {
    let categoryInfo;

    const categorySlug = CURRENT_CATEGORIES_TO_NEW_MAP[slug] || slug;

    if (CATEGORIES_INFO_MAP[categorySlug]) {
        categoryInfo = CATEGORIES_INFO_MAP[categorySlug];
    }

    return categoryInfo;
};

export const getNewCategoriesFromOld = (categories) =>
    uniq(
        categories.map(
            (category) =>
                CATEGORIES_INFO_MAP[
                    CURRENT_CATEGORIES_TO_NEW_MAP[category.slug]
                ],
        ),
    );
