import React, { PureComponent } from 'react';
import Header from '../components/Header/Header';
import CategoriesFilter from '../containers/ConnectedCategoriesFilter';
import { gettext } from '@eventbrite/i18n';
import { Layout } from '@eventbrite/eds-layout';

import { NotFoundIllustration } from '../svgs/index';

export default class NotFound extends PureComponent {
    componentDidMount() {
        const { categoriesFetching, fetchCategories } = this.props;

        if (!categoriesFetching) {
            fetchCategories();
        }
    }

    render() {
        return (
            <Layout maxWidth="large" hasHorizontalGutters={true}>
                <div className="eds-l-mar-bot-8">
                    <div>
                        <Header
                            headline={gettext('Page not found')}
                            secondary={gettext(
                                'Try searching for apps or exploring categories below',
                            )}
                            alwaysShowSecondary={true}
                            align="left"
                            hero={NotFoundIllustration}
                            margin={false}
                            heroVerticalAlignCenter={false}
                        />
                        <div className="eds-l-mar-top-4">
                            <CategoriesFilter />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
