import { connect } from 'react-redux';
import { gettext } from '@eventbrite/i18n';

import EditReviewModalContent from '../components/modals/EditReviewModalContent';

const _calculateSubmitButtonText = (reviewFromUser) => {
    let btnText = gettext('Add Review');

    if (reviewFromUser && reviewFromUser.reviewTitle) {
        btnText = gettext('Update Review');
    }

    return btnText;
};

const _mapStateToProps = ({ currentApp, reviewFromUser }) => ({
    appName: (currentApp && currentApp.name) || '',
    submitButtonText: _calculateSubmitButtonText(reviewFromUser),
});

export default connect(_mapStateToProps)(EditReviewModalContent);
