import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Star } from '@eventbrite/eds-iconography';
import { StarFill } from '@eventbrite/eds-iconography';
import { StarHalfFilled } from '../../svgs/index';
import { Button } from '@eventbrite/eds-button';
import { Icon } from '@eventbrite/eds-icon';
import classNames from 'classnames';
import fill from 'lodash/fill';
import isUndefined from 'lodash/isUndefined';
import { ICON_SIZE_PROP_TYPE } from '@eventbrite/eds-icon';
import './RatingStars.scss';

export default class RatingStars extends PureComponent {
    static propTypes = {
        isDisabled: PropTypes.bool,
        setRatingForSelectedApp: PropTypes.func,
        size: ICON_SIZE_PROP_TYPE,
        rating: PropTypes.number,
        isReadOnly: PropTypes.bool,
    };

    static defaultProps = {
        isDisabled: false,
        isReadOnly: true,
    };

    constructor() {
        super();

        this.state = {
            stars: [],
            starsHovered: [],
        };
    }

    componentDidMount() {
        this._calculateStarFillStatus();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rating !== this.props.rating) {
            this._calculateStarFillStatus();
        }
    }

    _calculateStarFillStatus = () => {
        const stars = fill(Array(5), false);

        const { rating } = this.props;

        if (!isUndefined(rating)) {
            const decimalPortionRating = this._retrievePartOfRating(
                true,
                rating,
            );
            const integerPortionRating = this._retrievePartOfRating(
                false,
                rating,
            );
            let quantityOfEmptyStars = 5 - integerPortionRating;
            let starsHalfFilled = [];

            if (decimalPortionRating > 1) {
                quantityOfEmptyStars--;
                starsHalfFilled = fill(Array(1), 2);
            }

            const starsFilled = fill(Array(integerPortionRating), 1);
            const starsEmpty = fill(Array(quantityOfEmptyStars), 0);
            const starsFromProps = [
                ...starsFilled,
                ...starsHalfFilled,
                ...starsEmpty,
            ];

            this.setState({ stars: starsFromProps });
        } else {
            this.setState({ stars });
        }
    };

    _retrievePartOfRating = (decimal, rating) => {
        const whichPartToRetrieve = decimal ? 1 : 0;
        const ratingSplit = `${rating}`.split('.');
        const decimalRating = ratingSplit[whichPartToRetrieve];

        if (decimalRating >= 0) {
            return parseInt(decimalRating, 10);
        }

        return 0;
    };

    _getRating = (stars) => {
        let rating = 0;

        stars.forEach((value) => {
            if (value) {
                rating++;
            }
        });

        return rating;
    };

    _handleStarClick(index, isSelected) {
        const { onStarClick, setRatingForSelectedApp } = this.props;
        const { stars } = this.state;
        const currentStarNumber = index + 1;
        let starSelected = false;

        let starsCurrentlySelected = 0;

        stars.forEach((selected) => {
            if (selected) {
                starsCurrentlySelected++;
            }
        });

        if (starsCurrentlySelected <= currentStarNumber) {
            starSelected = isSelected ? 0 : 1;
        } else {
            starSelected = 1;
        }

        let newStars = fill(Array(currentStarNumber), starSelected);

        const remainingStars = fill(Array(5 - currentStarNumber), 0);

        newStars = [...newStars, ...remainingStars];

        setRatingForSelectedApp(this._getRating(newStars));
        this.setState(
            { stars: newStars },
            () => onStarClick && onStarClick(currentStarNumber),
        );
    }

    _handleMouseInteraction = (currentStar, isMouseEnter) => {
        const starsHovered = fill(Array(currentStar), isMouseEnter);

        this.setState({ starsHovered });
    };

    render() {
        const starsSection = [];

        const { isDisabled, isReadOnly, size } = this.props;
        const { stars, starsHovered } = this.state;

        const createIcon = (starIconType, idx, value, isHovered) => {
            let colorBasedOnMouseState = 'ui-orange';

            if (isHovered) {
                colorBasedOnMouseState = 'ui-orange--hover';
            }

            let iconComponent = (
                <Icon
                    type={<StarFill />}
                    size={size}
                    title="Star"
                    color="grey-300"
                />
            );

            if (!isDisabled && isReadOnly) {
                iconComponent = (
                    <Icon
                        type={starIconType}
                        size={size}
                        title="Star"
                        color="ui-orange"
                    />
                );
            } else if (!isDisabled) {
                iconComponent = (
                    <Button
                        style="none"
                        onClick={this._handleStarClick.bind(this, idx, value)}
                        onMouseEnter={this._handleMouseInteraction.bind(
                            this,
                            idx + 1,
                            true,
                        )}
                        onMouseLeave={this._handleMouseInteraction.bind(
                            this,
                            idx + 1,
                            false,
                        )}
                    >
                        <Icon
                            type={starIconType}
                            title="Star"
                            size={size}
                            color={colorBasedOnMouseState}
                        />
                    </Button>
                );
            }

            return iconComponent;
        };

        stars.forEach((value, idx) => {
            let starIconType = <Star />;

            if (value === 1 || starsHovered[idx]) {
                starIconType = <StarFill />;
            } else if (value === 2) {
                starIconType = <StarHalfFilled />;
            }

            const starClass = classNames(
                { [`star-spacing--${size}`]: stars.length - 1 !== idx },
                'eds-align--left',
                `star-${idx + 1}`,
            );

            starsSection.push(
                <div className={starClass} key={idx}>
                    {createIcon(starIconType, idx, value, starsHovered[idx])}
                </div>,
            );
        });

        return (
            <div>
                <div className="eds-align--left rating-stars--container">
                    {starsSection}
                </div>
            </div>
        );
    }
}
