import { connect } from 'react-redux';

import RatingStars from '../components/ratingStars/RatingStars';
import { setRatingForSelectedApp } from '../actions/Ratings';

const _mapStateToProps = ({ reviewFromUser }) => ({
    rating: (reviewFromUser && reviewFromUser.rating) || undefined,
});

const _mapPropsToDispatch = {
    setRatingForSelectedApp,
};

export default connect(_mapStateToProps, _mapPropsToDispatch)(RatingStars);
