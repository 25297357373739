import * as React from 'react';

const TicketingSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 336 237" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M74.411 95.687C84.366 41.8 131.601.983 188.368.983c64.003 0 115.889 51.885 115.889 115.889 0 64.003-51.886 115.888-115.89 115.888-23.972 0-46.245-7.279-64.728-19.747-12.912 14.48-31.709 23.596-52.635 23.596-38.937 0-70.502-31.564-70.502-70.501 0-38.938 31.565-70.502 70.502-70.502 1.142 0 2.278.027 3.407.08z"
                fill="#82D2DC"
                fillRule="nonzero"
                opacity={0.3}
            />
            <path
                d="M67.25 185.962l5.358 32.493 47.664-7.859 19.886-3.207c-1.056-7.19 3.85-13.899 11.021-15.072 7.172-1.173 13.96 3.622 15.25 10.774l116.497-19.246a115.247 115.247 0 0017.321-36.758L67.251 185.962z"
                fill="#F05537"
                fillRule="nonzero"
                opacity={0.5}
                style={{
                    mixBlendMode: 'multiply',
                }}
            />
            <path
                d="M134.417 52.913c1.103 7.121-3.66 13.827-10.748 15.13-7.087 1.303-13.924-3.27-15.426-10.318L40.885 70.17l26.43 142.992 67.359-12.445c-1.186-7.157 3.577-13.945 10.71-15.265 7.133-1.32 14.01 3.315 15.463 10.422l175.004-32.3-26.43-143.121-175.004 32.46z"
                fill="#F05537"
                fillRule="nonzero"
            />
            <path d="M126.59 79.792l1.444 7.891" stroke="#FFF" />
            <path
                d="M130.92 103.272l10.105 54.592"
                stroke="#FFF"
                strokeDasharray={4.95}
            />
            <path d="M142.436 165.69l1.475 7.891" stroke="#FFF" />
            <path
                d="M186.123 71.389l7.537 40.319 40.32-7.442-7.442-40.319-40.415 7.442zm37.143 25.66l-22.453 4.138-4.138-22.453 22.453-4.138 4.138 22.453zM234.809 62.401l16.686-3.08 3.08 16.686-16.686 3.08zM239.188 86.043l16.686-3.08 3.08 16.685-16.685 3.08zM258.235 58.094l16.686-3.08 3.08 16.685-16.685 3.08zM262.583 81.735l16.686-3.08 3.08 16.686-16.686 3.08zM195.3 119.782l16.686-3.08 3.08 16.686-16.685 3.08zM199.68 143.418l16.685-3.08 3.08 16.685-16.685 3.08zM218.758 115.469l16.686-3.08 3.08 16.685-16.685 3.081zM223.112 139.142l16.686-3.08 3.08 16.686-16.686 3.08z"
                fill="#FFF"
                fillRule="nonzero"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M205.418 84.65l7.76-1.432 1.432 7.76-7.76 1.432zM243.345 110.777l7.442 40.32 40.319-7.442-7.442-40.351-40.319 7.473zm37.144 25.66l-22.453 4.138-4.138-22.452 22.453-4.138 4.138 22.453z"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M262.619 124.068l7.759-1.433 1.433 7.76-7.76 1.432z"
            />
            <circle
                fill="#1A0E2F"
                fillRule="nonzero"
                cx={79.536}
                cy={100.385}
                r={14.562}
            />
            <path
                d="M71.004 134.417l44.488-8.243M73.698 148.915l44.457-8.211M76.36 163.445l44.49-8.243"
                stroke="#1A0E2F"
            />
        </g>
    </svg>
);

TicketingSvg.displayName = 'TicketingSvg';
export default TicketingSvg;
