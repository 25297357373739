import * as React from 'react';

const ProductivitySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 335 284" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M191.752 224.959c-19.599 13.23-43.22 20.955-68.646 20.955C55.262 245.914.264 190.916.264 123.072S55.262.23 123.106.23c66.68 0 120.951 53.128 122.794 119.362a75.17 75.17 0 0113.478-1.212c41.273 0 74.732 33.459 74.732 74.732 0 41.273-33.459 74.732-74.732 74.732-29.883 0-55.67-17.54-67.626-42.885z"
                fill="#82D2DC"
                fillRule="nonzero"
                opacity={0.3}
            />
            <path
                d="M271.21 42.696l-8.33-3.944c-47.056.578-119 1.938-119 1.938-24.922 0-45.118 18.122-45.118 43.044v175.95l-30.838 11.39a48.28 48.28 0 0030.838 12.75h124.066c26.571-.33 48.02-21.81 48.314-48.382l.068-192.746z"
                fill="#F05537"
                fillRule="nonzero"
                opacity={0.5}
                style={{
                    mixBlendMode: 'multiply',
                }}
            />
            <path
                d="M262.88 178.798V38.412H90.5v238.544H218c24.81-.038 44.899-20.171 44.88-44.982v-53.176z"
                fill="#FFF"
                fillRule="nonzero"
            />
            <path
                d="M123.106 78.09h107.168M123.106 96.212h107.168M123.106 114.3h107.168M123.106 132.388h107.168M123.106 150.51h107.168M123.106 168.598h107.168M123.106 186.686h107.168M123.106 204.808h107.168M123.106 222.896h107.168M123.106 240.984h107.168"
                stroke="#1A0E2F"
            />
            <path
                d="M203.448 274.44a38.182 38.182 0 01-11.56-27.2V224.8h-14.416v49.64h25.976z"
                fill="#F05537"
                fillRule="nonzero"
                opacity={0.5}
                style={{
                    mixBlendMode: 'multiply',
                }}
            />
            <circle
                fill="#1A0E2F"
                fillRule="nonzero"
                cx={85.944}
                cy={76.492}
                r={18.156}
            />
            <path stroke="#FFF" d="M78.056 74.18l6.086 6.086 21.692-21.692" />
            <circle
                fill="#1A0E2F"
                fillRule="nonzero"
                cx={85.944}
                cy={122.732}
                r={18.156}
            />
            <path stroke="#FFF" d="M78.056 120.42l6.086 6.086 21.692-21.726" />
            <circle
                fill="#1A0E2F"
                fillRule="nonzero"
                cx={85.944}
                cy={168.938}
                r={18.156}
            />
            <path
                d="M181.688 238.4v-22.27H45.926v22.27c0 21.219 17.201 38.42 38.42 38.42h135.728c-21.206-.019-38.386-17.214-38.386-38.42z"
                fill="#F05537"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

ProductivitySvg.displayName = 'ProductivitySvg';
export default ProductivitySvg;
