import findIndex from 'lodash/findIndex';
import {
    LOAD_RATINGS,
    CLEAR_RATINGS,
    CLEAR_REVIEW_FROM_USER,
    SET_SELECTED_APP_RATING,
    SET_IS_LOADING,
    SET_PAGE_NUMBER,
    SET_RATING_FILTER,
    SET_RATING_SORT_ORDER,
    SET_REVIEW_FROM_USER,
    REMOVE_REVIEW_REPLY,
    UPDATE_REVIEW_REPLY,
} from '../actions/Ratings';
import { APP_RATING_SORT_OPTIONS } from '../constants/constants';

export const currentAppRating = (state = 0, { type, payload }) => {
    let newState = state;

    if (type === SET_SELECTED_APP_RATING) {
        newState = payload;
    }

    return newState;
};

export const reviewFromUser = (state = {}, { type, payload }) => {
    let newState = {};

    Object.assign(newState, state);

    switch (type) {
        case CLEAR_REVIEW_FROM_USER:
            newState = {};
            break;
        case SET_REVIEW_FROM_USER:
            newState = payload;
            break;
        default:
            return newState;
    }

    return newState;
};

export const appRatingsInitalState = {
    isLoading: false,
    pageNumber: 1,
    ratings: [],
    pagination: {},
    ratingFilter: 'all',
    sortOrder: APP_RATING_SORT_OPTIONS.MOST_RECENT,
};

export const appRatings = (
    state = appRatingsInitalState,
    { type, payload },
) => {
    let newState = {
        ...state,
    };
    const oldRatings = state && state.ratings ? state.ratings : [];
    const newRatings = payload && payload.ratings ? payload.ratings : [];
    const updateAppRatingReplies = () => {
        const { ratingId, ...ratingReply } = payload;
        const updatedRatingIndex = findIndex(
            state.ratings,
            (rating) => rating.id === ratingId,
        );
        const updatedRatings = [...state.ratings];

        updatedRatings[updatedRatingIndex] = {
            ...updatedRatings[updatedRatingIndex],
            replies: [ratingReply],
        };

        return updatedRatings;
    };

    const removeAppRatingReply = () => {
        const { ratingId, ratingReplyId } = payload;
        const updatedRatingIndex = findIndex(
            state.ratings,
            (rating) => rating.id === ratingId,
        );
        const updatedRatings = [...state.ratings];

        updatedRatings[updatedRatingIndex] = {
            ...updatedRatings[updatedRatingIndex],
            replies: updatedRatings[updatedRatingIndex].replies.filter(
                (reply) => reply.id !== ratingReplyId,
            ),
        };

        return updatedRatings;
    };

    switch (type) {
        case LOAD_RATINGS:
            newState = {
                ...state,
                ratings: oldRatings.concat(newRatings),
                pagination: payload && payload.pagination,
            };
            break;
        case CLEAR_RATINGS:
            newState = {
                ...state,
                ratings: [],
                pagination: {},
            };
            break;
        case SET_IS_LOADING:
            newState = {
                ...state,
                isLoading: payload,
            };
            break;
        case SET_PAGE_NUMBER:
            newState = {
                ...state,
                pageNumber: payload,
            };
            break;
        case SET_RATING_FILTER:
            newState = {
                ...state,
                ratingFilter: payload,
            };
            break;
        case SET_RATING_SORT_ORDER:
            newState = {
                ...state,
                sortOrder: payload,
            };
            break;
        case REMOVE_REVIEW_REPLY:
            newState = {
                ...state,
                ratings: removeAppRatingReply(),
            };
            break;
        case UPDATE_REVIEW_REPLY:
            newState = {
                ...state,
                ratings: updateAppRatingReplies(),
            };
            break;
        default:
            return state;
    }

    return newState;
};
