import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@eventbrite/eds-card';
import { Link } from '@eventbrite/eds-link';
import { Icon } from '@eventbrite/eds-icon';
import { CheckChunky } from '@eventbrite/eds-iconography';

import CTAButton from './CTAButton';
import {
    APP_DETAILS_SHAPE,
    APP_EXTENSION_SHAPE,
    APP_LOCALIZATION_SHAPE,
    MINIMUM_NUMBER_OF_APP_RATINGS,
} from '../../constants/constants';
import { gettext } from '@eventbrite/i18n';
import RatingStars from '../ratingStars/RatingStars';
import './appTitleCard.scss';

const _transformAppName = (appName) => {
    if (appName && appName.length > 30) {
        return `${appName.substr(0, 30)}...`;
    }

    return appName;
};

const _renderRatingInfo = (totalReviews, rating) => {
    let ratingInfoComponent = (
        <div className="eds-align--center eds-l-mar-left-4 eds-l-sn-mar-bot-4">
            <RatingStars
                isDisabled={totalReviews < MINIMUM_NUMBER_OF_APP_RATINGS}
                rating={Number(rating)}
                size="xsmall"
            />
            <span className="eds-l-mar-left-2 eds-text-bs">
                {gettext('Not enough ratings')}
            </span>
        </div>
    );

    if (totalReviews >= MINIMUM_NUMBER_OF_APP_RATINGS) {
        ratingInfoComponent = (
            <div className="eds-align--center eds-l-sn-mar-bot-4">
                <div className="eds-l-mar-left-4 eds-l-mar-right-2">
                    <span className="eds-text-bl">{rating}</span>
                </div>
                <RatingStars
                    isDisabled={totalReviews < MINIMUM_NUMBER_OF_APP_RATINGS}
                    rating={Number(rating)}
                    size="xsmall"
                />
                <Link to="#rating-overview">
                    <span className="eds-l-mar-left-2 eds-text-bm">
                        ({totalReviews})
                    </span>
                </Link>
            </div>
        );
    }

    return ratingInfoComponent;
};

const AppTitleCard = ({
    categories,
    details,
    extension,
    localization,
    logoUrl,
    name,
    rating,
    totalReviews,
}) => (
    <Card style="simple">
        <div className="app-title-card eds-l-mar-top-6 eds-l-mar-bot-8 eds-l-mar-hor-6 eds-l-sn-mar-hor-4">
            <div className="app-logo">
                <img alt={`${name} logo`} src={logoUrl} />
                {details && details.isInstalled && (
                    <span className="app-logo__installed-icon">
                        <Icon
                            color="white"
                            type={<CheckChunky />}
                            size="small"
                        />
                    </span>
                )}
            </div>
            <div className="header-content eds-l-sn-mar-top-2 eds-l-mar-left-6 eds-l-sn-mar-left-0">
                <span className="eds-show-up-sm">
                    {categories && categories[0] && (
                        <Link to={`/apps/categories/${categories[0].slug}/`}>
                            {categories[0].name_localized || categories[0].name}
                        </Link>
                    )}
                </span>
                <h1 className="eds-text-hl eds-l-mar-bot-1">
                    {_transformAppName(name)}
                </h1>
                <p className="eds-text-bl eds-l-sn-mar-top-1 eds-l-mar-bot-6 eds-l-sn-mar-bot-4">
                    {localization && localization.shortDescription}
                </p>
                <div className="cta-button-wrapper">
                    {extension && localization && (
                        <CTAButton
                            clientId={extension.clientId}
                            isInstalled={details && details.isInstalled}
                            getStartedUrl={localization.getStartedUrl}
                            requiresOauth={extension.requiresOauth}
                            slug={extension.slug}
                            appName={name}
                        />
                    )}
                    {_renderRatingInfo(totalReviews, rating)}
                </div>
            </div>
        </div>
    </Card>
);

AppTitleCard.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object),
    details: APP_DETAILS_SHAPE,
    extension: APP_EXTENSION_SHAPE,
    localization: APP_LOCALIZATION_SHAPE,
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    rating: PropTypes.string,
    totalReviews: PropTypes.number,
};

export default AppTitleCard;
