import { connect } from 'react-redux';

import SpotlightSection from '../components/SpotlightSection/SpotlightSection';

const _mapStateToProps = ({ appsListMap, hasFailed }) => ({
    appsListMap,
    hasFailed,
});

export default connect(_mapStateToProps)(SpotlightSection);
