import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { gettext } from '@eventbrite/i18n';

import { Pagination } from '@eventbrite/eds-pagination';

export default class AppPagination extends PureComponent {
    static propTypes = {
        appsByPage: PropTypes.arrayOf(PropTypes.shape).isRequired,
        onPageSelection: PropTypes.func.isRequired,
        totalApps: PropTypes.number.isRequired,
        pageNumber: PropTypes.number.isRequired,
    };

    constructor() {
        super();

        this.state = {
            pagination: {
                pageNumber: 1,
            },
        };
    }

    render() {
        const { appsByPage, onPageSelection, totalApps, pageNumber } =
            this.props;

        const currentlyViewableApps =
            appsByPage.length > 0 ? appsByPage[pageNumber - 1] : [];

        const resultsShown =
            currentlyViewableApps.length === 15 ? pageNumber * 15 : totalApps;
        const pageCount = appsByPage.length;
        const pagination = (
            <Pagination
                pageNumber={pageNumber}
                pageCount={pageCount}
                size="large"
                onSelected={onPageSelection}
            />
        );
        const renderPagination = totalApps > 15 ? pagination : <div />;

        return (
            <div className="eds-align--space-between eds-l-mar-top-10">
                {renderPagination}
                <p>
                    {gettext('Results %(resultsShown)s of %(totalApps)s', {
                        resultsShown,
                        totalApps,
                    })}
                </p>
            </div>
        );
    }
}
