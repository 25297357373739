import { connect } from 'react-redux';
import { deleteReviewReply } from '../actions/Ratings';
import ReviewReplyCard from '../components/reviewReplyCard/ReviewReplyCard';

const _mapStateToProps = ({ currentApp }) => ({
    logoUrl: currentApp && currentApp.logoUrl,
    name: currentApp && currentApp.name,
    shouldShowMoreActionsMenu:
        (currentApp &&
            currentApp.permissions &&
            currentApp.permissions.canReplyRating) ||
        false,
});

const _mapPropsToDispatch = {
    deleteReviewReply,
};

export default connect(_mapStateToProps, _mapPropsToDispatch)(ReviewReplyCard);
