import { connect } from 'react-redux';
import chunk from 'lodash/chunk';

import RatingsListWithControls from '../components/ratingsList/RatingsListWithControls';
import {
    clearRatings,
    fetchRatings,
    setPageNumber,
    setRatingFilter,
    setRatingSortOrder,
} from '../actions/Ratings';
import { APP_RATINGS_PER_PAGE } from '../constants/constants';

const _mapStateToProps = ({ appRatings, currentApp }) => ({
    isLoading: appRatings && appRatings.isLoading,
    pageNumber: appRatings && appRatings.pageNumber,
    ratings: chunk(appRatings.ratings, APP_RATINGS_PER_PAGE),
    ratingFilter: appRatings && appRatings.ratingFilter,
    sortOrder: appRatings && appRatings.sortOrder,
    canReplyToRatings:
        (currentApp &&
            currentApp.permissions &&
            currentApp.permissions.canReplyRating) ||
        false,
});

const _mapPropsToDispatch = {
    clearRatings,
    fetchRatings,
    setPageNumber,
    setRatingFilter,
    setRatingSortOrder,
};

export default connect(
    _mapStateToProps,
    _mapPropsToDispatch,
)(RatingsListWithControls);
