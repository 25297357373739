import * as React from 'react';

const RecommendedSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 292 230" {...props}>
        <g fillRule="nonzero" fill="none">
            <path
                d="M80.679 71.346C96.009 29.702 136.036 0 183 0c60.199 0 109 48.801 109 109s-48.801 109-109 109c-22.493 0-43.395-6.813-60.755-18.489C109.273 211.015 92.202 218 73.5 218 32.907 218 0 185.093 0 144.5S32.907 71 73.5 71c2.422 0 4.817.117 7.179.346z"
                fill="#82D2DC"
                opacity={0.3}
            />
            <path
                d="M188.142 34c-19.189 0-36.788 8.95-48.142 23.679C128.646 42.95 111.047 34 91.858 34 58.307 34 31 61.36 31 95.01c0 15.804 6.018 30.815 16.974 42.258l85.838 86.5L140 230l6.188-6.231 84.191-84.858C242.301 127.411 249 111.777 249 95.009 249 61.361 221.693 34 188.142 34z"
                fill="#F05537"
            />
        </g>
    </svg>
);

RecommendedSvg.displayName = 'RecommendedSvg';
export default RecommendedSvg;
