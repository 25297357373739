import React from 'react';
import PropTypes from 'prop-types';

const ChartBar = ({ value }) => (
    <div className="eds-l-mar-hor-2">
        <div
            className="chart-bar__container"
            role="progressbar"
            aria-valuenow={value}
            aria-valuemin="0"
            aria-valuemax="100"
        >
            <div className="chart-bar__fill" style={{ width: `${value}%` }} />
        </div>
        <progress
            className="eds-is-hidden-accessible"
            value={value}
            max="100"
        />
    </div>
);

ChartBar.propTypes = {
    value: PropTypes.number.isRequired,
};

export default ChartBar;
