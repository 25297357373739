import React from 'react';
import { Route, IndexRoute } from 'react-router';
import isEmpty from 'lodash/isEmpty';

import { fetchApp } from './actions/Apps';
import { fetchCategories } from './actions/Categories';
import { isLangValid } from './utils/router';
import { getRenamedAppSlug } from './utils/appSlugMapHelper';

import ConnectedBase from './containers/ConnectedBase';
import ConnectedHomePage from './containers/ConnectedHomePage';
import ConnectedSearchPage from './containers/ConnectedSearchPage';
import ConnectedProductPage from './containers/ConnectedProductPage';
import ConnectedCategoryPage from './containers/ConnectedCategoryPage';
import ConnectedPicksPage from './containers/ConnectedPicksPage';
import ConnectedNotFound from './containers/ConnectedNotFound';

export const getRoutes = (appProps, store) => {
    const path = '/(:lang/)apps';
    const playground = '/admin/spa/playground/app_marketplace_spa/:version';

    const PageWithAppProps = (props) => (
        <ConnectedBase {...appProps} {...props} />
    );

    const NotFoundWithBasePage = (props) => (
        <ConnectedBase {...appProps} {...props}>
            <ConnectedNotFound />
        </ConnectedBase>
    );

    const handleProductPageOnEnter = (nextState, replace, callback) => {
        const appSlug = nextState.params.app_name;
        const fetchAppPromise = store.dispatch(
            fetchApp({
                'expand.app': 'details,extension,permissions,rating_summary',
                slug: appSlug,
            }),
        );

        fetchAppPromise.then(
            ({ payload }) => {
                if (!payload || isEmpty(payload)) {
                    const newAppSlug = getRenamedAppSlug(appSlug);

                    if (newAppSlug) {
                        replace(`/apps/${newAppSlug}/`);
                    }
                }

                callback();
            },
            () => {
                replace('/apps');
                callback();
            },
        );
    };

    const handleCategoriesPageOnEnter = (nextState, replace, callback) => {
        const state = store.getState();

        if (!state.categoriesFetching) {
            const fetchCategoriesPromise = store.dispatch(fetchCategories());

            fetchCategoriesPromise.then(
                () => {
                    callback();
                },
                () => {
                    replace('/apps');
                    callback();
                },
            );
        } else {
            callback();
        }
    };

    const forceTrailingSlash = (nextState, replace) => {
        const nextPathName = nextState.location.pathname;

        if (nextPathName.slice(-1) !== '/') {
            replace({
                ...nextState.location,
                pathname: `${nextPathName}/`,
            });
        }
    };

    const forceTrailingSlashOnChange = (prevState, nextState, replace) => {
        forceTrailingSlash(nextState, replace);
    };

    const handleRouteOnEnter = (nextState, replace) => {
        forceTrailingSlash(nextState, replace);
    };

    const getMainRouteComponent = (nextState, callback) => {
        if (nextState.params && nextState.params.lang) {
            const lang = nextState.params.lang;

            if (!isLangValid(appProps, lang)) {
                return callback(null, NotFoundWithBasePage);
            }
        }

        return callback(null, PageWithAppProps);
    };

    return (
        <Route
            onEnter={handleRouteOnEnter}
            onChange={forceTrailingSlashOnChange}
        >
            <Route path={path} getComponent={getMainRouteComponent}>
                <IndexRoute component={ConnectedHomePage} />
                <Route path="/" component={ConnectedHomePage} />
                <Route path="search" component={ConnectedSearchPage} />
                <Route path="popular" component={ConnectedPicksPage} />
                <Route path="recommended" component={ConnectedPicksPage} />
                <Route
                    path=":app_name"
                    component={ConnectedProductPage}
                    onEnter={handleProductPageOnEnter}
                />
                <Route
                    path="categories/:slug"
                    component={ConnectedCategoryPage}
                    onEnter={handleCategoriesPageOnEnter}
                />
                <Route path="*" component={ConnectedNotFound} />
            </Route>
            <Route path={playground} component={PageWithAppProps}>
                <IndexRoute component={ConnectedHomePage} />
                <Route path="search" component={ConnectedSearchPage} />
                <Route path="popular" component={ConnectedPicksPage} />
                <Route path="recommended" component={ConnectedPicksPage} />
                <Route path=":app_name" component={ConnectedProductPage} />
                <Route
                    path="categories/:slug"
                    component={ConnectedSearchPage}
                />
                <Route path="*" component={ConnectedNotFound} />
            </Route>
        </Route>
    );
};

export default getRoutes;
