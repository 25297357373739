import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import { gettext } from '@eventbrite/i18n';
import InternalLink from '../InternalLink/InternalLink';
import { isMediumDown } from '@eventbrite/eds-utils';

import { BASE_APPS_URL } from '../../constants/constants';

import './CategoriesFilter.scss';

export default class CategoriesFilter extends PureComponent {
    static propTypes = {
        fetchCategories: PropTypes.func.isRequired,
        categories: PropTypes.arrayOf(PropTypes.object).isRequired,
        selectedCategory: PropTypes.number,
        selectCategory: PropTypes.func.isRequired,
        categoriesFetching: PropTypes.bool,
    };

    constructor() {
        super();

        this.state = {
            localCategories: [],
        };
    }

    componentDidMount() {
        this.initiliazeLocalCategories();
    }

    componentDidUpdate() {
        const { router, categories } = this.props;

        const pathName = router.getCurrentLocation().pathname;

        if (this.state.localCategories.length === 0 && categories.length > 0) {
            this.initiliazeLocalCategories();
        }

        this.findAndSelectCategory(pathName);
    }

    linkClickHandler(index) {
        this.props.selectCategory(index);
    }

    initiliazeLocalCategories() {
        const picksLinks = [
            {
                name: gettext('Popular'),
                url: 'popular',
            },
            {
                name: gettext('Recommended'),
                url: 'recommended',
            },
        ];

        const { categories } = this.props;
        let localCategories = [];

        if (categories.length > 0) {
            localCategories = [...picksLinks, ...sortBy(categories, 'name')];
        }

        this.setState({ localCategories });
    }

    createInternalLinkList(categories) {
        return categories.map((category, index) => {
            const to = category.url
                ? `${BASE_APPS_URL}${category.url}/`
                : `${BASE_APPS_URL}categories/${category.slug}/`;

            return (
                <li className="categories-filter--container__link" key={index}>
                    <InternalLink
                        to={to}
                        onClick={this.linkClickHandler.bind(this, index)}
                        style={
                            this.props.selectedCategory === index
                                ? 'inverse'
                                : 'neutral'
                        }
                    >
                        {category.name_localized || category.name}
                    </InternalLink>
                </li>
            );
        });
    }

    createTabLinkList(categories) {
        const containerClassName = 'categories-filter--container__tab-link';

        return categories.map((category, index) => {
            const to = category.url
                ? `${BASE_APPS_URL}${category.url}/`
                : `${BASE_APPS_URL}categories/${category.slug}/`;

            return (
                <div
                    className={
                        this.props.selectedCategory === index
                            ? `selected ${containerClassName}`
                            : containerClassName
                    }
                    key={index}
                >
                    <InternalLink
                        to={to}
                        onClick={this.linkClickHandler.bind(this, index)}
                        style={
                            this.props.selectedCategory === index
                                ? 'tabSelected'
                                : 'tab'
                        }
                    >
                        {category.name}
                    </InternalLink>
                </div>
            );
        });
    }

    isCategoryPages(pathName) {
        const { params } = this.props;

        return (
            pathName === '/apps/popular' ||
            pathName === '/apps/popular/' ||
            pathName === '/apps/recommended' ||
            pathName === '/apps/recommended/' ||
            (pathName.includes('/categories/') &&
                !isEmpty(params) &&
                params.slug)
        );
    }

    getSlug() {
        const { params } = this.props;

        return (!isEmpty(params) && params.slug) || null;
    }

    findAndSelectCategory(pathName) {
        const { localCategories } = this.state;

        if (localCategories.length > 0 && this.isCategoryPages(pathName)) {
            const slug = this.getSlug();
            const categoryIndex = localCategories.findIndex(
                (category) =>
                    (category.slug && slug === category.slug) ||
                    (category.url && pathName.includes(category.url)),
            );

            if (categoryIndex !== -1) {
                this.props.selectCategory(categoryIndex);
            }
        }
    }

    render() {
        const { localCategories } = this.state;

        let categoriesList = (
            <ul className="categories-filter--container eds-l-pad-all-6">
                {this.createInternalLinkList(localCategories)}
            </ul>
        );

        if (isMediumDown()) {
            categoriesList = (
                <div className="categories-filter--container tab-link-container">
                    {this.createTabLinkList(localCategories)}
                </div>
            );
        }

        return (
            <div className="categories-filter">
                {localCategories.length > 0 ? categoriesList : null}
            </div>
        );
    }
}
