export const SET_LOADING = 'SET_LOADING';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const IS_FETCHING = 'IS_FETCHING';
export const HAS_FAILED = 'HAS_FAILED';
export const SET_USER_LOCALE = 'SET_USER_LOCALE';
export const SET_IS_USER_SIGNED_IN = 'SET_IS_USER_SIGNED_IN';

export const setLoading = (isLoading) => ({
    type: SET_LOADING,
    payload: isLoading,
});

export const selectCategory = (categoryId) => ({
    type: SELECT_CATEGORY,
    payload: categoryId,
});

export const setIsFetching = (isFetchingObj) => ({
    type: IS_FETCHING,
    payload: isFetchingObj,
});

export const setHasFailed = (hasFailed) => ({
    type: HAS_FAILED,
    payload: hasFailed,
});

export const setUserLocale = (locale) => ({
    type: SET_USER_LOCALE,
    payload: locale,
});

export const setIsUserSignedIn = (isSignedIn) => ({
    type: SET_IS_USER_SIGNED_IN,
    payload: isSignedIn,
});
