const formatLocalesListFromProps = (localesList) =>
    localesList.map((localeObj) => localeObj.locale.toLowerCase());

export const isLangValid = (appProps, lang) => {
    if (
        appProps.env &&
        appProps.env.locales &&
        appProps.env.locales.length > 0
    ) {
        const locales = formatLocalesListFromProps(appProps.env.locales);

        const isLangInLocalesList = locales.indexOf(lang.toLowerCase()) !== -1;

        return isLangInLocalesList;
    }

    return false;
};
