import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TabItem from './_internal/TabItem';
import HotTabs from './_internal/_HotTabs';
import { getTabId, getPanelId } from './util';
import {
    ITEMS_PROP_TYPE,
    FORMAT_TAB,
    FORMAT_PILL,
    DIRECTION_HORIZONTAL,
    DIRECTION_VERTICAL,
    TAB_DIRECTION_PROP_TYPE,
    TAB_FORMAT_PROP_TYPE,
} from './constants';
import {
    focusNewPosition,
    filterNonEmptyItems,
} from '@eventbrite/eds-hot-keys';
import './tabs.scss';
import { SelectField, STYLE_BASIC } from '@eventbrite/eds-input-field';
import { Divider } from '@eventbrite/eds-divider';
import classNames from 'classnames';

const TabContent = ({ focusPosition, items, shouldKeepContentMounted }) => {
    const { value, content } = items[focusPosition];

    if (shouldKeepContentMounted) {
        return (
            <>
                {items.map(({ value, content }, index) => {
                    const isVisible = index === focusPosition;

                    return (
                        <div
                            id={getPanelId(value)}
                            key={getPanelId(value)}
                            className={`${
                                isVisible ? 'eds-tabs__content' : 'eds-hide'
                            }`}
                            role="tabpanel"
                            aria-labelledby={getTabId(value)}
                        >
                            {content}
                        </div>
                    );
                })}
            </>
        );
    }

    return (
        <div
            id={getPanelId(value)}
            className="eds-tabs__content"
            role="tabpanel"
            aria-labelledby={getTabId(value)}
        >
            {content}
        </div>
    );
};

class Tabs extends PureComponent {
    static propTypes = {
        /**
         * items: List of items to display in the tab list.
         * Accepts value, displayName, and content
         */
        items: ITEMS_PROP_TYPE.isRequired,
        /**
         * onTabChange: Callback function to be invoked on tab change
         */
        onTabChange: PropTypes.func,
        /**
         * initialSelectedTab: Initial selected tab value
         */
        initialSelectedTab: PropTypes.number,
        /**
         * Whether the navigation for items should be un-wrapped (i.e. no first to last, last to first)
         */
        preventFocusWrap: PropTypes.bool,
        /**
         * List of values for disabled tabs
         */
        disabledList: PropTypes.arrayOf(PropTypes.number),
        /**
         * Keeps the undisplayed content mounted, just not visible
         */
        shouldKeepContentMounted: PropTypes.bool,
        /**
         * Format of tabs: "tab" or "pill"
         */
        tabFormat: TAB_FORMAT_PROP_TYPE.isRequired,
        /**
         * Direction of tabs: "horizontal" or "vertical"
         */
        tabDirection: TAB_DIRECTION_PROP_TYPE.isRequired,
        /**
         * HOF to change the focus position.
         * This gives the parent the authority to set the tab on which the focus should go.
         */
        getSetFocus: PropTypes.func,
        /**
         * Class names for the list of tabs
         */
        __tabListClassName: PropTypes.string,
    };

    static defaultProps = {
        initialSelectedTab: 0,
        preventFocusWrap: false,
        disabledList: [],
        tabFormat: FORMAT_TAB,
        tabDirection: DIRECTION_HORIZONTAL,
        getSetFocus: undefined,
    };

    constructor(props) {
        super(props);

        const { initialSelectedTab } = props;

        this.state = {
            focusPosition: initialSelectedTab,
        };
    }

    componentDidMount() {
        if (this.props.getSetFocus) {
            const setFocus = (idx) => {
                if (typeof idx === 'number') {
                    this.setState({ focusPosition: idx || 0 });
                }
            };

            this.props.getSetFocus(setFocus);
        }
    }

    _changeFocusedTab(e, direction) {
        const { items, preventFocusWrap } = this.props;
        const { focusPosition } = this.state;
        const nonEmptyItems = filterNonEmptyItems(items);
        const boundFocusNewPosition = focusNewPosition.bind(
            null,
            nonEmptyItems,
            focusPosition,
            e,
            preventFocusWrap,
        );

        this.setState(boundFocusNewPosition(direction));
    }

    _handleSelected(newFocusPosition, value) {
        const { onTabChange } = this.props;

        this.setState({ focusPosition: newFocusPosition });

        if (onTabChange) {
            onTabChange(newFocusPosition);
        }
    }

    _handleSelectChange(value, index) {
        const { onTabChange } = this.props;

        this.setState({ focusPosition: +index });

        if (onTabChange) {
            onTabChange(+index);
        }
    }

    _getTabItems(items, focusPosition, disabledList) {
        const tabItems = items.map(({ value, displayName, icon }, index) => (
            <TabItem
                key={value}
                value={value}
                icon={icon}
                onSelected={this._handleSelected.bind(this, index, value)}
                displayName={displayName}
                isSelected={index === focusPosition}
                isDisabled={disabledList.includes(value)}
            />
        ));

        return tabItems;
    }

    render() {
        const {
            items,
            disabledList,
            shouldKeepContentMounted,
            tabFormat,
            tabDirection,
            __tabListClassName,
        } = this.props;
        const { focusPosition } = this.state;
        const tabItems = this._getTabItems(items, focusPosition, disabledList);
        const isVertical = tabDirection === DIRECTION_VERTICAL;
        const tabClasses = classNames('eds-tabs', {
            'eds-tabs__flex': isVertical,
        });

        return (
            <div className={tabClasses}>
                <HotTabs
                    onChangeTab={this._changeFocusedTab.bind(this)}
                    tabItems={tabItems}
                    tabFormat={tabFormat}
                    tabDirection={tabDirection}
                    selectedTab={focusPosition}
                    rawItems={items}
                    __containerClassName={__tabListClassName}
                />
                {isVertical ? (
                    <div id="eds-mobile-select-menu">
                        <SelectField
                            id="eds-select"
                            placeholder=""
                            label=""
                            style={STYLE_BASIC}
                            values={items.map((item, idx) => ({
                                value: idx.toString(),
                                display: item.displayName,
                            }))}
                            value={focusPosition.toString()}
                            onChange={this._handleSelectChange.bind(this)}
                        />
                        <Divider />
                    </div>
                ) : null}
                <TabContent
                    focusPosition={focusPosition}
                    items={items}
                    shouldKeepContentMounted={shouldKeepContentMounted}
                />
            </div>
        );
    }
}

export default Tabs;
