import { push } from 'react-router-redux';
import { BASE_APPS_URL } from '../constants/constants';
import * as JsSearch from 'js-search';

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const LOAD_FILTERED_APPS = 'LOAD_FILTERED_APPS';
export const LOAD_APPS_PAGINATION_OBJ = 'LOAD_APPS_PAGINATION_OBJ';

export const setSearchedTerm = (searchedTerm) => ({
    type: SET_SEARCH_TERM,
    payload: searchedTerm,
});
export const loadFilteredApps = (apps) => ({
    type: LOAD_FILTERED_APPS,
    payload: apps,
});
export const loadAppsPaginationObj = (pagination) => ({
    type: LOAD_APPS_PAGINATION_OBJ,
    payload: pagination,
});

export const setSearchView =
    (searchedTerm, currentLocationPath) => (dispatch) => {
        if (!currentLocationPath.endsWith('/search/')) {
            dispatch(push(`${BASE_APPS_URL}search/?q=${searchedTerm}`));
        }
    };

export const searchApps = (searchedTerm, apps) => (dispatch) => {
    const search = new JsSearch.Search('id');

    search.addIndex('name');
    search.addIndex('flatCategories');
    search.addIndex(['localization', 'description']);
    search.addIndex(['localization', 'short_description']);

    search.addDocuments(apps);

    const results = search.search(searchedTerm);

    return dispatch(loadFilteredApps(results));
};
