import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Search from '../components/Search/Search';
import { setSearchView, searchApps, setSearchedTerm } from '../actions/Search';

const _mapStateToProps = ({
    searchedTerm,
    appsListMap: { apps },
    filteredApps,
}) => ({
    searchedTerm,
    apps,
    filteredApps,
});

const _mapPropsToDispatch = {
    setSearchView,
    searchApps,
    setSearchedTerm,
};

export default withRouter(
    connect(_mapStateToProps, _mapPropsToDispatch)(Search),
);
