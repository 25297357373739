import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import chunk from 'lodash/chunk';
import { AppDiscoverySection } from '@eventbrite/app-discovery';
import { Layout } from '@eventbrite/eds-layout';
import MetaTags from 'react-meta-tags';

import Header from '../components/Header/Header';
import CategoriesExploreSection from '../containers/ConnectedCategoriesExploreSection';
import AppPagination from '../components/AppPagination/AppPagination';
import { gettext } from '@eventbrite/i18n';
import { MAX_APPS_TO_DISPLAY } from '../constants/constants';

import './SearchPage.scss';

export default class SearchPage extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        apps: PropTypes.array,
        // eslint-disable-next-line react/forbid-prop-types
        searchedTerm: PropTypes.object.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        filteredApps: PropTypes.array.isRequired,
        searchApps: PropTypes.func.isRequired,
    };

    constructor() {
        super();

        this.state = {
            pagination: {
                pageNumber: 1,
            },
        };
    }

    UNSAFE_componentWillMount() {
        this._setInternalLoading(true);
    }

    componentDidMount() {
        const { categoriesFetching, fetchCategories } = this.props;

        if (!categoriesFetching) {
            fetchCategories();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { apps, filteredApps, isLoading, searchedTerm } = nextProps;

        // Make sure to set loading to false if apps are loading on homepage prior to search
        if ((!isEmpty(apps) || !isEmpty(filteredApps)) && isLoading) {
            this._setInternalLoading(false);
        }
        // If search is made from homepage and apps are not yet loaded, search apps when they are loaded
        if (!isEmpty(apps) && apps !== this.props.apps) {
            this.props.searchApps(searchedTerm.newSearch, apps);
        }
        // Reset pagination when new search is made
        if (this.props.searchedTerm.newSearch !== searchedTerm.newSearch) {
            this.setState({ pagination: { pageNumber: 1 } });
        }
    }

    _handlePageSelection = (page) => {
        if (page !== this.state.pagination.pageNumber) {
            this.setState({ pagination: { pageNumber: page } });
        }
    };

    _setInternalLoading(isLoading) {
        this.props.setLoading(isLoading);
    }

    _getAppDisplay = () => {
        const { searchedTerm, filteredApps, apps } = this.props;

        let appsData = isEmpty(filteredApps) ? apps : filteredApps;
        const { pageNumber } = this.state.pagination;

        if (isEmpty(apps)) {
            appsData = [];
        }

        if (!isEmpty(searchedTerm.newSearch) && isEmpty(filteredApps)) {
            appsData = [];
        }
        const appsByPage = chunk(appsData, MAX_APPS_TO_DISPLAY);
        const currentlyViewableApps =
            appsByPage.length > 0 ? appsByPage[pageNumber - 1] : [];

        return (
            <div>
                <AppDiscoverySection
                    apps={currentlyViewableApps}
                    maxApps={MAX_APPS_TO_DISPLAY}
                />
                <AppPagination
                    appsByPage={appsByPage}
                    onPageSelection={this._handlePageSelection}
                    totalApps={appsData.length}
                    pageNumber={pageNumber}
                />
            </div>
        );
    };

    render() {
        const { searchedTerm } = this.props;

        //eslint-disable-next-line quotes
        const headlineMessage = gettext("Search results for '%(term)s'", {
            term: searchedTerm.newSearch || '',
        });

        // Translators: 'Eventbrite App Marketplace' is the marketplace name and shouldn't be translated
        const pageTitle = gettext('Search | Eventbrite App Marketplace');

        return (
            <Layout maxWidth="large" hasHorizontalGutters={true}>
                <MetaTags>
                    <title>{pageTitle}</title>
                    <meta
                        name="description"
                        //eslint-disable-next-line quotes
                        content={gettext(
                            "Every event is unique, find the app that's right for you and grow your event your way",
                        )}
                    />
                </MetaTags>
                <div className="eds-l-mar-bot-8">
                    <Header headline={headlineMessage} align="center" />
                    <div className="search-apps__display eds-l-mar-bot-10 eds-l-mar-top-6">
                        {this._getAppDisplay()}
                    </div>
                    <div className="eds-l-mar-bot-12">
                        <CategoriesExploreSection />
                    </div>
                </div>
            </Layout>
        );
    }
}
