import * as React from 'react';

const RefreshChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="refresh-chunky_svg__eds-icon--refresh-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="refresh-chunky_svg__eds-icon--refresh-chunky_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.5 8c-1.1-1.2-2.7-2-4.5-2-3.3 0-6 2.7-6 6s2.7 6 6 6c1.3 0 2.6-.5 3.6-1.2l1.2 1.6c-1.3 1-3 1.6-4.8 1.6-4.4 0-8-3.6-8-8s3.6-8 8-8c2.4 0 4.5 1.1 6 2.7V4h2v6h-6V8h2.5z"
        />
    </svg>
);

RefreshChunkySvg.displayName = 'RefreshChunkySvg';
export default RefreshChunkySvg;
