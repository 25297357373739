import * as React from 'react';

const WriteIllustrationSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 150 163" {...props}>
        <title>{'write-illustration'}</title>
        <g fill="none" fillRule="evenodd">
            <path
                d="M41.593 62.22C49.586 41.055 70.035 26 94 26c30.928 0 56 25.072 56 56s-25.072 56-56 56c-11.918 0-22.967-3.723-32.046-10.07A37.353 37.353 0 0137.5 137C16.79 137 0 120.21 0 99.5S16.79 62 37.5 62c1.383 0 2.749.075 4.093.22z"
                fill="#82D2DC"
                fillRule="nonzero"
                opacity={0.3}
            />
            <path
                fill="#1A0E2F"
                d="M36.148 139.403l4.512 21.93 20.06-9.945-7.082-16.663z"
            />
            <path
                fill="#F05537"
                d="M36.148 139.403L101.027 6.382l24.571 11.984L60.72 151.388z"
            />
            <path
                fill="#1A0E2F"
                d="M103.838.617l-5.26 10.786 24.572 11.984 5.26-10.785z"
            />
            <path
                d="M33.374 162H1.266a.5.5 0 000 1h32.108a.5.5 0 000-1z"
                fill="#1A0E2F"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

WriteIllustrationSvg.displayName = 'WriteIllustrationSvg';
export default WriteIllustrationSvg;
