/* eslint-disable react/no-danger */
import React, { PureComponent } from 'react';
import { Carousel } from '@eventbrite/eds-carousel';
import { Divider } from '@eventbrite/eds-divider';
import { Layout } from '@eventbrite/eds-layout';
import { Link } from '@eventbrite/eds-link';
import { gettext } from '@eventbrite/i18n';
import MetaTags from 'react-meta-tags';
import { Button } from '@eventbrite/eds-button';
import eq from 'lodash/eq';

import AppDescription from '../components/AppDescription/AppDescription';
import NameBadgesBanner from '../components/NameBadgesBanner/NameBadgesBanner';
import AppTitleCard from '../components/appTitleCard/AppTitleCard';
import NotFound from '../containers/ConnectedNotFound';
import MessageModal from '../components/modals/MessageModal';
import OneClickRatingCTA from '../containers/ConnectedOneClickRating';
import AfterSubmitMessageModalContent from '../components/modals/AfterSubmitMessageModalContent';
import ReviewModal from '../containers/ConnectedReviewModal';
import each from 'lodash/each';
import {
    MINIMUM_NUMBER_OF_APP_RATINGS,
    REVIEW_SUBMIT_ERRORS,
} from '../constants/constants';
import ConnectedRatingsOverview from '../containers/ConnectedRatingsOverview';
import ConnectedRatingsList from '../containers/ConnectedRatingsList';

export default class AppProductPage extends PureComponent {
    constructor() {
        super();

        this.state = {
            reviewModalShown: false,
            reviewMessageModalShown: false,
            reviewSavedSuccessfully: false,
            reviewFailureError: null,
        };
    }

    componentDidMount() {
        const { router } = this.props;

        const locationQuery = router.location.query;

        const removeOpenReviewModalParam = () =>
            router.replace(router.getCurrentLocation().pathname);

        if (eq(locationQuery.open_review_modal, 'true')) {
            this._handleAddReview(null, null);
            removeOpenReviewModalParam();
        }
    }

    componentWillUnmount() {
        this.props.clearReviewFromUser();
    }

    _handleFetchReviewsForUser = () => {
        const { details, fetchAppRatingsByUser, id, user } = this.props;

        if (details && details.isInstalled) {
            return fetchAppRatingsByUser(id, user && user.publicId)
                .then((response) => Promise.resolve(response))
                .catch((e) => Promise.reject(e));
        }

        return Promise.resolve();
    };

    _handleAddReview = (event, nameOfButton = 'Rate') => {
        const { details, name } = this.props;

        if (details && details.isInstalled) {
            this.setState({ reviewModalShown: true });
        } else {
            this.setState({ reviewMessageModalShown: true });
        }
    };

    _handleOneClickReview = () => {
        this.setState({ reviewModalShown: true });
    };

    _handleCloseReviewModal = () => {
        this.setState({ reviewModalShown: false });
    };

    _handleCloseMessageModal = () => {
        this.setState({ reviewMessageModalShown: false });
    };

    _handleReviewSavedSuccessfully = () => {
        const { clearRatings, fetchRatings, id } = this.props;

        clearRatings();
        fetchRatings(id);
        this.setState({
            reviewModalShown: false,
            reviewSavedSuccessfully: true,
            reviewFailureError: null,
            reviewMessageModalShown: true,
        });
    };

    _handleReviewSaveFailed = (parsedError) => {
        let reviewFailureError = REVIEW_SUBMIT_ERRORS.UNKNOWN;

        if (parsedError && parsedError.error) {
            if (parsedError.error === REVIEW_SUBMIT_ERRORS.ARGUMENTS_ERROR) {
                each(parsedError.argumentErrors, (value) => {
                    if (value === REVIEW_SUBMIT_ERRORS.APP_NOT_INSTALLED) {
                        reviewFailureError =
                            REVIEW_SUBMIT_ERRORS.APP_NOT_INSTALLED;
                        return;
                    }
                });
            } else {
                reviewFailureError = parsedError.error;
            }
        }
        this.setState({
            reviewModalShown: false,
            reviewSavedSuccessfully: false,
            reviewFailureError,
            reviewMessageModalShown: true,
        });
    };

    render() {
        const {
            appRatingSummary,
            bannerUrl,
            categories,
            details,
            extension,
            id,
            localization,
            logoUrl,
            name,
            slug,
            location,
        } = this.props;

        const AppBanner = () => (
            <div
                className="app-banner eds-align--center"
                style={{
                    height: '233px',
                    background: `#EEEDF2 url(${bannerUrl}) no-repeat top center`,
                    backgroundSize: 'cover',
                }}
            />
        );

        const appScreenshots = (screenShots) => {
            if (screenShots && screenShots.length) {
                return screenShots.map((screenShot) => (
                    <div
                        className="screenshots eds-g-cell eds-g-cell-1-1"
                        key={screenShot.id}
                    >
                        <img
                            className="eds-max-img"
                            alt={`${name} screenshot`}
                            src={screenShot.imageUrl}
                        />
                    </div>
                ));
            }
            return <div />;
        };

        const resourceSection = (resourceLinks, contactInfo) => (
            <div className="eds-g-cell eds-g-cell-12-12 eds-g-cell-sw-3-12 eds-g-offset-sw-1-12">
                <h3 className="eds-text-hs eds-l-mar-bot-2">
                    {gettext('Resources')}
                </h3>
                <ul className="eds-l-mar-bot-3">
                    {contactInfo.supportEmail && (
                        <li>
                            <Link to={`mailto:${contactInfo.supportEmail}`}>
                                {contactInfo.supportEmail}
                            </Link>
                        </li>
                    )}
                    {contactInfo.salesEmail && (
                        <li>
                            <Link to={`mailto:${contactInfo.salesEmail}`}>
                                {contactInfo.salesEmail}
                            </Link>
                        </li>
                    )}
                </ul>
                <ul>
                    {resourceLinks.map((resourceLink) => (
                        <li key={resourceLink.id}>
                            <Link to={resourceLink.url}>
                                {resourceLink.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        );

        const supportSection = (supportDescription) => (
            <div className="eds-g-cell eds-g-cell-12-12 eds-g-cell-sw-8-12">
                <h3 className="eds-text-hs eds-l-mar-bot-2">
                    {gettext('Support')}
                </h3>
                <p
                    className="support-description eds-text-bm eds-l-mar-top-2"
                    dangerouslySetInnerHTML={{
                        __html: supportDescription.toString(),
                    }}
                />
                <div className="eds-show-down-sm eds-l-mar-vert-6">
                    <Divider />
                </div>
            </div>
        );

        const ratingSectionNoOverview = (
            <div>
                <div
                    className="eds-l-mar-vert-10 eds-l-sw-mar-vert-6 eds-l-sm-mar-vert-6 eds-l-sn-mar-vert-6"
                    id="rating-overview"
                >
                    <Divider />
                </div>
                <section>
                    <h3 className="eds-text-hs eds-l-mar-bot-2">
                        {gettext('Review this app')}
                    </h3>
                    <p className="eds-text-bm eds-l-mar-bot-6">
                        {gettext(
                            'Your feedback helps our app partners and other event creators.',
                        )}
                    </p>
                    <Button style="fill" onClick={this._handleAddReview}>
                        {gettext('Rate')}
                    </Button>
                </section>
            </div>
        );

        const ratingSectionWithOverview = (
            <section>
                <div
                    className="eds-l-mar-vert-10 eds-l-sw-mar-vert-6 eds-l-sm-mar-vert-6 eds-l-sn-mar-vert-6"
                    id="rating-overview"
                >
                    <Divider />
                </div>
                <ConnectedRatingsOverview
                    {...appRatingSummary}
                    appId={id}
                    onAddReviewBtnClick={this._handleAddReview}
                />
            </section>
        );

        const renderRatingSection = () => {
            let ratingSectionComponent = null;

            if (
                appRatingSummary &&
                appRatingSummary.totalReviews >= MINIMUM_NUMBER_OF_APP_RATINGS
            ) {
                ratingSectionComponent = ratingSectionWithOverview;
            } else if (details) {
                ratingSectionComponent = ratingSectionNoOverview;
            }

            return ratingSectionComponent;
        };

        const renderReviewsSection = () => {
            const totalReviews =
                appRatingSummary && appRatingSummary.totalReviews;

            if (id && totalReviews >= MINIMUM_NUMBER_OF_APP_RATINGS) {
                return (
                    <section className="eds-l-mar-top-10 eds-l-sn-mar-top-4 eds-l-sm-mar-top-4">
                        <ConnectedRatingsList
                            appId={id}
                            stars={appRatingSummary && appRatingSummary.stars}
                            totalReviews={
                                appRatingSummary &&
                                appRatingSummary.totalReviews
                            }
                        />
                    </section>
                );
            }
            return false;
        };

        const showCarousel = Boolean(
            localization &&
                localization.screenshots &&
                localization.screenshots.length,
        );

        const {
            reviewFailureError,
            reviewSavedSuccessfully,
            reviewModalShown,
            reviewMessageModalShown,
        } = this.state;

        let reviewModalMessage = (
            <AfterSubmitMessageModalContent
                error={REVIEW_SUBMIT_ERRORS.APP_NOT_INSTALLED}
            />
        );

        if (reviewSavedSuccessfully) {
            reviewModalMessage = <AfterSubmitMessageModalContent />;
        } else if (reviewFailureError) {
            reviewModalMessage = (
                <AfterSubmitMessageModalContent error={reviewFailureError} />
            );
        }

        const pageTitle = name
            ? `${name} | Eventbrite App Marketplace`
            : 'Eventbrite App Marketplace';

        let oneClickRatingSection;

        if (details && details.isInstalled) {
            oneClickRatingSection = (
                <div className="eds-l-mar-vert-8">
                    <OneClickRatingCTA
                        onAddReviewBtnClick={this._handleOneClickReview}
                        onLoad={this._handleFetchReviewsForUser}
                    />
                </div>
            );
        }

        const productPageContent = (
            <div className="app-product-page">
                <NameBadgesBanner slug={slug} location={location} />
                <MetaTags>
                    <title>{pageTitle}</title>
                    <meta
                        name="description"
                        content={
                            (localization && localization.shortDescription) ||
                            name
                        }
                    />
                </MetaTags>

                <MessageModal
                    shown={reviewMessageModalShown}
                    onCloseAction={this._handleCloseMessageModal}
                >
                    {reviewModalMessage}
                </MessageModal>

                <ReviewModal
                    shown={reviewModalShown}
                    onCloseAction={this._handleCloseReviewModal}
                    onSaveSuccessAction={this._handleReviewSavedSuccessfully}
                    onSaveFailureAction={this._handleReviewSaveFailed}
                />

                <Layout
                    header={<AppBanner />}
                    hasHorizontalGutters={true}
                    peakSize="large"
                    maxWidth="large"
                >
                    <div className="content">
                        <AppTitleCard
                            categories={categories}
                            details={details}
                            extension={extension}
                            localization={localization}
                            logoUrl={logoUrl}
                            name={name}
                            {...appRatingSummary}
                        />
                        {oneClickRatingSection}
                        <div className="eds-l-mar-top-6 eds-l-sn-mar-top-4 eds-l-ln-mar-top-8 eds-l-lg-mar-top-8 eds-l-lw-mar-top-8 eds-l-mar-bot-4">
                            {showCarousel && (
                                <Carousel
                                    fullWidth={true}
                                    autoTransition={false}
                                    bottomNavigationType="numbers"
                                    alwaysShowBottomNavigation={true}
                                    alwaysShowSideNavigation={true}
                                    fixAspectRatio={true}
                                >
                                    {localization &&
                                        appScreenshots(
                                            localization.screenshots,
                                        )}
                                </Carousel>
                            )}
                        </div>

                        {localization && localization.description && (
                            <AppDescription
                                description={localization.description}
                            />
                        )}
                        {renderRatingSection()}
                        {renderReviewsSection()}
                        <div className="eds-l-mar-vert-10 eds-l-sw-mar-vert-6 eds-l-sm-mar-vert-6 eds-l-sn-mar-vert-6">
                            <Divider />
                        </div>
                        <section className="eds-g-grid">
                            <div className="eds-g-group">
                                {localization &&
                                    localization.supportDescription &&
                                    supportSection(
                                        localization.supportDescription,
                                    )}
                                {localization &&
                                    localization.resourceLinks &&
                                    resourceSection(
                                        localization.resourceLinks,
                                        localization.contactInfo,
                                    )}
                            </div>
                        </section>
                    </div>
                </Layout>
            </div>
        );

        const notFoundContent = <NotFound />;

        return <div>{!name ? notFoundContent : productPageContent}</div>;
    }
}
