import { connect } from 'react-redux';

import CategoriesExploreSection from '../components/CategoriesExploreSection/CategoriesExploreSection';

import { fetchCategories } from '../actions/Categories';

const _mapStateToProps = ({ categories, categoriesFetching }) => ({
    categories,
    categoriesFetching,
});

const _mapPropsToDispatch = {
    fetchCategories,
};

export default connect(
    _mapStateToProps,
    _mapPropsToDispatch,
)(CategoriesExploreSection);
