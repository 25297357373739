/* eslint-disable react/no-danger */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { gettext } from '@eventbrite/i18n';

import './AppDescription.scss';

export default class AppDescription extends PureComponent {
    static propTypes = {
        description: PropTypes.string.isRequired,
    };

    render() {
        const { description } = this.props;

        return (
            <article>
                <section>
                    <h3 className="eds-text-hs eds-l-mar-bot-4">
                        {gettext('Features')}
                    </h3>
                    <p
                        className="app-description-text eds-text-bm eds-l-mar-top-2"
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </section>
            </article>
        );
    }
}
