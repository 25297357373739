import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { filterByAppName } from '../../utils/filters';

import SpotlightSectionItem from './SpotlightSectionItem';

import { Tabs } from '@eventbrite/eds-tabs';
import { ProgressIndicator } from '@eventbrite/eds-progress-indicator';
import './SpotlightSection.scss';

export default class SpotlightSection extends PureComponent {
    static propTypes = {
        appsListMap: PropTypes.objectOf(PropTypes.shape).isRequired,
        hasFailed: PropTypes.objectOf(PropTypes.shape).isRequired,
    };

    searchSpotlightItemInApps(apps) {
        const spotlightApps = ['Emma', 'Lennd', 'Gift Up'];

        const filteredApps = [];

        spotlightApps.forEach((appName) => {
            const foundApp = filterByAppName(apps, appName);

            if (foundApp && foundApp[0]) {
                filteredApps.push(foundApp[0]);
            }
        });

        return filteredApps;
    }

    render() {
        let shouldShowSpotlight = true;
        let spotlightApps = [];
        let spotlightItems = [];
        let spotlightDisplay = (
            <div className="spotlight-section--loader eds-align--center">
                <ProgressIndicator
                    style="gradient"
                    size="large-chunky"
                    shape="circular"
                />
            </div>
        );

        const { appsListMap, hasFailed } = this.props;

        if (appsListMap.apps && appsListMap.apps.length > 0) {
            spotlightApps = this.searchSpotlightItemInApps(appsListMap.apps);

            if (spotlightApps.length > 0) {
                spotlightItems = spotlightApps.map((app, idx) => ({
                    value: idx,
                    displayName: app.name,
                    content: <SpotlightSectionItem app={app} />,
                }));

                spotlightDisplay = <Tabs items={spotlightItems} />;
            } else {
                shouldShowSpotlight = false;
                spotlightDisplay = null;
            }
        }

        if (
            hasFailed.apps ||
            (appsListMap.apps && appsListMap.apps.length === 0)
        ) {
            shouldShowSpotlight = false;
        }

        return (
            <div className="eds-g-grid">
                {shouldShowSpotlight ? (
                    <div className="eds-g-cell eds-g-cell-lg-12-12 eds-g-cell-12-12 spotlight-section--container">
                        <div className="spotlight-section--img-container">
                            <h2 className="eds-text-hs eds-text-weight--heavy spotlight-section--img-container__text">
                                Staff Picks
                            </h2>
                        </div>
                        <div className="spotlight-section--content eds-l-mar-left-6">
                            {spotlightDisplay}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}
