import * as React from 'react';

const MarketingPromotionSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 337 262" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M116.84 51.373c21.518-29.068 56.058-47.913 95-47.913 65.235 0 118.118 52.883 118.118 118.117 0 65.234-52.883 118.117-118.118 118.117-47.346 0-88.186-27.857-107.024-68.075-9.81 5.023-20.925 7.856-32.703 7.856-39.685 0-71.857-32.172-71.857-71.858 0-39.686 32.172-71.857 71.857-71.857 16.91 0 32.454 5.84 44.728 15.613z"
                fill="#82D2DC"
                fillRule="nonzero"
                opacity={0.3}
            />
            <path
                d="M142.108 140.865s8.532 22.885 9.317 30.306c.785 7.421-5.852 7.192-14.385 8.042l5.068-38.348z"
                fill="#1A0E2F"
                fillRule="nonzero"
            />
            <circle
                fill="#FFF"
                fillRule="nonzero"
                cx={241.885}
                cy={88.885}
                r={42.663}
            />
            <circle
                fill="#1A0E2F"
                fillRule="nonzero"
                cx={240.446}
                cy={89.048}
                r={28.573}
            />
            <path
                d="M233.385 1.923l-99.647 56.133-71.236 7.257c-11.386 1.17-19.678 11.333-18.537 22.722l4.218 41.552a20.727 20.727 0 0022.884 18.536l71.106-7.258 108.767 34.981a12.063 12.063 0 0015.137-12.88L250.712 11.501a12.03 12.03 0 00-17.327-9.579z"
                fill="#F05537"
                fillRule="nonzero"
            />
            <path
                d="M63.385 261.173c-1.798-16.836 33.836-86.635 30.927-115.47l47.796-4.838L139.1 253.49l-75.715 7.683z"
                fill="#F05537"
                fillRule="nonzero"
            />
            <circle
                fill="#FFF"
                fillRule="nonzero"
                cx={96.338}
                cy={80.385}
                r={5.067}
            />
            <circle
                fill="#FFF"
                fillRule="nonzero"
                cx={112.488}
                cy={78.717}
                r={5.067}
            />
            <circle
                fill="#82D2DC"
                fillRule="nonzero"
                cx={128.638}
                cy={77.083}
                r={5.067}
            />
            <path
                d="M232.894 14.837l14.581 143.453M97.875 95.292l2.256 22.395M113.992 93.658l2.289 22.394M130.142 91.99l2.256 22.427"
                stroke="#1A0E2F"
            />
            <path
                d="M298.835 68.615l30.011-16.64M301.744 97.32l32.725 10.297M302.692 82.738l33.38-3.4"
                stroke="#F05537"
            />
            <path
                d="M87.74 240.217l24.945-2.55M89.212 225.8l24.944-2.55M90.715 211.35l24.945-2.517"
                stroke="#FFF"
            />
            <path
                d="M142.108 140.865l-.523 19.19-47.306-6.538s.196-3.661 0-7.911l47.829-4.74z"
                fill="#F05537"
                fillRule="nonzero"
                opacity={0.5}
                style={{
                    mixBlendMode: 'multiply',
                }}
            />
        </g>
    </svg>
);

MarketingPromotionSvg.displayName = 'MarketingPromotionSvg';
export default MarketingPromotionSvg;
