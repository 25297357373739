import * as React from 'react';

const WebsiteSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 335 258" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M202.976 176.563c-20.22 28.622-53.564 47.312-91.272 47.312C50.027 223.875.029 173.876.029 112.2.03 50.524 50.027.525 111.704.525c57.53 0 104.9 43.503 111.008 99.407 11.803-9.899 27.02-15.86 43.63-15.86 37.521 0 67.938 30.418 67.938 67.939 0 37.521-30.417 67.938-67.938 67.938-28.863 0-53.523-18-63.366-43.386z"
                fill="#82D2DC"
                fillRule="nonzero"
                opacity={0.3}
            />
            <path
                fill="#F05537"
                fillRule="nonzero"
                d="M189.656 195.902H140.11l-11.189 58.17h71.925z"
            />
            <rect
                fill="#F05537"
                fillRule="nonzero"
                x={49.731}
                y={29.858}
                width={230.304}
                height={166.013}
                rx={5.79}
            />
            <rect
                fill="#FFF"
                fillRule="nonzero"
                x={58.973}
                y={38.791}
                width={211.789}
                height={148.178}
                rx={3.93}
            />
            <path
                fill="#F05537"
                fillRule="nonzero"
                d="M115.351 251.198h99.064v5.811h-99.064z"
            />
            <circle
                fill="#1A0E2F"
                fillRule="nonzero"
                cx={78.322}
                cy={56.378}
                r={3.585}
            />
            <circle
                fill="#82D2DC"
                fillRule="nonzero"
                cx={103.605}
                cy={56.378}
                r={3.585}
            />
            <circle
                fill="#F05537"
                fillRule="nonzero"
                cx={90.964}
                cy={56.378}
                r={3.585}
            />
            <path
                fill="#F05537"
                fillRule="nonzero"
                d="M74.705 81.724h181.158v63.333H74.705z"
            />
            <path
                d="M74.705 159.43h181.159M74.705 68.464h86.577M74.705 170.03h153.68"
                stroke="#1A0E2F"
            />
            <path
                fill="#F05537"
                fillRule="nonzero"
                opacity={0.5}
                style={{
                    mixBlendMode: 'multiply',
                }}
                d="M140.11 195.902h49.546l2.535 13.229-53.782-4.39z"
            />
        </g>
    </svg>
);

WebsiteSvg.displayName = 'WebsiteSvg';
export default WebsiteSvg;
