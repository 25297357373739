export const RECOMMENDATION_AFFILIATES_PAGE = 'affiliates';
export const RECOMMENDATION_APPS_PAGE = 'apps';
export const RECOMMENDATION_ATTENDEES_BADGE_PAGE = 'attendees_badge';
export const RECOMMENDATION_ATTENDEES_EMAIL_PAGE = 'attendees_email';
export const RECOMMENDATION_CREATEINVITE_PAGE = 'createinvite';
export const RECOMMENDATION_FACEBOOK_PUBLISH_PAGE = 'facebook_publish';
export const RECOMMENDATION_LINKS_PAGE = 'links';
export const RECOMMENDATION_MYACCOUNTS_PAYOUTS_PAGE = 'myaccounts_payouts';
export const RECOMMENDATION_MYEVENTS_PAGE = 'myevents';
export const RECOMMENDATION_MYEVENTS_MULTI_EVENT_DISCOUNTS_PAGE =
    'myevents_multi_event_discounts';
export const RECOMMENDATION_MYEVENTS_REPORTING_ATTENDEE_PAGE =
    'myevents_reporting_attendee';
export const RECOMMENDATION_MYEVENTS_TRACKING_PAGE = 'myevents_tracking';
export const RECOMMENDATION_PAYMENT_INFORMATION_PAGE = 'payment_information';
export const RECOMMENDATION_PAYMENT_OPTIONS_PAGE = 'payment_options';
export const RECOMMENDATION_REPORTS_PAGE = 'reports';
export const RECOMMENDATION_WEB_INTEGRATION_PAGE = 'web_integration';
