import { connect } from 'react-redux';

import OneClickRatingCTA from '../components/oneClickRatingCTA/OneClickRatingCTA';
import { createNewReview, editReview } from '../actions/Ratings';

const _mapStateToProps = ({ currentApp, reviewFromUser }) => ({
    appId: currentApp && currentApp.id,
    appName: currentApp && currentApp.name,
    currentRating: reviewFromUser && reviewFromUser.rating,
    currentReviewId: reviewFromUser && reviewFromUser.id,
    hasReview: Boolean(reviewFromUser && reviewFromUser.reviewTitle),
});

const _mapPropsToDispatch = {
    createNewReview,
    editReview,
};

export default connect(
    _mapStateToProps,
    _mapPropsToDispatch,
)(OneClickRatingCTA);
