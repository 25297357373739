import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@eventbrite/eds-button';
import { gettext } from '@eventbrite/i18n';
import { formatUrl } from 'url-lib';
import { openInNewTab, setWindowLocation } from '@eventbrite/http';

import './appTitleCard.scss';

/* eslint-disable react/jsx-no-bind */
const CTAButton = ({
    clientId,
    getStartedUrl,
    isInstalled,
    requiresOauth,
    slug,
    appName,
}) => {
    let component = (
        <Button
            href={getStartedUrl}
            target="_blank"
            type="link"
            style="fill"
            onClick={() => {
                openInNewTab(getStartedUrl);
            }}
        >
            {gettext('Get app info')}
        </Button>
    );

    const buildOAuthLink = () =>
        /* eslint-disable camelcase */
        formatUrl('/oauth/authorize', {
            state: '',
            redirect_uri: encodeURI(`/myevents/apps/${slug}/`),
            response_type: 'token',
            client_id: clientId,
            v: '1.0',
        });
    /* eslint-enable camelcase */

    /*  Off-platform apps will not have an app extension slug and should always
     *   show the 'Get app info' button with the getStartedUrl link. Uninstalled
     *   on-platform apps that require OAuth should show the 'Get app' button
     *   with their OAuth link. Installed on-platform apps with an app extension
     *   slug should show the 'Open' button with a link to their on-platform page
     *   at /myevents/apps/:slug
     */

    if (isInstalled && slug) {
        component = (
            <Button
                href={`/myevents/apps/${slug}/`}
                type="link"
                onClick={() => {
                    setWindowLocation(`/myevents/apps/${slug}/`);
                }}
            >
                {gettext('Open')}
            </Button>
        );
    } else if (requiresOauth) {
        component = (
            <Button
                href={buildOAuthLink()}
                type="link"
                style="fill"
                onClick={() => {
                    setWindowLocation(buildOAuthLink());
                }}
            >
                {gettext('Get app')}
            </Button>
        );
    }
    return component;
};
/* eslint-disable react/jsx-no-bind */

CTAButton.propTypes = {
    clientId: PropTypes.string,
    getStartedUrl: PropTypes.string,
    isInstalled: PropTypes.bool,
    requiresOauth: PropTypes.bool.isRequired,
    slug: PropTypes.string,
    appName: PropTypes.string,
};

export default CTAButton;
