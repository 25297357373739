import * as React from 'react';

const EmailSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 347 238" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle
                fill="#82D2DC"
                fillRule="nonzero"
                opacity={0.3}
                cx={280.5}
                cy={72.5}
                r={66.5}
            />
            <ellipse
                fill="#82D2DC"
                fillRule="nonzero"
                opacity={0.3}
                cx={108.5}
                cy={129}
                rx={108.5}
                ry={109}
            />
            <path
                d="M68 77.289L184 0l116 77.289V193.1a18.94 18.94 0 01-5.532 13.385A18.775 18.775 0 01281.123 212H86.817C76.424 212 68 203.539 68 193.1V77.289z"
                fill="#FFF"
                fillRule="nonzero"
            />
            <path
                d="M68 77.289L184 0l116 77.289V193.1a18.94 18.94 0 01-5.532 13.385A18.775 18.775 0 01281.123 212H86.817C76.424 212 68 203.539 68 193.1V77.289z"
                fill="#F05537"
                fillRule="nonzero"
                style={{
                    mixBlendMode: 'multiply',
                }}
            />
            <path
                fill="#F05537"
                fillRule="nonzero"
                opacity={0.5}
                style={{
                    mixBlendMode: 'multiply',
                }}
                d="M276 95.042L269 110V35l7 7.976z"
            />
            <path fill="#FFF" fillRule="nonzero" d="M100 35h170v171H100z" />
            <path
                d="M130 66.5h105M130 81.5h105M129 96.5h92M130 113.5h105M130 129.5h105"
                stroke="#1A0E2F"
            />
            <path
                d="M300 78l-116 59.345L68 78v115.682a18.31 18.31 0 005.37 12.953A18.343 18.343 0 0086.335 212h195.36c10.115-.017 18.305-8.213 18.305-18.318V78z"
                fill="#F05537"
                fillRule="nonzero"
            />
            <path
                fill="#F05537"
                fillRule="nonzero"
                style={{
                    mixBlendMode: 'multiply',
                }}
                d="M277 194l-92.83-50L92 194"
            />
        </g>
    </svg>
);

EmailSvg.displayName = 'EmailSvg';
export default EmailSvg;
