import { connect } from 'react-redux';

import SearchPage from '../pages/SearchPage';
import { searchApps } from '../actions/Search';
import { setLoading } from '../actions';
import { fetchCategories } from '../actions/Categories';

const _mapStateToProps = ({
    isLoading,
    filteredApps,
    searchedTerm,
    appsListMap: { apps },
    categoriesFetching,
}) => ({
    isLoading,
    filteredApps,
    searchedTerm,
    apps,
    categoriesFetching,
});

const _mapPropsToDispatch = {
    searchApps,
    setLoading,
    fetchCategories,
};

export default connect(_mapStateToProps, _mapPropsToDispatch)(SearchPage);
