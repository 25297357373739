import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ProgressIndicator } from '@eventbrite/eds-progress-indicator';
import { SelectField } from '@eventbrite/eds-input-field';

import { gettext } from '@eventbrite/i18n';
import {
    APP_RATING_SORT_OPTIONS,
    RATINGS_SHAPE,
    RATINGS_SUMMARY_STARS_SHAPE,
} from '../../constants/constants';
import { starNumberAsDigitToWord } from '../../utils/transforms';
import PaginatedRatingsList from './PaginatedRatingsList';
import './ratingsList.scss';

export default class RatingsListWithControls extends PureComponent {
    static propTypes = {
        appId: PropTypes.string,
        isLoading: PropTypes.bool,
        pageNumber: PropTypes.number,
        ratings: PropTypes.arrayOf(PropTypes.arrayOf(RATINGS_SHAPE)),
        stars: RATINGS_SUMMARY_STARS_SHAPE,
        ratingFilter: PropTypes.string,
        sortOrder: PropTypes.string,
        totalReviews: PropTypes.number,
        fetchRatings: PropTypes.func,
        clearRatings: PropTypes.func,
        setPageNumber: PropTypes.func,
        setRatingFilter: PropTypes.func,
        setRatingSortOrder: PropTypes.func,

        canReplyToRatings: PropTypes.bool,
    };

    static defaultProps = {
        canReplyToRatings: false,
    };

    componentDidMount() {
        const { appId, fetchRatings } = this.props;

        if (appId) {
            fetchRatings(appId);
        }
    }

    componentWillUnmount() {
        const { clearRatings, setRatingFilter, setRatingSortOrder } =
            this.props;

        clearRatings();
        setRatingFilter('all');
        setRatingSortOrder(APP_RATING_SORT_OPTIONS.MOST_RECENT);
    }

    _handleOrderBySelection(sortOrder) {
        const {
            appId,
            clearRatings,
            fetchRatings,
            setPageNumber,
            setRatingSortOrder,
        } = this.props;

        setPageNumber(1);
        setRatingSortOrder(sortOrder);

        if (appId) {
            clearRatings();
            fetchRatings(appId);
        }
    }

    _handleRatingFilterSelection(ratingFilter) {
        const {
            appId,
            clearRatings,
            fetchRatings,
            setPageNumber,
            setRatingFilter,
        } = this.props;

        setPageNumber(1);
        setRatingFilter(ratingFilter);

        if (appId) {
            clearRatings();
            fetchRatings(appId);
        }
    }

    _handleFetchNextPage() {
        const { appId, fetchRatings } = this.props;

        if (appId) {
            fetchRatings(appId);
        }
    }

    _handlePageSelect(newPageNumber) {
        const { ratings, setPageNumber } = this.props;
        const pageCount = ratings && ratings.length;

        setPageNumber(newPageNumber);

        if (newPageNumber === pageCount) {
            this._handleFetchNextPage();
        }
    }

    render() {
        const {
            isLoading,
            pageNumber,
            ratings,
            stars,
            ratingFilter,
            sortOrder,
            totalReviews,
            canReplyToRatings,
        } = this.props;
        const { TOP_RATED, MOST_RECENT } = APP_RATING_SORT_OPTIONS;
        const pageIndex = pageNumber - 1;

        const calculateTotalReviews = () => {
            const ratingFilterAsWord =
                ratingFilter &&
                ratingFilter !== 'all' &&
                starNumberAsDigitToWord(ratingFilter);
            const totalReviewsPerSelectedRating =
                ratingFilterAsWord && stars && stars[ratingFilterAsWord];

            return ratingFilterAsWord
                ? totalReviewsPerSelectedRating
                : totalReviews;
        };

        const renderReviews = () => {
            if (isLoading) {
                return (
                    <div className="eds-align--center eds-l-pad-vert-10">
                        <ProgressIndicator
                            style="gradient"
                            size="large-chunky"
                            shape="circular"
                        />
                    </div>
                );
            }

            const totalReviewsForCurrentList = calculateTotalReviews();

            let reviewsListContent = (
                <PaginatedRatingsList
                    pageCount={ratings && ratings.length}
                    pageNumber={pageNumber}
                    ratings={ratings && ratings[pageIndex]}
                    totalReviews={totalReviewsForCurrentList}
                    onPageSelect={this._handlePageSelect.bind(this)}
                    canReplyToRatings={canReplyToRatings}
                />
            );

            if (!totalReviewsForCurrentList || totalReviewsForCurrentList < 1) {
                // Translators: 'ratingFilter' is a number from 1 to 5
                reviewsListContent = (
                    <p className="eds-text-bl eds-text-color--ui-600">
                        {gettext(
                            'There are no %(ratingFilter)s star reviews for this app.',
                            { ratingFilter },
                        )}
                    </p>
                );
            }

            return reviewsListContent;
        };

        return (
            <div id="ratings">
                <div className="ratings-list-filter-container eds-l-mar-bot-6">
                    <div className="ratings-list-filter eds-l-mar-right-4">
                        <SelectField
                            label={gettext('Sort by')}
                            values={[
                                {
                                    value: MOST_RECENT,
                                    display: gettext('Most recent'),
                                },
                                {
                                    value: TOP_RATED,
                                    display: gettext('Top rated'),
                                },
                            ]}
                            value={sortOrder}
                            onChange={this._handleOrderBySelection.bind(this)}
                        />
                    </div>
                    <div className="ratings-list-filter">
                        <SelectField
                            label={gettext('Filter by')}
                            values={[
                                { value: 'all', display: gettext('All stars') },
                                { value: '5', display: gettext('5 star only') },
                                { value: '4', display: gettext('4 star only') },
                                { value: '3', display: gettext('3 star only') },
                                { value: '2', display: gettext('2 star only') },
                                { value: '1', display: gettext('1 star only') },
                            ]}
                            value={ratingFilter}
                            onChange={this._handleRatingFilterSelection.bind(
                                this,
                            )}
                        />
                    </div>
                </div>
                {renderReviews()}
            </div>
        );
    }
}
