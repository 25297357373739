import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@eventbrite/eds-button';

import RatingsChart from './RatingsChart';
import RatingStars from '../ratingStars/RatingStars';
import { gettext } from '@eventbrite/i18n';

import './ratingsOverview.scss';

const RatingsOverview = ({
    appId,
    clearRatings,
    fetchRatings,
    onAddReviewBtnClick,
    rating,
    setPageNumber,
    setRatingFilter,
    stars,
    totalReviews,
}) => (
    <div>
        <h3 className="eds-text-hs eds-l-mar-bot-1">{gettext('Reviews')}</h3>
        <div className="eds-g-grid">
            <div className="ratings-overview-component eds-g-group">
                <div className="eds-g-cell eds-g-cell-12-12 eds-g-cell-sw-3-12 eds-l-pad-right-8 eds-l-sn-pad-right-0">
                    <div className="ratings-summary-wrapper">
                        <div className="ratings-summary eds-bg-color--ui-100 eds-l-pad-top-4 eds-l-pad-bot-4 eds-l-mar-top-2">
                            <div className="eds-text-hm eds-l-mar-bot-1">
                                {rating}
                            </div>
                            <RatingStars
                                rating={Number(rating)}
                                size="xsmall"
                            />
                            <div className="eds-text-weight--heavy eds-l-mar-top-3">
                                {gettext('%(totalReviews)s reviews', {
                                    totalReviews,
                                })}
                            </div>
                        </div>
                        <div className="eds-show-up-sw eds-l-mar-bot-2">
                            <Button
                                style="fill"
                                size="block"
                                onClick={onAddReviewBtnClick}
                            >
                                {gettext('Rate')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="eds-g-cell eds-g-cell-12-12 eds-g-cell-sw-9-12">
                    <RatingsChart
                        appId={appId}
                        clearRatings={clearRatings}
                        fetchRatings={fetchRatings}
                        setPageNumber={setPageNumber}
                        setRatingFilter={setRatingFilter}
                        stars={stars}
                        totalReviews={totalReviews}
                    />
                </div>
                <div className="eds-g-cell eds-g-cell-12-12 eds-l-mar-top-4 eds-show-down-sm">
                    <Button
                        style="fill"
                        size="block"
                        onClick={onAddReviewBtnClick}
                    >
                        {gettext('Rate')}
                    </Button>
                </div>
            </div>
        </div>
    </div>
);

RatingsOverview.propTypes = {
    appId: PropTypes.string,
    clearRatings: PropTypes.func,
    fetchRatings: PropTypes.func,
    rating: PropTypes.string,
    setPageNumber: PropTypes.func,
    setRatingFilter: PropTypes.func,
    stars: PropTypes.shape({
        one: PropTypes.number,
        two: PropTypes.number,
        three: PropTypes.number,
        four: PropTypes.number,
        five: PropTypes.number,
    }),
    totalReviews: PropTypes.number,
};

export default RatingsOverview;
