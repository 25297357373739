import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import PicksPage from '../pages/PicksPage';
import {
    fetchPopularApps,
    fetchFeaturedApps,
    fetchRecommendedApps,
} from '../actions/Apps';
import { fetchCategories } from '../actions/Categories';

const _mapStateToProps = ({
    appsListMap,
    categories,
    isFetching,
    hasFailed,
    categoriesFetching,
    isUserSignedIn,
}) => ({
    appsListMap,
    categories,
    isFetching,
    hasFailed,
    categoriesFetching,
    isUserSignedIn,
});

const _mapPropsToDispatch = {
    fetchPopularApps,
    fetchFeaturedApps,
    fetchCategories,
    fetchRecommendedApps,
};

export default withRouter(
    connect(_mapStateToProps, _mapPropsToDispatch)(PicksPage),
);
