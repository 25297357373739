import React from 'react';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { browserHistory, Router } from 'react-router';
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux';
import isEmpty from 'lodash/isEmpty';
import { getLocaleFromDefaultLanguage } from './utils/localeMapHelper';

import analytics from './utils/analytics/analytics';
import configureStore from './store/configureStore';
import reducer from './reducers';
import { getInitialState } from './reducers/utils';
import { getRoutes } from './routes';
import { setLanguage } from '@eventbrite/i18n';
import { getHistory } from '@eventbrite/react-router-history';
import { selectCategory, setUserLocale, setIsUserSignedIn } from './actions';

const pageview = () => {
    analytics.pageview();
};

export default class App extends React.Component {
    constructor(props) {
        super(props);

        const historyManagement = getHistory();

        // creates a store w/ Redux logging defaulted in development environment
        // also adds `redux-thunk` middleware by default for async actions
        this._store = configureStore({
            reducer,
            initialState: getInitialState(props),
            middleware: [thunk, routerMiddleware(historyManagement)],
        });

        if (!isEmpty(props) && props.env && props.env.localeInfo) {
            const { localeInfo } = props.env;
            const locale =
                getLocaleFromDefaultLanguage(localeInfo.default_language) ||
                localeInfo.locale ||
                'en_US';

            setLanguage(locale);
            this._store.dispatch(setUserLocale(locale));
        }

        if (!isEmpty(props) && props.user) {
            const { isAuthenticated } = props.user;

            this._store.dispatch(setIsUserSignedIn(isAuthenticated));
        }

        this._history = syncHistoryWithStore(historyManagement, this._store);

        browserHistory?.listen((location) => {
            if (
                !location.pathname.includes('/categories/') &&
                !location.pathname.includes('/popular') &&
                !location.pathname.includes('/recommended')
            ) {
                this._store.dispatch(selectCategory(-1));
            }

            pageview();
        });
    }

    componentDidMount() {
        const crazyEggScript = document.createElement('script');

        crazyEggScript.type = 'text/javascript';
        crazyEggScript.src = '//script.crazyegg.com/pages/scripts/0047/7928.js';
        crazyEggScript.async = 'async';
        document.head.appendChild(crazyEggScript);
    }

    _handleRouterUpdate() {
        const { hash } = window.location;

        if (hash !== '') {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);

                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        } else {
            window.scrollTo(0, 0);
        }
    }

    render() {
        const routes = getRoutes(this.props, this._store);

        return (
            <Provider store={this._store}>
                <Router
                    history={this._history}
                    routes={routes}
                    onUpdate={this._handleRouterUpdate}
                    user={this.props.user}
                />
            </Provider>
        );
    }
}
