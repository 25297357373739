import { EDSLink } from '@eventbrite/eds-link';
import { Link } from 'react-router';
import * as recomm from './recommendationPages';

// export these bad boys. check!!
export const BASE_URL = '/';
export const BASE_APPS_URL = '/apps/';

export const WIDGET_TYPE_POPULAR = 'popular';
export const WIDGET_TYPE_FEATURED = 'featured';
export const WIDGET_TYPE_RECOMMENDED = 'recommended';

export type ValueOf<T> = T[keyof T];

export const WIDGET_TYPES = [
    WIDGET_TYPE_POPULAR,
    WIDGET_TYPE_FEATURED,
    WIDGET_TYPE_RECOMMENDED,
];
export type WidgetTypesProps = ValueOf<typeof WIDGET_TYPES>;

export interface AppProp {
    id: string;
    name: string;
    categories: Array<{
        id: string;
        name: string;
        slug: string;
    }>;
    localization: {
        short_description: string;
    };
    slug: string;
    details?: {
        is_installed: boolean;
    };
    app_url?: string;
    logo_url?: string;
    banner_url?: string;
    eventCategories?: Array<{
        id: string;
        name: string;
        slug: string;
    }>;
    serviceCategories?: Array<{
        id: string;
        name: string;
        slug: string;
    }>;
}

export const ROUTER_LINK = 'routerLink';
export const ANCHOR_LINK = 'anchor';

export const LINK_COMPONENT_TYPES_MAP = {
    [ROUTER_LINK]: Link,
    [ANCHOR_LINK]: EDSLink,
};

export const APP_CARD_LINK_COMPONENT_TYPES = [ROUTER_LINK, ANCHOR_LINK];

export type AppCardLinkProp = keyof typeof LINK_COMPONENT_TYPES_MAP;

export const APP_CARD_APP_NAME_TEXT_SIZE_MD = 'medium';
export const APP_CARD_APP_NAME_TEXT_SIZE_LG = 'large';

export const APP_CARD_APP_NAME_TEXT_SIZES = [
    APP_CARD_APP_NAME_TEXT_SIZE_MD,
    APP_CARD_APP_NAME_TEXT_SIZE_LG,
];

export type AppCardAppNameTextSizeProp = ValueOf<
    typeof APP_CARD_APP_NAME_TEXT_SIZES
>;

export const APP_LIST_COLUMN_NUMBER_TWO = 2;
export const APP_LIST_COLUMN_NUMBER_THREE = 3;

export const APP_LIST_COLUMN_NUMBERS = [
    APP_LIST_COLUMN_NUMBER_TWO,
    APP_LIST_COLUMN_NUMBER_THREE,
];
export type AppListColumnNumbersProp = ValueOf<typeof APP_LIST_COLUMN_NUMBERS>;

export const RECOMMENDATION_PAGE_TYPES = [
    recomm.RECOMMENDATION_AFFILIATES_PAGE,
    recomm.RECOMMENDATION_APPS_PAGE,
    recomm.RECOMMENDATION_ATTENDEES_BADGE_PAGE,
    recomm.RECOMMENDATION_ATTENDEES_EMAIL_PAGE,
    recomm.RECOMMENDATION_CREATEINVITE_PAGE,
    recomm.RECOMMENDATION_FACEBOOK_PUBLISH_PAGE,
    recomm.RECOMMENDATION_LINKS_PAGE,
    recomm.RECOMMENDATION_MYACCOUNTS_PAYOUTS_PAGE,
    recomm.RECOMMENDATION_MYEVENTS_PAGE,
    recomm.RECOMMENDATION_MYEVENTS_MULTI_EVENT_DISCOUNTS_PAGE,
    recomm.RECOMMENDATION_MYEVENTS_REPORTING_ATTENDEE_PAGE,
    recomm.RECOMMENDATION_MYEVENTS_TRACKING_PAGE,
    recomm.RECOMMENDATION_PAYMENT_INFORMATION_PAGE,
    recomm.RECOMMENDATION_PAYMENT_OPTIONS_PAGE,
    recomm.RECOMMENDATION_REPORTS_PAGE,
    recomm.RECOMMENDATION_WEB_INTEGRATION_PAGE,
];

export type RecommendationPageProp = ValueOf<typeof RECOMMENDATION_PAGE_TYPES>;
