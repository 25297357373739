import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';
import { Link } from 'react-router';
import { Icon } from '@eventbrite/eds-icon';
import { ArrowRightChunky } from '@eventbrite/eds-iconography';

import './ArrowLink.scss';

export default class ArrowLink extends PureComponent {
    static propTypes = {
        text: translationPropType.isRequired,
        url: translationPropType.isRequired,
        onClick: PropTypes.func,
    };

    render() {
        const { url, text, onClick } = this.props;

        return (
            <div className="arrow-link">
                <Link to={url} onClick={onClick ? () => onClick() : null}>
                    <div className="arrow-link--text">{text}</div>
                    <div className="arrow-link--icon eds-l-mar-left-1">
                        <Icon type={<ArrowRightChunky />} size="xsmall" />
                    </div>
                </Link>
            </div>
        );
    }
}
