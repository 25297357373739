import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AlertChunky } from '@eventbrite/eds-iconography';
import { Button } from '@eventbrite/eds-button';
import { InputField } from '@eventbrite/eds-input-field';
import { NotificationBar } from '@eventbrite/eds-notification';
import { ProgressIndicator } from '@eventbrite/eds-progress-indicator';
import { TYPE_ERROR } from '@eventbrite/eds-notification';
import { gettext } from '@eventbrite/i18n';
import './reviewReplyForm.scss';

export default class ReviewReplyForm extends PureComponent {
    static propTypes = {
        appId: PropTypes.string.isRequired,
        appName: PropTypes.string.isRequired,
        createNewReviewReply: PropTypes.func.isRequired,
        editReviewReply: PropTypes.func.isRequired,
        handleCancelReply: PropTypes.func.isRequired,
        handleSubmitReply: PropTypes.func.isRequired,
        ratingId: PropTypes.string.isRequired,

        replyId: PropTypes.string,
        replyText: PropTypes.string,
    };

    static defaultProps = {
        replyId: '',
        replyText: '',
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            hasFailed: false,
            replyBody: this.props.replyText,
        };
    }

    _handleSubmit(e) {
        e.preventDefault();
        const {
            appId,
            appName,
            createNewReviewReply,
            editReviewReply,
            handleSubmitReply,
            ratingId,
            replyId,
        } = this.props;
        const { replyBody } = this.state;
        const handleFailure = () => {
            this.setState({
                hasFailed: true,
                isLoading: false,
            });
        };
        const handleSuccess = () => {
            this.setState({ isLoading: false });
            if (handleSubmitReply) {
                handleSubmitReply();
            }
        };

        if (replyId && editReviewReply) {
            this.setState({ isLoading: true });
            editReviewReply(ratingId, replyId, replyBody)
                .catch(() => handleFailure())
                .then(() => {
                    handleSuccess();
                });
        } else if (!replyId && createNewReviewReply) {
            this.setState({ isLoading: true });
            createNewReviewReply(appId, ratingId, replyBody)
                .catch(() => handleFailure())
                .then(() => {
                    handleSuccess();
                });
        }
    }

    _handleCancel() {
        if (this.props.handleCancelReply) {
            this.props.handleCancelReply();
        }
    }

    _handleReplyBodyChange(value) {
        this.setState({ replyBody: value });
    }

    render() {
        const { isLoading, hasFailed, replyBody } = this.state;

        let errorNotification;

        if (isLoading) {
            return (
                <div className="eds-align--center eds-l-mar-vert-16">
                    <ProgressIndicator style="gradient" />
                </div>
            );
        }

        if (hasFailed) {
            errorNotification = (
                <div className="eds-align--center eds-l-mar-vert-4">
                    <NotificationBar
                        data-spec="create-review-reply-error-notification"
                        type={TYPE_ERROR}
                        iconType={<AlertChunky />}
                    >
                        {gettext('Failed to save reply. Please try again.')}
                    </NotificationBar>
                </div>
            );
        }

        return (
            <form
                className="review-reply-form eds-l-mar-top-4"
                onSubmit={this._handleSubmit.bind(this)}
            >
                {errorNotification}
                <InputField
                    id="reply_to_review_body"
                    hasCharacterCount={true}
                    isMultiline={true}
                    label={gettext('Reply to review')}
                    maxLength={750}
                    name="reply_to_review_body"
                    onChange={this._handleReplyBodyChange.bind(this)}
                    rows={5}
                    type="text"
                    value={replyBody}
                />
                <div className="submit-button-group">
                    <Button
                        __containerClassName="eds-l-mar-left-4"
                        data-spec="create-review-reply-btn"
                        disabled={replyBody.length === 0}
                        size="responsive"
                        style="fill"
                        type="submit"
                    >
                        {gettext('Reply')}
                    </Button>
                    <Button
                        data-spec="cancel-review-reply-btn"
                        onClick={this._handleCancel.bind(this)}
                        size="responsive"
                    >
                        {gettext('Cancel')}
                    </Button>
                </div>
            </form>
        );
    }
}
