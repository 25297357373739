import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';
import classNames from 'classnames';

import { Button } from '@eventbrite/eds-button';
import { Icon } from '@eventbrite/eds-icon';

import { STYLE_NONE as BUTTON_STYLE_NONE } from '@eventbrite/eds-button';
import {
    STYLE_PROP_TYPE,
    ICON_PROP_TYPE,
    STYLE_NEUTRAL,
    STYLE_DARK,
} from './constants';
import { getAdditionalProps } from '@eventbrite/eds-utils';

import './iconButton.scss';

export default class IconButton extends PureComponent {
    static propTypes = {
        /**
         * The title used to describe icon button
         */
        title: translationPropType.isRequired,
        /**
         * Changes the style of the icon button.
         */
        style: STYLE_PROP_TYPE,
        /**
         * The type of icon that you want to display (chunky or third party)
         */
        iconType: ICON_PROP_TYPE,
        /**
         * If the icon button is active
         **/
        isActive: PropTypes.bool,
        /**
         * If the icon button should be set to a disabled state
         **/
        isDisabled: PropTypes.bool,
        /**
         * If the icon button will be shown over an image, use a style that includes a background color for better visibility
         **/
        isOverImage: PropTypes.bool,
        /**
         * Callback function invoked when the button is clicked.
         */
        onClick: PropTypes.func,
        /**
         * Overrides the color of the icon.
         */
        iconColor: PropTypes.string,
    };

    static defaultProps = {
        style: STYLE_NEUTRAL,
        isActive: false,
        isDisabled: false,
        isOverImage: false,
    };

    render() {
        const {
            style,
            title,
            isActive,
            isDisabled,
            isOverImage,
            iconType,
            onClick,
            iconColor,
        } = this.props;

        const extraAttrs = getAdditionalProps(this);

        let buttonStyle = style;

        if (buttonStyle === STYLE_DARK) {
            buttonStyle = STYLE_NEUTRAL;
        }

        const iconButtonClasses = classNames('eds-icon-button', {
            'eds-icon-button--active': isActive,
            'eds-icon-button--disabled': isDisabled,
            'eds-icon-button--over-image': isOverImage,
            [`eds-icon-button--${buttonStyle}`]: buttonStyle,
        });

        return (
            <span className={iconButtonClasses} data-spec="icon-button">
                <Button
                    style={BUTTON_STYLE_NONE}
                    onClick={onClick}
                    disabled={isDisabled}
                    {...extraAttrs}
                >
                    <Icon
                        type={iconType}
                        title={title}
                        isBlockLevel={true}
                        color={iconColor}
                    />
                </Button>
            </span>
        );
    }
}
