import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@eventbrite/eds-divider';
import { Pagination } from '@eventbrite/eds-pagination';
import RatingCard from '../../containers/ConnectedRatingCard';
import { gettext } from '@eventbrite/i18n';
import { APP_RATINGS_PER_PAGE, RATINGS_SHAPE } from '../../constants/constants';

const PaginatedRatingsList = ({
    pageCount,
    pageNumber,
    ratings = [],
    totalReviews,
    onPageSelect,
    canReplyToRatings,
}) => {
    const pageIndex = pageNumber - 1;
    const reviewRangeStart = pageIndex * APP_RATINGS_PER_PAGE + 1;
    const reviewRangeEnd = pageIndex * APP_RATINGS_PER_PAGE + ratings.length;
    let ratingDisplayRange = gettext(
        'Ratings %(reviewRangeStart)s-%(reviewRangeEnd)s of %(totalReviews)s',
        { reviewRangeStart, reviewRangeEnd, totalReviews },
    );

    if (reviewRangeStart === reviewRangeEnd) {
        ratingDisplayRange = gettext(
            'Rating %(reviewRangeStart)s of %(totalReviews)s',
            { reviewRangeStart, totalReviews },
        );
    }

    return (
        <div>
            <ul className="ratings-list eds-l-mar-bot-8">
                {ratings.map((rating, index) => (
                    <li key={rating.id}>
                        <RatingCard
                            shouldShowReplyCTA={canReplyToRatings}
                            {...rating}
                        />
                        {index < ratings.length - 1 && (
                            <div className="eds-l-mar-vert-6 eds-l-sw-mar-vert-4 eds-l-sm-mar-vert-4 eds-l-sn-mar-vert-4">
                                <Divider />
                            </div>
                        )}
                    </li>
                ))}
            </ul>
            <div className="ratings-pagination-wrapper eds-align--space-between">
                <p>{ratingDisplayRange}</p>
                <div>
                    <Pagination
                        onSelected={onPageSelect}
                        pageCount={pageCount}
                        pageNumber={pageNumber}
                        showOnSinglePage={false}
                    />
                </div>
            </div>
        </div>
    );
};

PaginatedRatingsList.propTypes = {
    pageCount: PropTypes.number,
    pageNumber: PropTypes.number,
    ratings: PropTypes.arrayOf(RATINGS_SHAPE),
    totalReviews: PropTypes.number,
    onPageSelect: PropTypes.func,
    canReplyToRatings: PropTypes.bool.isRequired,
};

export default PaginatedRatingsList;
