import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import {
    AppListColumnNumbersProp,
    APP_LIST_COLUMN_NUMBER_THREE,
    APP_LIST_COLUMN_NUMBER_TWO,
} from '../../constants';
import './AppList.scss';

const AppList: FunctionComponent<{
    columnNumber?: AppListColumnNumbersProp;
}> = ({ columnNumber = APP_LIST_COLUMN_NUMBER_THREE, children }) => {
    const appListClass = classNames('app-list', {
        'app-list--max-three-columns':
            columnNumber === APP_LIST_COLUMN_NUMBER_THREE,
        'app-list--max-two-columns':
            columnNumber === APP_LIST_COLUMN_NUMBER_TWO,
    });

    return <div className={appListClass}>{children}</div>;
};

export default AppList;
