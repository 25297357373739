import * as React from 'react';

const HeartIconFilledSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 172 136" {...props}>
        <title>{'Group 13'}</title>
        <g fillRule="nonzero" fill="none">
            <path
                d="M47.786 42.263C56.685 17.617 80.285 0 108 0c35.346 0 64 28.654 64 64 0 35.346-28.654 64-64 64-13.396 0-25.83-4.116-36.109-11.152C64.257 123.778 54.121 128 43 128c-23.748 0-43-19.252-43-43s19.252-43 43-43c1.618 0 3.215.09 4.786.263z"
                fill="#82D2DC"
                opacity={0.3}
            />
            <path
                d="M110.267 20C99 20 88.667 25.297 82 34.014 75.333 25.297 65 20 53.733 20 34.033 20 18 36.193 18 56.108c0 9.353 3.533 18.238 9.967 25.01l50.4 51.194L82 136l3.633-3.688 49.434-50.222c7-6.806 10.933-16.059 10.933-25.982C146 36.193 129.967 20 110.267 20z"
                fill="#F05537"
            />
        </g>
    </svg>
);

HeartIconFilledSvg.displayName = 'HeartIconFilledSvg';
export default HeartIconFilledSvg;
