import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RatingStars from '../../containers/ConnectedRatingStars';
import ReviewForm from '../../containers/ConnectedReviewForm';
import { LoadingOverlay } from '@eventbrite/eds-loading-overlay';
import { gettext } from '@eventbrite/i18n';

export default class ReviewModalContent extends PureComponent {
    static propTypes = {
        appName: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    render() {
        const { appName, isLoading, onSubmit } = this.props;

        return (
            <div className="eds-l-pad-all-4">
                <h2 className="eds-text-hs eds-align--left eds-l-mar-top-8">
                    {gettext('Review %(appName)s', { appName })}
                </h2>
                <div className="eds-l-mar-top-8">
                    <h3 className="eds-text-bl">{gettext('Overall rating')}</h3>
                    <div className="eds-l-mar-top-2 eds-l-mar-bot-10">
                        <RatingStars isReadOnly={false} size="medium" />
                    </div>
                </div>
                <div className="eds-l-mar-top-6">
                    <ReviewForm
                        onSubmit={onSubmit}
                        submitButtonText={gettext('Add Review')}
                    />
                </div>
                <LoadingOverlay isShown={isLoading} />
            </div>
        );
    }
}
